<template>
  <div>
    <!--Filter by Month-->
    <v-card dark style="background-color:#002147">
      <v-card-text>
        <v-row
          ><v-col cols="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :label="$t('Filter by Month')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  style="width: 50%;"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                no-title
                scrollable
                @change="changeFilter"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <!--End Filter by Month-->
          <v-col cols="8">
            <v-autocomplete
              :items="boards"
              :label="$t('Select Lesson')"
              v-model="selected"
              @change="search"
              style="direction: ltr;width: 30%;"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-btn @click="clearsearch" color="info" x-small depressed
            >{{$t("Clear Search")}}</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
    <br />

    <div v-if="boards.length > 0">
      <!-- Summer Work-->
      <div v-if="summer != ''">
        <v-card
          class="mx-auto"
          max-width="100%"
          style="margin-bottom: 20px; background-color:#E53935
;"
          dark
          outlined
          @click="
            goToWeek(
              summer.ref.path,
              summer.data().title,
              summer.id,
              summer.data().revision
            )
          "
        >
          <v-list-item-title> </v-list-item-title>

          <v-list-item>
            <v-row
              ><v-col cols="2" style=" font-size:x-large;">
                <img src="@/assets/summer.png" width="80px" />
              </v-col>
              <v-col cols="10">
                <h1 style="color:#fff; font-size: 1.5rem;">Summer Work</h1>
                <!-- <div style="padding-left:20px;padding-right:20px;">
                  {{ summer.data().title }}
                </div> -->
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
      </div>

      <!-- Current Week-->
      <div v-if="current != ''">
        <v-card
          class="mx-auto"
          shaped
          max-width="100%"
          style="margin-bottom: 20px; background-color:#006e47;"
          dark
          outlined
          @click="
            goToWeek(
              current.ref.path,
              current.data().title,
              current.id,
              current.data().revision
            )
          "
        >
          <v-list-item-title> </v-list-item-title>

          <v-list-item>
            <v-row
              ><v-col cols="2" style=" font-size:x-large; color:#c7cfdd;"
                ><div>
                 {{ $t("Week") }} 
                </div></v-col
              >
              <v-col cols="10">
                <h1 style="color:orange; font-size: 1.5rem;">Current Week</h1>
                <div style="padding-left:20px;padding-right:20px;">
                  {{ current.data().title }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
      </div>

      <!-- Orientation Week-->
      <div v-if="orientation != ''">
        <v-card
          class="mx-auto"
          shaped
          max-width="100%"
          style="margin-bottom: 20px; background-color:#01579B;"
          dark
          outlined
          @click="
            goToWeek(
              orientation.ref.path,
              orientation.data().title,
              orientation.id,
              orientation.data().revision
            )
          "
        >
          <v-list-item-title> </v-list-item-title>

          <v-list-item>
            <v-row
              ><v-col cols="2" style=" font-size:x-large; color:#c7cfdd;"
                ><div>
                  <img src="@/assets/orientation.png" width="80px" /></div
              ></v-col>
              <v-col cols="10">
                <h1 style="color:orange; font-size: 1.5rem;">
                  Orientation Week
                </h1>
                <div style="padding-right:20px;">
                  {{ orientation.data().title }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
      </div>

      <div v-for="(week, index) in boards" :key="week.key">
        <v-card
          class="mx-auto"
          max-width="100%"
          shaped
          flat
          outlined
          v-if="!week.revision"
          style="margin-bottom: 20px;"
          @click="
            goToWeek(
              week.path,
              week.title,
              week.key,
              week.revision,
              week.practice,
              week.lessonbreakdown
            )
          "
        >
          <v-list-item-title> </v-list-item-title>

          <v-list-item>
            <v-row
              ><v-col cols="2">
                <div>
                  <p style=" font-size:x-large; color:#c7cfdd;">   {{ $t("Week") }} </p>
                  <br />
                  <v-chip v-if="week.section"> {{ week.section }}</v-chip>
                </div>
              </v-col>
              <v-col cols="10">
                <h1 style="color:#c7cfdd; font-size: 3rem;">{{$i18n.locale=='Arabic' ?(index + 1 ).toLocaleString('ar-EG') : (index + 1 )}}</h1>
                <div class="fooar">
                  {{ week.title }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
        <v-card
          class="mx-auto"
          max-width="100%"
          shaped
          flat
          outlined
          v-else
          dark
          style="margin-bottom: 20px;background-color:#1976D2;"
          @click="goToWeek(week.path, week.title, week.key, week.revision,week.lessonbreakdown)"
        >
          <v-list-item-title> </v-list-item-title>

          <v-list-item>
            <v-row
              ><v-col
                cols="2"
                style=" font-size:x-large; color:#c7cfdd; background-color:#1976D2;"
                ><div>
                  {{ $t("Revision") }} 
                </div></v-col
              >
              <v-col cols="10">
                <h1 style="color:#c7cfdd; font-size: 3rem;">{{ $i18n.locale=='Arabic' ?(index + 1 ).toLocaleString('ar-EG') : (index + 1 )  }}</h1>
                <div  style="padding-left:20px;padding-right:20px;">
                  {{ week.title }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
      </div>
    </div>

    <v-sheet v-else color="darken-2" class="pa-3" height="800px">
      <v-skeleton-loader type="article, actions"></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "WeeksList",
  components: {},
  props: ["path", "mirror"],
  data() {
    return {
      fields: [
        { field: "order", label: "Order", sortable: true, class: "text-left" },
        {
          field: "section",
          label: "Section",
          sortable: true,
          class: "text-left"
        },

        { field: "title", label: "Title", sortable: true, class: "text-left" },

        {
          field: "term",
          label: "Term",
          sortable: true
          // class: "text-left"
        },
        { field: "flipped", label: "Flipped", class: "text-center" },
        { field: "lesson", label: "Lesson", class: "text-center" },
        { field: "bank", label: "Quesions", class: "text-center" },
        { field: "assignments", label: "Assignments", class: "text-center" },

        { field: "actions", label: "Action", class: "text-center" }
      ],
      subjectpath: "",

      boards: [],
      current: [],
      orientation: [],
      summer: [],

      errors: [],

      subjectTitle: this.title,
      date: new Date().toISOString().substr(0, 7),

      menu: false,
      modal: false,
      selected: ""
    };
  },
  created() {
    this.loadWeeks();
  },
  methods: {
    loadWeeks() {
      if (
        this.mirror == null ||
        this.mirror == "" ||
        this.mirror == "undefined"
      ) {
        this.subjectpath = this.path;
      } else {
        this.subjectpath = this.mirror;
      }
      firebase
        .firestore()
        .collection(this.subjectpath + "/weeks/")
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.boards = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            if (
              doc.data().hidden != true &&
              doc.data().summer != true &&
              doc.data().enrichment != true &&
              doc.data().orientation != true
            ) {
              this.boards.push({
                key: doc.id,
                path: doc.ref.path,
                lessonbreakdown: data.breakdown,
                section: data.section,
                title: data.title,
                term: data.term,
                order: data.order,
                weekly: data.weekly,
                text: data.title,
                date: data.date,
                revision: data.revision,
                summer: data.summer,
                orientation: data.orientation,
                practice: data.practice,
                value: data.title
              });
            }

            if (data.current == true) {
              this.current = doc;
            }
            if (data.orientation == true) {
              this.orientation = doc;
            }

            if (data.summer == true) {
              this.summer = doc;
            }
          });
        });
    },
    changeFilter() {
      var mon;
      mon = new Date(this.date).getMonth() + 1;
      this.$refs.menu.save(this.date);
      this.menu = false;

      if (
        this.mirror == null ||
        this.mirror == "" ||
        this.mirror == "undefined"
      ) {
        firebase
          .firestore()
          .collection(this.path + "/weeks/")
          .orderBy("order")
          .get()
          .then(querySnapshot => {
            this.boards = [];
            querySnapshot.forEach(doc => {
              if (new Date(doc.data().date).getMonth() + 1 == mon)
                this.boards.push({
                  key: doc.id,
                  path: doc.ref.path,
                  lessonbreakdown: doc.data().breakdown,
                  section: doc.data().section,
                  title: doc.data().title,
                  term: doc.data().term,
                  order: doc.data().order,
                  date: doc.data().date,
                  standard: doc.data().standard,
                  text: doc.data().title,
                  value: doc.data().title
                });
            });
          });
      } else {
        firebase
          .firestore()
          .collection(this.mirror + "/weeks/")
          .orderBy("order")
          .get()
          .then(querySnapshot => {
            this.boards = [];
            querySnapshot.forEach(doc => {
              if (new Date(doc.data().date).getMonth() + 1 == mon)
                this.boards.push({
                  key: doc.id,
                  path: doc.ref.path,
                  section: doc.data().section,
                  lessonbreakdown: doc.data().breakdown,
                  title: doc.data().title,
                  term: doc.data().term,
                  order: doc.data().order,
                  date: doc.data().date,
                  standard: doc.data().standard,
                  text: doc.data().title,
                  value: doc.data().title
                });
            });
          });
      }
    },
    search() {
      if (
        this.mirror == null ||
        this.mirror == "" ||
        this.mirror == "undefined"
      ) {
        firebase
          .firestore()
          .collection(this.path + "/weeks/")

          .orderBy("order")
          .get()
          .then(querySnapshot => {
            this.boards = [];
            querySnapshot.forEach(doc => {
              if (doc.data().title == this.selected)
                this.boards.push({
                  key: doc.id,
                  path: doc.ref.path,
                  section: doc.data().section,
                  lessonbreakdown: doc.data().breakdown,
                  title: doc.data().title,
                  term: doc.data().term,
                  order: doc.data().order,
                  date: doc.data().date,
                  standard: doc.data().standard,
                  text: doc.data().title,
                  value: doc.data().title
                });
            });
          });
      } else {
        firebase
          .firestore()
          .collection(this.mirror + "/weeks/")
          .orderBy("order")
          .get()
          .then(querySnapshot => {
            this.boards = [];
            querySnapshot.forEach(doc => {
              if (doc.data().title == this.selected)
                this.boards.push({
                  key: doc.id,
                  path: doc.ref.path,
                  section: doc.data().section,
                  lessonbreakdown: doc.data().breakdown,
                  title: doc.data().title,
                  term: doc.data().term,
                  order: doc.data().order,
                  date: doc.data().date,
                  standard: doc.data().standard,
                  text: doc.data().title,
                  value: doc.data().title
                });
            });
          });
      }
    },
    clearsearch() {
      this.loadWeeks();
    },
    deleteWeek(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/weeks")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Week Deleted", {
                  title: "Week Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    goToWeek(path, title, id, revision, practice,lessonbreakdown) {
      this.$store.state.rightAnswers = 0;
      this.$store.state.flippedrightAnswers = 0;
      this.$store.state.exitrightAnswers = 0;

      this.$store.state.lessonrightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.lessonwrongAnswers = 0;

      this.$store.state.practice = practice;

      this.$store.commit("weekpath", path);
      this.$store.commit("weekid", id);
      this.$store.commit("weektitle", title);
      this.$store.commit("lessonbreakdown", lessonbreakdown);

      if (revision == true) {
        router.push({
          name: "ReviewPage"
        });
      } else {
        router.push({
          name: "WeekPage"
        });
      }
    },
    goToAssign(path, title) {
      router.push({
        name: "AssignmentsList",
        params: { id: path, title: title }
      });
    },

    goToBank(path, title) {
      router.push({ name: "BankList", params: { id: path, title: title } });
    },
    flipped(type) {
      console(type);
    },
    Edit(path) {
      router.push({
        name: "EditWeek",
        params: { path: path, subject: this.path }
      });
    }
  }
};
</script>
<style scoped>
.arabic .v-menu__content {
left: auto !important;
}
</style>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/textbook.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">{{ this.assign.weight }}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}

                <br />
                {{ this.picture }}

                <span>{{ this.assign.title }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div style="padding-bottom:100px; width:100px;"></div>
        </template>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-card flat>
          <v-card-text>
            <!-- Text Book Assignmant Body -->
            <v-card flat>
              <v-card-text>
                <b-form-group
                  id="fieldtitle"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Assignment Title"
                >
                  {{ this.assign.title }}
                </b-form-group>
                <b-form-group
                  id="fieldfile"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Assignment Doc"
                >
                  <a :href="this.assign.file" target="_blank"
                    >{{$t("Click to Open the Assignment Guide")}}</a
                  >
                </b-form-group>
                <b-form-group
                  id="fielddescription"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Assignment Description"
                >
                  <div v-html="this.description" class="foo" />
                </b-form-group>

                <b-form-group
                  id="fieldSubmission"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Assignment Submission"
                >
                  <quill-editor ref="answer" v-model="submit.submission" />
                </b-form-group>

                <v-expansion-panels style="margin-bottom:150px">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{$t("Video Submission")}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card dark style="background-color:#002147"
                        ><v-card-title>
                          <v-icon large color="orange darken-2"
                            >mdi-video-outline</v-icon
                          >
                          {{$t("Video Submission")}}
                        </v-card-title>

                        <v-card-text>
                          <v-row
                            ><v-col cols="12" lg="6">
                              <VueRecordVideo
                                @result="onResultVideo"
                                @stream="onStream"
                              />
                            </v-col>

                            <v-col cols="12" lg="6">
                              <video
                                v-show="videoRecording"
                                ref="camera"
                                :width="450"
                                :height="337.5"
                                autoplay
                              ></video>

                              <v-card v-if="this.videolink" light>
                                <v-card-subtitle style="color:#333"
                                  >{{$t("Previous Video Submission")}}</v-card-subtitle
                                >
                                <v-card-text>
                                  <video
                                    ref="playback"
                                    :src="this.videolink"
                                    :width="450"
                                    :height="337.5"
                                    controls
                                  ></video>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          {{ uploadVideo.toFixed() + "%" }}
                          <v-progress-linear
                            id="progress"
                            color="light-green"
                            height="10"
                            :value="uploadVideo"
                            max="100"
                            striped
                          ></v-progress-linear>
                        </v-card-actions>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{$t("Audio Submission")}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container v-if="reading">
                        <v-card>
                          <v-card-text>
                            <v-card flat outlined>
                              <v-card-title style="color:green"
                                >{{$t("Listen Before Reading")}}<v-divider /><span
                                  style="font-size:12px; color:#333;  margin-right:10px;"
                                  >{{$t("Click to Hear The Paragraph")}}</span
                                >
                                <vue-speech-synthesis
                                  :lang="this.lang"
                                  :text="reading"
                                  color="red"
                                  :rate="speed"
                              /></v-card-title>
                              <v-card-text>
                                {{ reading }}
                                <v-divider /> </v-card-text
                            ></v-card>

                            <br />

                            <v-card flat outlined shaped>
                              <v-card-title style="color:green"
                                >Practice Before Submitting<v-divider />

                                <span
                                  style="font-size:12px; color:#333; margin-right:10px;"
                                  >Similarity </span
                                >{{ Math.round(sim * 100) }}%
                                <span
                                  style="font-size:12px; color:green; margin-left:10px;"
                                  >Accurate</span
                                >

                                <span
                                  style="font-size:12px; color:#333; margin-right:10px; margin-left:10px;"
                                  >Confidence </span
                                >{{ Math.round(confidence * 100) }}%
                                <span
                                  style="font-size:12px; color:green; margin-left:10px;"
                                >
                                </span>
                              </v-card-title>

                              <v-card-text>
                                <v-btn
                                  @click="toggleStartStop()"
                                  color="error"
                                  depressed
                                  v-if="start"
                                  small
                                >
                                  <v-icon>mdi-stop</v-icon>Stop Reading</v-btn
                                >
                                <v-btn
                                  @click="toggleStartStop()"
                                  color="success"
                                  depressed
                                  v-if="!start"
                                  small
                                  ><v-icon>mdi-play</v-icon> Start
                                  Reading</v-btn
                                >

                                <v-divider />

                                {{ this.rtext }}
                              </v-card-text>
                              <v-card-actions>
                                <v-divider /><span
                                  style="font-size:12px; color:#333; margin-right:10px;"
                                >
                                  Playback</span
                                >
                                <vue-speech-synthesis
                                  :lang="this.lang"
                                  :text="rtext"
                                  color="green"
                                  :rate="speed"
                                  style=" margin-right:10px;"
                                />
                              </v-card-actions>
                            </v-card>
                            <div style="margin-top:10px">
                              <v-icon>mdi-head-cog-outline</v-icon>
                              <span
                                style="font-size:12px; color:red; margin-right:10px;"
                              >
                                It’s A.I. (Artificial Intelligence) you can't
                                reach 100% aim for higher than 80%</span
                              >
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-container>

                      <v-card dark style="background-color:#002147"
                        ><v-card-title>
                          <v-icon large color="orange darken-2"
                            >mdi-volume-high</v-icon
                          >
                          {{$t("Audio Submission")}}
                        </v-card-title>

                        <v-card-text>
                          <v-row
                            ><v-col cols="12" lg="6">
                              <audio-recorder
                                :attempts="1"
                                :time="30"
                                :show-upload-button="false"
                                :select-record="callback"
                              />
                              <br />
                              <span style="color:red;">
                                {{$t("Select the recorded audio that you want to upload and click Upload Audio")}}</span
                              >

                              <br />
                              <v-btn
                                @click="onResultAudio"
                                color="success"
                                small
                                depressed
                              >
                                <v-icon>mdi-tray-arrow-up</v-icon>
                                {{$t("Upload Audio")}}</v-btn
                              >
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-card v-if="this.audiolink" light>
                                <v-card-subtitle style="color:#333"
                                  >{{$t("Previous Audio Submission")}}</v-card-subtitle
                                >
                                <v-card-text>
                                  <Audio
                                    style="margin-left:10px"
                                    :src="this.audiolink"
                                    controls
                                  ></Audio>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          {{ uploadAudio.toFixed() + "%" }}
                          <v-progress-linear
                            id="progress"
                            color="light-green"
                            height="10"
                            :value="uploadAudio"
                            max="100"
                            striped
                          ></v-progress-linear>
                        </v-card-actions>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{$t("File Submission")}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card dark style="background-color:#002147"
                        ><v-card-title>
                          <v-icon large color="orange darken-2"
                            >mdi-file-document-multiple-outline</v-icon
                          >
                          {{$t("File Submission")}}</v-card-title
                        >
                        <v-card-text>
                          <v-card v-if="this.submit.file">
                            <v-card-text>
                              <a :href="this.submit.file" target="_blank">
                                <v-icon>mdi-file</v-icon> 
                                {{$t("Click Here to View Last Submission File")}}</a
                              >
                            </v-card-text></v-card
                          >
                          <br />
                          <v-row>
                            <v-col cols="8" md="8">
                              <v-file-input
                                v-model="files"
                                show-size
                                accept=".pdf , .doc, .docx, .ppt, .pptx, .xls, .xlsx, .jpg"
                                placeholder="Pick an File"
                                prepend-icon="mdi-file"
                                label="File (Accept Files Max Size 10 MB)"
                                @change="previewImage"
                              ></v-file-input>
                              {{ uploadValue.toFixed() + "%" }}
                              <v-progress-linear
                                id="progress"
                                color="light-blue"
                                height="10"
                                :value="uploadValue"
                                max="100"
                                striped
                              ></v-progress-linear>
                            </v-col>
                            <v-col cols="4" md="4">
                              <v-btn
                                @click="onUpload"
                                color="success"
                                small
                                depressed
                              >
                              {{$t("Upload File")}}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions>
            <!-- Quiz Footer -->
            <v-footer
              dark
              padless
              fixed
              height="80px"
              style="background-color:#1d2835;"
            >
              <v-col cols="6">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="cursor: pointer;" v-bind="attrs" v-on="on">
                      <img
                        src="@/assets/alarm.png"
                        width="40px"
                        style="margin-left:90px; "
                      />
                      <span style="margin-left:10px; font-size:small;"
                        >Teacher Note</span
                      >
                    </div>
                  </template>

                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      <img
                        src="@/assets/alarm.png"
                        width="50px"
                        style="margin-right:20px; "
                      />
                      Teacher Note
                    </v-card-title>

                    <v-card-text>
                      <br />
                      <audio
                        controls
                        v-if="this.assign.note"
                        style="width:100%; "
                        title="  Teacher Note"
                      >
                        <source :src="this.assign.note" type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">
                        Understood
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col cols="4"> </v-col>

              <!-- Next Button-->

              <v-col cols="2">
                <v-btn
                  color="green"
                  small
                  rounded
                  dark
                  @click="onSubmit"
                  style="margin-right:40px"
                >
                  <v-icon>mdi-chevron-right</v-icon>Submit Assignment
                </v-btn>
              </v-col>
            </v-footer>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import stringSimilarity from "string-similarity";
import moment from "moment";
export default {
  components: {},
  props: ["attempts","assAttempts","assignm"],
  data() {
    return {
      //Upload Image
      dialog: false,
      imageData: null,
      hidden: false,
      picture: null,
      uploadValue: 0,
      uploadVideo: 0,
      uploadAudio: 0,
      files: "",
      description: "",

      reading: "",
      assign: [],
      student: this.$store.state.studentpath,
      asspath: this.$store.state.asspath,
      subject: this.$store.state.subjecttitle,
      subjectpath: this.$store.state.oraginalsubject,
      grade: this.$store.state.grade,
      week: this.$store.state.weekpath,
      id: this.$store.state.id,
      submit: {},
      videourl: "",
      audiourl: "",
      videolink: "",
      audiolink: "",
      videoRecording: false,
      audioRecording: false,

      play: "",
      record: "",
      text: "",
      capturedText: [],

      rtext: ".....",
      confidence: 0,
      start: false,
      sim: 0,
      speed: 0.7,
      lang: "",
      //! Speech
      recognizing: "",
      recognition: window.speechRecognition || window.webkitSpeechRecognition
    };
  },
  methods: {
    toggleStartStop() {
      if (this.recognizing) {
        this.recognition.stop();
        this.recognizing = false;
        this.start = false;

        this.sim = stringSimilarity.compareTwoStrings(this.reading, this.rtext);
      } else {
        this.rtext = "";
        this.recognition.start();
        this.recognizing = true;
        this.start = true;
      }
    },
    previewImage(event) {
      this.imageData = event;
    },

    onUpload() {
      if (this.files.size / 1048576 > 10) {
        alert("The File Is Bigger Than 10 MB");
      } else {
        this.picture = null;
        const storageRef = firebase
          .storage()
          .ref(
            "schools/" +
              this.$store.state.school +
              "/assignment/" +
              Math.floor(Math.random() * 900000 + 1000000).toString() +
              this.files.name
          )
          .put(this.files);

        storageRef.on(
          `state_changed`,
          snapshot => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then(url => {
              this.picture = url;

              this.$swal(
                {
                  icon: "success",
                  title: "File Uploaded",
                  html: "File Uploaded Successfully"
                }
              );
            });
          }
        );
      }
    },

    callback(data) {
      this.audiourl = data.url;
    },
    onResultVideo(data) {
      // console.log("The blob data:", data);
      this.videourl = window.URL.createObjectURL(data);

      this.videoRecording = false;
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });

      if (this.videourl != null) {
        this.videolink = null;

        fetch(this.videourl)
          .then(res => res.blob())
          .then(blob => {
            const storageRef = firebase
              .storage()
              .ref(
                this.$store.state.school +
                  "/videos/" +
                  this.id +
                  Math.floor(
                    Math.random() * 1000000000 + 9000000000
                  ).toString() +
                  ".mp4"
              )
              .put(blob);

            storageRef.on(
              `state_changed`,
              snapshot => {
                this.uploadVideo =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              error => {
                console.log(error.message);
              },
              () => {
                this.uploadVideo = 100;
                storageRef.snapshot.ref.getDownloadURL().then(videourl => {
                  this.videolink = videourl;

                  this.$swal(
                    {
                      icon: "success",
                      title: "Video Uploaded",
                      html: "Video Uploaded Successfully"
                    }
                  );
                });
              }
            );
          });
      }
    },
    onResultAudio() {
      if (this.audiourl != null) {
        this.audiolink = null;

        fetch(this.audiourl)
          .then(res => res.blob())
          .then(blob => {
            const storageRef = firebase
              .storage()
              .ref(
                this.$store.state.school +
                  "/audio/" +
                  this.id +
                  Math.floor(
                    Math.random() * 1000000000 + 9000000000
                  ).toString() +
                  ".mp3"
              )
              .put(blob);

            storageRef.on(
              `state_changed`,
              snapshot => {
                this.uploadAudio =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              error => {
                console.log(error.message);
              },
              () => {
                this.uploadAudio = 100;
                storageRef.snapshot.ref.getDownloadURL().then(audiourl => {
                  this.audiolink = audiourl;

                  this.$swal(
                    {
                      icon: "success",
                      title: "Audio Uploaded",
                      html: "Audio Uploaded Successfully"
                    }
                  );
                });
              }
            );
          });
      }
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (
        this.picture == null &&
        this.videolink == "" &&
        this.audiolink == "" &&
        this.submit.submission == null
      ) {
        this.$bvToast.toast("Please upload your assignment first", {
          title: "Cant Submit",
          variant: "danger",
          solid: true
        });
        // alert("You Did't Submit anything");
      } else {
       
        if(this.assAttempts <= this.assignm.attempts){
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/quizes")
              .doc(this.$store.state.assid)
              .set({
                start_date: moment().format("DD-MM-YY"),
                start_time: moment().format("h:mm:ss a"),
                attempts: this.assAttempts,
                highestScore:"",
                highestRight:""
              })
              .then(() => {
                firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+'/attempts/'+this.assAttempts)
              .set({
                assid: this.$store.state.assid,
                asspath:this.asspath,
                assgrade:this.assignm.grade,
                date:moment().toISOString(),
                attempts: this.assAttempts,
                breakdown: this.$store.state?.lessonbreakdown,
                subjectTitle: this.$store.state.subjecttitle,
                class: this.$store.state.sclass,
                student:this.$store.state.email,
                branch: this.$store.state.branch,
                school: this.$store.state.school,
                weektitle: this.$store.state.weektitle,
                week: this.$store.state.weekpath,
                gradelevel:this.$store.state.grade,
                grade:this.assignm.grade,
                weightcode:this.$store.state.weightcode,
                missed:0,
                highestScore:0,
                highestRight:0,
                score:"",
                type:this.$store.state.asstype,
                weight:this.$store.state.weighttitle
              })
              .then(() => {
                if (this.picture != null) {
          this.submit.file = this.picture;
        }

        if (this.videolink != null) {
          this.submit.video = this.videolink;
        }

        if (this.audiolink != null) {
          this.submit.audio = this.audiolink;
        }

        this.submit.asspath = this.assignm.path;
        this.submit.assid = this.$store.state.assid;
        this.submit.assgrade = this.assignm.grade;
        this.submit.weight = this.assignm.weight;
        this.submit.weightcode = this.assignm.weightcode;
        this.submit.title = this.assignm.title;
        this.submit.date = new Date().toLocaleString();
        this.submit.breakdown=this.$store.state?.lessonbreakdown;
        this.submit.type = this.assignm.type;
        this.submit.subject = this.$store.state.subject;
        this.submit.weektitle = this.$store.state.weektitle;

        //! Analytics Data
        this.submit.school = this.$store.state.asspath.split("/")[1];
        this.submit.branch = this.$store.state.branch;
        this.submit.gradelevel = this.$store.state.asspath.split("/")[5];
        this.submit.subject = this.$store.state.asspath.split("/")[7];
        this.submit.class = this.$store.state.sclass;
        this.submit.student = this.id;
        this.submit.attempts = this.attempts;
        firebase
          .firestore()
          .collection(this.$store.state.oraginalsubject + "/submissions")
          .doc(this.$store.state.email + this.$store.state.assid)
          .set(this.submit)
          .then(() => {
            this.$bvToast.toast("Now to go to the home page", {
          title: "Submitted Carefully",
          variant: "success",
          solid: true
        });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });

        router.push({ name: "Home" });
              })

              });
            }else{
              let att = Number(this.assignm.attempts) + 1
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/quizes")
              .doc(this.$store.state.assid)
              .set({
                start_date: moment().format("DD-MM-YY"),
                start_time: moment().format("h:mm:ss a"),
                attempts: att,
                highestScore:"",
                highestRight:""
              })
              .then(() => {
                firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+'/attempts/'+att)
              .set({
                assid: this.$store.state.assid,
                asspath:this.asspath,
                assgrade:this.assignm.grade,
                date:moment().toISOString(),
                attempts: att,
                breakdown: this.$store.state?.lessonbreakdown,
                subjectTitle: this.$store.state.subjecttitle,
                class: this.$store.state.sclass,
                student:this.$store.state.email,
                branch: this.$store.state.branch,
                school: this.$store.state.school,
                weektitle: this.$store.state.weektitle,
                week: this.$store.state.weekpath,
                gradelevel:this.$store.state.grade,
                grade:this.assignm.grade,
                weightcode:this.$store.state.weightcode,
                missed:0,
                highestScore:0,
                highestRight:0,
                score:"",
                type:this.$store.state.asstype,
                weight:this.$store.state.weighttitle
              })
              .then(() => {
                if (this.picture != null) {
          this.submit.file = this.picture;
        }

        if (this.videolink != null) {
          this.submit.video = this.videolink;
        }

        if (this.audiolink != null) {
          this.submit.audio = this.audiolink;
        }

        this.submit.asspath = this.assignm.path;
        this.submit.assid = this.$store.state.assid;
        this.submit.assgrade = this.assignm.grade;
        this.submit.weight = this.assignm.weight;
        this.submit.weightcode = this.assignm.weightcode;
        this.submit.title = this.assignm.title;
        this.submit.date = new Date().toLocaleString();

        this.submit.type = this.assignm.type;
        this.submit.subject = this.$store.state.subject;
        this.submit.weektitle = this.$store.state.weektitle;

        //! Analytics Data
        this.submit.school = this.$store.state.asspath.split("/")[1];
        this.submit.branch = this.$store.state.branch;
        this.submit.gradelevel = this.$store.state.asspath.split("/")[5];
        this.submit.subject = this.$store.state.asspath.split("/")[7];
        this.submit.class = this.$store.state.sclass;
        this.submit.student = this.id;
        this.submit.attempts = this.attempts;
        firebase
          .firestore()
          .collection(this.$store.state.oraginalsubject + "/submissions")
          .doc(this.$store.state.email + this.$store.state.assid)
          .set(this.submit)
          .then(() => {
            this.$bvToast.toast("Now to go to the home page", {
          title: "Submitted Carefully",
          variant: "success",
          solid: true
        });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
        //   this.$bvToast.toast("Assignment Submitted", {
        //   title: "Assignment Submitted Successfully",
        //   variant: "success",
        //   solid: true
        // });

        //router.back();
        router.push({ name: "Home" });
              })

              });
            }

      }
    },
    checkDate(opendate, closedate) {
      if (
        Date.now() >= Date.parse(opendate) &&
        Date.now() <= Date.parse(closedate)
      )
        return true;
      else return false;
    },

    toggleCamera() {
      // if(this.isCameraOpen) {
      //   this.isCameraOpen = false;
      //   this.isPhotoTaken = false;
      //   this.isShotPhoto = false;
      //   this.stopCameraStream();
      // } else {
      this.isCameraOpen = true;
      this.createCameraElement();
      // }
    },

    onStream() {
      this.videoRecording = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          //this.videoRecording = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          //this.videoRecording = false;
          alert(
            "May the browser didn't support or there is some errors." + error
          );
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    }
  },
  created() {
    console.log(this.$store.state.assid)
    console.log(this.$store.state.oraginalsubject)
    if (this.subject == "Arabic" || this.subject == "Arabic Social Studies") {
      this.lang = "ar-EG";
    } else {
      this.lang = "en";
    }

    //! Get Assignment Full Data
    firebase
      .firestore()
      .doc(this.asspath)
      .get()
      .then(doc => {
        this.temp = [];
        this.temp.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          type: doc.data().type,
          grade: doc.data().grade,
          weight: doc.data().weight,
          weightcode: doc.data().weightcode,
          opendate: doc.data().opendate,
          closedate: doc.data().closedate,
          // description: doc.data().description,
          quiztype: doc.data().quiztype,
          count: doc.data().count,
          week: doc.data().week,
          note: doc.data().note,
          file: doc.data().file,
          // reading: doc.data().reading,

          attempts: doc.data().attempts
        });

        this.assign = this.temp[0];
        //this.videolink = this.temp[0].video;

        firebase
          .firestore()
          .doc(this.asspath + "/details/description")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.description = snapshot.data().description;
          });

        firebase
          .firestore()
          .doc(this.asspath + "/details/reading")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.reading = snapshot.data().reading;
          });

        //! Kamal Shalaby
        firebase
          .firestore()
          .collection(this.subjectpath + "/submissions")
          .doc(this.id + this.assign.key)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.submit = doc.data();

              this.videolink = this.submit.video ?? "";
              this.audiolink = this.submit.audio ?? "";
            }
          });
      });
    //!Speech
    this.recognition = new window.webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.lang = this.lang;
    this.recognizing = false;
  },
  async mounted() {
    let vm = this;
    this.recognition.onresult = function(event) {
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          vm.rtext += event.results[i][0].transcript;
          vm.confidence = event.results[i][0].confidence;
        }
      }
    };
  }
};
</script>
<style scoped>
.foo >>> img {
  width: 400px;
}
</style>

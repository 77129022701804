<template>
  <div>
    <div v-if="boards.length == 0" style="text-align:center">
      <img src="@/assets/search.png" width="50%" />
      <br />

      <div style="color:#ffffff">No Activities In This Lesson</div>
    </div>

    <vue-timeline-update 
      v-else
      v-for="activity in boards"
      :description="activity.title.substring(0, 50)"
      :date="activity.date"
      :category="title[activity.type]"
      :icon="icon[activity.type]"
      :color="color[activity.type]"
      :key="activity.id"
    />
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "LessonList",
  components: {},
  props: ["path"],

  data() {
    return {
      title: {
        video: "video",
        question: "question",
        show: "show",
        "Live Session": "Live Session",
        flipped: "flipped Prep",
        exit: "Exit Prep"
      },
      color: {
        video: "orange",
        question: "blue",
        show: "white",
        "Live Session": "red",
        flipped: "green",
        exit: "red"
      },
      icon: {
        video: "videocam",
        question: "help",
        show: "import_contacts",
        "Live Session": "play_arrow",
        flipped: "settings_backup_restore",
        exit: "exit_to_app"
      },
      fields: [
        {
          field: "order",
          label: "Order",
          sortable: true,
          class: "text-left"
        },
        {
          field: "section",
          label: "Section",
          sortable: true,
          class: "text-left"
        },

        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },

        {
          field: "term",
          label: "Term",
          sortable: true
          // class: "text-left"
        },
        {
          field: "flipped",
          label: "Flipped",
          class: "text-center"
        },
        {
          field: "lesson",
          label: "Lesson",
          class: "text-center"
        },
        {
          field: "bank",
          label: "Quesions",
          class: "text-center"
        },
        {
          field: "assignments",
          label: "Assignments",
          class: "text-center"
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center"
        }
      ],

      boards: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.path + "/lesson/")
        .orderBy("order"),

      subjectTitle: this.title
    };
  },
  created() {
    this.ref.get().then(querySnapshot => {
      this.boards = [];
      querySnapshot.forEach(doc => {
        this.boards.push({
          key: doc.id,
          path: doc.ref.path,
          section: doc.data().section,
          title: doc.data().title,
          term: doc.data().term,
          type: doc.data().type,
          order: doc.data().order,
          date: doc.data().livedate
        });
      });
    });
  },
  methods: {
    deleteWeek(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/weeks")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Week Deleted", {
                  title: "Week Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    goToWeek(path, title) {
      router.push({
        name: "WeekPage",
        params: {
          path: path,
          title: title
        }
      });
    },
    goToAssign(path, title) {
      router.push({
        name: "AssignmentsList",
        params: {
          id: path,
          title: title
        }
      });
    },

    goToBank(path, title) {
      router.push({
        name: "BankList",
        params: {
          id: path,
          title: title
        }
      });
    },
    flipped(type) {
      console(type);
    },
    Edit(path) {
      router.push({
        name: "EditWeek",
        params: {
          path: path,
          subject: this.path
        }
      });
    }
  }
};
</script>

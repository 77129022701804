<template>
    <v-row>
      <!-- Side Exam Panel -->
  
      <v-col cols="3">
        <v-navigation-drawer
          fixed
          dark
          src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/reportbg.jpg?alt=media&token=2de70a5a-87ac-4722-ada6-899575dad3ef"
          style="z-index: 2; padding-left: 50px"
          width="28%"
          permanent
        >
          <!-- Activity Title -->
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <h4 style="padding-top: 30px">{{ this.sub.weight }}</h4>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ this.submission }}
                  <br />
                  <span>{{ this.sub.title }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <!-- Side Exam Panel -->
      <v-col cols="9" :style="$i18n.locale=='Arabic'? {'margin-left': '28%'} : ''">
        <v-container>
          <v-card>
            <v-card-title>{{$t("Submission Report")}}</v-card-title>
            <v-card-text
              ><h5>{{$t("Your Grade")}}</h5>
              <h3 style="color: green" v-if="this.sub.grade">
                {{
                  this.right+
                  "/" +
                  (this.total)
                }}
              </h3>
              <h3 style="color: green" v-else>
                {{
                  this.right+
                  "/" +
                  (this.total)
                }}
              </h3>
  
              <v-row>
                <v-col cols="12" style="text-align: left">
                  <v-card outlined style="border-style: solid; border-width: 1px">
                    <v-card-title>
                      <h5>{{$t("Lesson Answers")}}</h5>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="this.sub.score > 80">
                        <img src="@/assets/reports/80.png" width="50%" />
                        <br />
                        <br />
                        <h6>
                          {{ this.$store.state.firstname }}, Congratulations,
                          you’ve mastered this topic!
                        </h6>
                      </div>
                      <div
                        v-else-if="this.sub.score >= 60 && this.sub.score <= 80"
                      >
                        <img src="@/assets/reports/61.png" width="50%" />
                        <br />
                        <br />
                        <h6>
                          {{ this.$store.state.firstname }}, Congratulations, your
                          work here is commendable.
                        </h6>
                      </div>
                      <div
                        v-else-if="this.sub.score >= 41 && this.sub.score <= 60"
                      >
                        <img src="@/assets/reports/41.png" width="50%" />
                        <br />
                        <br />
                        <h6>
                          {{ this.$store.state.firstname }}, Well-done, you’ve
                          done a good job.
                        </h6>
                      </div>
                      <div
                        v-else-if="this.sub.score >= 21 && this.sub.score <= 40"
                      >
                        <img src="@/assets/reports/21.png" width="50%" />
                        <br />
                        <br />
                        <h6>
                          {{ this.$store.state.firstname }}, watch out, that
                          wasn’t your best work. You can do better next time!
                        </h6>
                      </div>
                      <div v-else-if="this.sub.score < 21">
                        <img src="@/assets/reports/0.png" width="50%" />
                        <br />
                        <br />
                        <h6>
                          {{ this.$store.state.firstname }}, Oops, you didn’t make
                          it, That’s okay, take your time and go over this lesson
                          one more time.
                        </h6>
                      </div>
  
<div :style="$i18n.locale=='Arabic'? {'text-align': 'right'} : ''">
  <img
                        src="@/assets/reports/questions.png"
                        width="30px"
                        style="margin-top: 10px"
                      />
                      {{ this.total
                      + $t("Total Questions")}}
  
                      <br />
  
                      <img
                        src="@/assets/reports/right.png"
                        width="30px"
                        style="margin-top: 10px"
                      />
                      {{ this.right +" "+ $t("Of Your Answer is Correct") }} 
  
                      <br />
  
                      <img
                        src="@/assets/reports/wrong.png"
                        width="30px"
                        style="margin-top: 10px"
                      />
                      {{ this.wrong + " "+$t("Of Your Answer is Incorrect") }} 
               
</div>
<br />
                      <v-row>
                        <v-col cols="12" sm="6" xs="6" style="text-align: left">
                          <v-card
                            outlined
                            style="border-style: solid; border-width: 1px"
                          >
                            <v-card-title>{{$t("Questions Review")}}</v-card-title>
                            <v-card-text>
                              <v-list dense>
                                <v-list-item-group color="primary">
                                  <QuestionPreview
                                    v-for="(q, index) in questions"
                                    :key="q.key"
                                    :title="$t('Question')+' '+(index + 1)"
                                    :answer="q.answer"
                                    :path="q.question"
                                  />
                                </v-list-item-group>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-col>
  
                        <v-col cols="12" sm="6" xs="6" style="text-align: left">
                          <v-card
                            outlined
                            style="border-style: solid; border-width: 1px"
                          >
                            <v-card-title>{{$t("Weakness")}}</v-card-title>
                            <v-card-text>
                         
                                <Standard
                              v-for="standard in standards" :key="standard.key"
                                :code="standard.standard"
                                :right="standard.right"
                                :wrong="standard.wrong"
                              />
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </template>
  <script>
  import firebase from "@/Firebase";
  import QuestionPreview from "./QuestionPreview";
  import Standard from "@/components/reports/Standard.vue";
  
  export default {
    components: { QuestionPreview ,Standard},
    data() {
      return {
        submission: this.$store.state.submissionpath,
        sub: [],
        questions: [],
        standards: [],
        loading: false,
        subject: this.$store.state.subjecttitle,
        right:0,
        total:0,
        wrong:0,
        grade: 0,
        week: this.$store.state.weekpath,
        //Score Chart
        doseries: [0, 0, 0],
        dochartOptions: {
          colors: ["#059612", "#e1261c", "#ff8400"],
          chart: {
            type: "donut",
          },
          labels: ["Right", "Wrong", "Missed"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
    created() {
      this.right = 0;
      this.wrong = 0;
      this.total = 0;

      firebase
          .firestore()
          .collection(
            this.$store.state.studentpath +
              "/quizes/" +
              this.$store.state.assid +
              "/answers_new/"
          )
          .where("attempts","==",this.$route.params.attempt)
          .get()
          .then((querySnapshot) => {
            console.log(querySnapshot.docs)
            //this.total = querySnapshot.size;
            this.questions = [];
            querySnapshot.forEach((doc) => {
              this.questions.push({
                key: doc.id,
                path: doc.ref.path,
                title: doc.data().title,
                type: doc.data().qtype,
                answer: doc.data().answer,
                date: doc.data().date,
                question: doc.data().question,
              });
              if(doc.data().answer == "Right"){
                    this.right = this.right + 1;
                }else{
                    this.wrong = this.wrong + 1;
                }
              });
              this.total =this.$route.params.total;
          });

          this.getStandards();
      // firebase
      //   .firestore()
      //   .doc(this.submission)
      //   .get()
      //   .then((snapshot) => {
      //     if (!snapshot.exists) return;
      //     this.sub = snapshot.data();
      //     this.doseries = [this.sub.right, this.sub.wrong, this.sub.missed];
  
      //     firebase
      //       .firestore()
      //       .collection(
      //         this.$store.state.studentpath +
      //           "/quizes/" +
      //           this.$store.state.assid +
      //           "/answers_new/"
      //       )
      //       .where("attempts","==",this.$route.params.attempt)
      //       .get()
      //       .then((querySnapshot) => {
      //         this.questions = [];
      //         querySnapshot.forEach((doc) => {
      //           console.log(doc.data())
      //           this.questions.push({
      //             key: doc.id,
      //             path: doc.ref.path,
      //             title: doc.data().title,
      //             type: doc.data().qtype,
      //             answer: doc.data().answer,
      //             date: doc.data().date,
      //             question: doc.data().question,
      //           });
      //           if(doc.data().answer == "Right"){
      //               this.right = this.right + 1;
      //           }else{
      //               this.wrong = this.wrong + 1;
      //           }
      //         });
      //         this.total = this.questions.length;
      //       });
  
      //       this.getStandards();
  
      //     this.loading = true;
      //   });
    },
    methods: {
      getStandards() {
        firebase
          .firestore()
          .collection(
            this.$store.state.studentpath +
              "/quizes/" +
              this.$store.state.assid+
              "/weakness_new"
          )
          .where("attempts","==",this.$route.params.attempt)
          .get()
          .then((querySnapshot) => {
            console.log("hereee")
            this.standards = [];
            querySnapshot.forEach((doc) => {
            if(this.standards.find(el => el.standard == doc.data().standard)){
            let el = this.standards.find(el => el.standard == doc.data().standard)
            el.right = Number(el.right) + Number(doc.data().right);
            el.wrong = Number(el.wrong) + Number(doc.data().wrong);
          }else{
            this.standards.push({
              key: doc.id,
              path: doc.ref.path,
              standard: doc.data().standard,
              parent: doc.data().parent,
              right: doc.data().right,
              wrong: doc.data().wrong,
            });
          }
              // this.standards.push({
              //   key: doc.id,
              //   path: doc.ref.path,
              //   standard: doc.data().standard,
              //   parent: doc.data().parent,
              //   right: doc.data().right,
              //   wrong: doc.data().wrong,
              // });
            });
          });
      },
    },
  };
  </script>
  
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-subtitle v-html="this.title"></v-card-subtitle>
          <v-card-text>
            <iframe
              v-if="this.path.includes('vimeo')"
              :src="this.path"
              width="100%"
              height="400px"
              style="border:0"
              allowfullscreen
            ></iframe>
            <video-embed :src="this.path" v-else></video-embed>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="sameVideos.length > 0">
        <v-card class="mx-auto" tile>
          <v-list dense>
            <v-subheader>
              If you have a problem in understanding this video please view the
              below videos</v-subheader
            >
            <v-list-item-group color="primary">
              <v-list-item
                v-for="n in sameVideos"
                :key="n.key"
                @click="changeVideo(n.link, n.title)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-youtube</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ n.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <br />
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "video-body",
  props: ["path", "title", "system", "grade", "subject", "week"],
  data() {
    return {
      wrong: false,
      right: false,
      sameVideos: [],
      options: []
    };
  },

  created() {
    firebase
      .firestore()
      .collection(
        "activities_hub/" +
          this.system +
          "/grades/" +
          this.grade +
          "/subjects/" +
          this.subject +
          "/videos"
      )
      .where("week", "==", 1)
      .get()
      .then(querySnapshot => {
        this.sameVideos = [];
        querySnapshot.forEach(doc => {
          this.sameVideos.push({
            key: doc.id,
            title: doc.data().title,
            link: doc.data().link
          });
        });
      });
  },
  methods: {
    // changeVideo(link, title) {

    //   this.path = link;
    //   this.title = title;
    // }
  }
};
</script>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/reportbg.jpg?alt=media&token=2de70a5a-87ac-4722-ada6-899575dad3ef"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">{{$t("Progress Reports")}}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9" :style="$i18n.locale=='Arabic'? {'margin-left': '28%'} : ''">
      <v-container>
        <div id="printMe">
          <v-card outlined style="padding:30px">
            <v-card-title>
              <v-icon @click="print">mdi-printer</v-icon>
              <v-divider></v-divider>
              <h5>{{$t("Progress Reports")}}</h5>
              <v-divider></v-divider>
              <img :src="this.$store.state.logo" width="100px" />
            </v-card-title>

            <v-card-text>
              <h5 style="color: #3c83ce">
                {{ this.$route.params.title }}
              </h5>
              <div v-if="this.submissions.length > 0">
                <h4>{{$t("Lesson Progress")}}</h4>

                <v-divider />
                <h4>{{$t("Your Score")}}</h4>
                <h3 style="color: green">
                  {{ Number(this.score.toFixed(1)) }}%
                </h3>

                <v-row>
                  <v-col cols="12" style="text-align: left">
                    <v-card
                      outlined
                      style="border-style: solid;  border-width: 1px;"
                    >
                      <v-card-title>
                        <h5>{{$t("Lesson Answers")}}</h5>
                      </v-card-title>
                      <v-card-text :style="$i18n.locale=='Arabic'? {'text-align': 'right'} : ''">
                        <div v-if="score > 80">
                          <img src="@/assets/reports/80.png" width="80%" />
                          <br />
                          <br />
                          <h6>
                            {{ this.$store.state.firstname +' ' +$t("Congratulations,you’ve mastered this topic!")}}
                          </h6>
                        </div>
                        <div v-else-if="score >= 60 && score <= 80">
                          <img src="@/assets/reports/61.png" width="80%" />
                          <br />
                          <br />
                          <h6>
                            {{ this.$store.state.firstname }} {{$t("Congratulations, your work here is commendable.")}}
                          </h6>
                        </div>
                        <div v-else-if="score >= 41 && score <= 60">
                          <img src="@/assets/reports/41.png" width="80%" />
                          <br />
                          <br />
                          <h6>
                            {{ this.$store.state.firstname }}  {{$t("Well-done, you’ve done a good job.")}}
                          </h6>
                        </div>
                        <div v-else-if="score >= 21 && score <= 40">
                          <img src="@/assets/reports/21.png" width="80%" />
                          <br />
                          <br />
                          <h6>
                            {{ this.$store.state.firstname }} {{$t("watch out, that wasn’t your best work. You can do better next time!")}}
                          </h6>
                        </div>
                        <div v-else-if="score < 21">
                          <img src="@/assets/reports/0.png" width="80%" />
                          <br />
                          <br />
                          <h6>
                            {{ this.$store.state.firstname }} {{$t("Oops, you didn’t make it, That’s okay, take your time and go over this lesson one more time.")}}
                          </h6>
                        </div>

                        <img
                          src="@/assets/reports/questions.png"
                          width="30px"
                          style="margin-top: 10px"
                        />
                        {{ this.submissions.length }}
                        {{$t("Total Questions")}}

                        <br />

                        <img
                          src="@/assets/reports/right.png"
                          width="30px"
                          style="margin-top: 10px"
                        />
                        {{ this.totalRight.length }} {{$t("Answers Correct")}}

                        <br />

                        <img
                          src="@/assets/reports/wrong.png"
                          width="30px"
                          style="margin-top: 10px"
                        />
                        {{ this.totalWrong.length }} {{$t("Answers Incorrect")}}
                        <br />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    sm="12"
                    xs="12"
                    lg="6"
                    style="text-align: left"
                  >
                    <v-card
                      outlined
                      style="border-style: solid;  border-width: 1px;"
                    >
                      <v-card-title>{{$t("Questions Answers")}}</v-card-title>
                      <v-card-text>
                        <div id="chart">
                          <apexchart
                            type="donut"
                            width="365"
                            :options="dochartOptions"
                            :series="doseries"
                          ></apexchart>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    cols="6"
                    sm="12"
                    xs="12"
                    lg="6"
                    style="text-align: left"
                  >
                    <v-card
                      outlined
                      style="border-style: solid;  border-width: 1px;"
                    >
                      <v-card-title>{{$t("Difficulty Level")}}</v-card-title>
                      <v-card-text>
                        <div id="chart">
                          <apexchart
                            type="radialBar"
                            height="345"
                            :options="chartOptions"
                            :series="series"
                          ></apexchart>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" xs="6" style="text-align: left">
                    <v-card
                      outlined
                      style="border-style: solid;  border-width: 1px;"
                    >
                      <v-card-title>{{$t("Depth of Knowledge")}}</v-card-title>
                      <v-card-text>
{{ $t("The DOK has 4 levels. Depth of Knowledge, or DOK, is a way to think about content complexity, not content difficulty. That is the first thing with which people must come to understanding. Complexity is different from difficulty. For example, if students have not seen the word or content before, it might be difficult for them, but it is not complex.") }}
                        <div id="chart">
                          <apexchart
                            type="radar"
                            width="100%"
                            :options="dokOptions"
                            :series="dokseries"
                            style="direction: ltr;"
                          ></apexchart>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" xs="6" style="text-align: left">
                    <v-card
                      outlined
                      style="border-style: solid;  border-width: 1px;"
                    >
                      <v-card-title>Bloom</v-card-title>
                      <v-card-text>
                        {{$t("Bloom's taxonomy is a set of three hierarchical models used to classify educational learning objectives into levels of complexity and specificity. ... The models were named after Benjamin Bloom, who chaired the committee of educators that devised the taxonomy.")}}
                        <div id="chart">
                          <apexchart
                            type="bar"
                            width="100%"
                            :options="bloomchartOptions"
                            :series="bloomseries"
                            style="direction: ltr;"
                          ></apexchart>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" style="text-align: left">
                    <v-card
                      outlined
                      style="border-style: solid;  border-width: 1px;"
                    >
                      <v-card-title>{{$t("Weaknesses")}}</v-card-title>
                      <v-card-text>
                 
                      
                            <Standard
                            v-for="standard in standards" :key="standard.key"
                              :code="standard.standard"
                              :right="standard.right"
                              :wrong="standard.wrong"
                            />
                         
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
            
              </div>
              <div v-else style="text-align: center">
                <img src="@/assets/search.png" width="500px" />
                <h3>{{$t("There is no submission yet")}}</h3>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import Standard from "./Standard.vue";
import apexchart from 'vue-apexcharts';

export default {
  name: "activityReport",
  components: { Standard,apexchart },
  data() {
    return {
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      // Bloom Chart
      level1Right: [],
      level1Wrong: [],

      level2Right: [],
      level2Wrong: [],

      level3Right: [],
      level3Wrong: [],

      level4Right: [],
      level4Wrong: [],

      level5Right: [],
      level5Wrong: [],

      level6Right: [],
      level6Wrong: [],

      bloomseries: [
        {
          name: "Right",
          data: [44, 55, 41, 64, 22, 43]
        },
        {
          name: "Wrong",
          data: [53, 32, 33, 52, 13, 44]
        }
      ],
      bloomchartOptions: {
        colors: ["#089d13", "#FF4560"],
        chart: {
          toolbar: {
            show: false
          },
          type: "bar",
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"]
          }
        },
        stroke: {
          show: true,
          width: 0,
          colors: ["#fff"]
        },
        xaxis: {
          categories: [
            "Knowledge (Remembering)",
            "Comprehension (Understanding)",
            "Application (Transferring)",
            "Analysis (Relating)",
            "Evaluation (Judging)",
            "Synthesis (Creating)"
          ]
        }
      },

      //Bloom Chart End

      // Difficulty Chart
      series: [2, 2, 2],
      chartOptions: {
        chart: {
          height: 190,
          type: "radialBar"
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "20%",
              background: "transparent",
              image: undefined
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: false
              }
            }
          }
        },
        colors: ["#089d13", "#0082ca", "#f22e2e"],
        labels: ["Easy %", "Medium %", "Difficult %"],
        legend: {
          show: true,
          floating: false,
          fontSize: "16px",
          position: "left",
          offsetX: 60,
          offsetY: 15,
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false
              }
            }
          }
        ]
      },
      //End Difficulty Chart

      datacollection: null,
      lesson: [],
      flipped: [],
      submissions: [],
      totalRight: [],
      totalWrong: [],

      standards: [],
      RightCount: 1,
      WrongCount: 8,
      exit: [],
      score: 0,

      school: this.$store.state.school,

      //Difficulty var
      dif1: [],
      dif2: [],
      dif3: [],

      //Dok var
      dok1: [],
      dok2: [],
      dok3: [],
      dok4: [],

      //Score Chart
      doseries: [1, 1],
      dochartOptions: {
        colors: ["#059612", "#e1261c"],
        chart: {
          type: "donut"
        },
        labels: ["Right", "Wrong"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },

      //DoK Chart

      dokseries: [
        {
          name: "Series 1",
          data: [1, 3, 2, 1]
        }
      ],
      dokOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: 400,
          type: "radar"
        },

        xaxis: {
          categories: [
            "Recall",
            "Skill Concept",
            "Strategic Thinking",
            "Extended Thinking"
          ]
        }
      }
    };
  },
  methods: {
    print() {
      // Pass the element id here
      this.$htmlToPaper("printMe");
    },

    getStandards(){
      firebase
      .firestore()
      .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
      .where("attempts","==",this.$route.params.attempts)
      .get()
      .then(querySnapshot => {
        this.standards = [];
        querySnapshot.forEach(doc => {
          console.log(doc.data())
          if(this.standards.find(el => el.standard == doc.data().standard)){
            let el = this.standards.find(el => el.standard == doc.data().standard)
            el.right = Number(el.right) + Number(doc.data().right);
            el.wrong = Number(el.wrong) + Number(doc.data().wrong);
          }else{
            this.standards.push({
            key: doc.data().standard,
            path: doc.ref.path,
            standard: doc.data().standard,
            parent: doc.data().parent,
            right: doc.data().right,
            wrong: doc.data().wrong,
          });
          }

          });
          });
      
      },


     getData(){
      this.submissions = [];
      firebase
      .firestore()
      .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
      .where("attempts","==",this.$route.params.attempts)
      .get()
      .then(querySnapshot => {
        this.submissions = [];
        querySnapshot.forEach(doc => {
          console.log(doc.data().attempts)
          this.submissions.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            type: doc.data().qtype,
            answer: doc.data().answer,
            date: doc.data().date
          });

         
          if (doc.data().answer == "Right") {
            this.totalRight.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (doc.data().answer == "Wrong") {
            this.totalWrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          }

         
          //Difficulty

          if (doc.data().difficulty == "1") {
            this.dif1.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (doc.data().difficulty == "2") {
            this.dif2.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().difficulty == "3" ||
            doc.data().difficulty == "4" ||
            doc.data().difficulty == "5" ||
            doc.data().difficulty == "6"
          ) {
            this.dif3.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          }

          if (doc.data().dok == "Recall") {
            this.dok1.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (doc.data().dok == "Skill Concept") {
            this.dok2.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (doc.data().dok == "Strategic Thinking") {
            this.dok3.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (doc.data().dok == "Extended Thinking") {
            this.dok4.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          }

          //!Bloom
          if (
            doc.data().bloom == "Knowledge (Remembering)" &&
            doc.data().answer == "Right"
          ) {
            this.level1Right.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Knowledge (Remembering)" &&
            doc.data().answer == "Wrong"
          ) {
            this.level1Wrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Comprehension (Understanding)" &&
            doc.data().answer == "Right"
          ) {
            this.level2Right.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Comprehension (Understanding)" &&
            doc.data().answer == "Wrong"
          ) {
            this.level2Wrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Application (Transferring)" &&
            doc.data().answer == "Right"
          ) {
            this.level3Right.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Application (Transferring)" &&
            doc.data().answer == "Wrong"
          ) {
            this.level3Wrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Analysis (Relating)" &&
            doc.data().answer == "Right"
          ) {
            this.level4Right.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Analysis (Relating)" &&
            doc.data().answer == "Wrong"
          ) {
            this.level4Wrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Evaluation (Judging)" &&
            doc.data().answer == "Right"
          ) {
            this.level5Right.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Evaluation (Judging)" &&
            doc.data().answer == "Wrong"
          ) {
            this.level5Wrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Synthesis (Creating)" &&
            doc.data().answer == "Right"
          ) {
            this.level6Right.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          } else if (
            doc.data().bloom == "Synthesis (Creating)" &&
            doc.data().answer == "Wrong"
          ) {
            this.level6Wrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date
            });
          }
          //! End Of Bloom
        });
        console.log(this.submissions.length)
        this.score = (this.totalRight.length / this.submissions.length) * 100;

        this.RightCount = this.totalRight.length;
        this.WrongCount = this.totalWrong.length;
        this.doseries = [this.RightCount, this.WrongCount];
        this.series = [
          ((this.dif1.length / this.submissions.length) * 100).toFixed(1),
          ((this.dif2.length / this.submissions.length) * 100).toFixed(1),
          ((this.dif3.length / this.submissions.length) * 100).toFixed(1)
        ];

        this.dokseries = [
          {
            name: "Total Questions",
            data: [
              this.dok1.length,
              this.dok2.length,
              this.dok3.length,
              this.dok4.length
            ]
          }
        ];

        //Bloom


        console.log(this.level1Right.length);

        this.bloomseries = [
          {
            name: "Right",
            data: [
              this.level1Right.length,
              this.level2Right.length,
              this.level3Right.length,
              this.level4Right.length,
              this.level5Right.length,
              this.level6Right.length
            ]
          },
          {
            name: "Wrong",
            data: [
              this.level1Wrong.length,
              this.level2Wrong.length,
              this.level3Wrong.length,
              this.level4Wrong.length,
              this.level5Wrong.length,
              this.level6Wrong.length
            ]
          }
        ];
      });
  
  }
  },
  created() {
  console.log(this.$route.params.attempts)
  this.getData();
  this.getStandards();
  
  },


 
};
</script>

<template>
  <v-app id="inspire" v-bind:class="this.$i18n.locale=='Arabic' ? 'arabic':''">
    <router-view></router-view>
  </v-app>
</template>

<script>
import router from "@/router";
import firebase from "@/Firebase";

export default {
  name: "App",
  components: {},
  props: {
    source: String
  },

  data: () => ({
    dialog: false,
    drawer: null,
    domain: ""
  }),
  methods: {
    goToPage(page) {
      router.push({ name: page });
    },
    signOut() {
      this.$store.state.loggedIn = false;
      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.href = "/school/" + this.$store.state.school;
        });
    }
  },
  created() {
    this.domain = window.location.host;
    console.log(this.$i18n.locale)
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Lalezar"); */

#inspire {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  background-color: #eff0f5;
}

.fooar{ color:#ffffff; text-align: right; direction: rtl;
font-family: 'arabic';
}

.card-text {
  padding: 20px;
}
.arabic {
    font-family: "arabic";
    text-align: right;
    direction: rtl !important;
}

</style>

<template>
  <div>
    <v-navigation-drawer
      src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/lessonheader.jpg?alt=media&token=5abb4495-65bf-4f3d-8aeb-158b1d69b3a8"
      height="300px"
      width="100%"
      permanent
    >
      <div style="padding-left:30px;  padding-top:70px; width:50%; color:#fff;" class="fooar">
        <h5>{{ this.$store.state.weektitle }}</h5>

        {{ subject + " " + grade }}
      </div>
    </v-navigation-drawer>

    <v-card flat
      ><v-card-text>
    
      
        <div v-if="lessonview == 'Lesson Page'">
          <v-row>
            <v-col cols="3">
              <span @click="view = 'sections'" style="cursor: pointer">
                <v-card dark style="background-color:#002147;" flat outlined>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <img src="@/assets/lesson.png" width="50px" />
                      </v-col>
                      <v-col cols="12" lg="8" style="text-align:center;">
                        <h6 style="color:#ffffff">{{ $t("Lesson Page") }} </h6>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </span>
            </v-col>
            <v-col cols="3">
              <span @click="view = 'timeline'" style="cursor: pointer;">
                <v-card dark style="background-color:#002147;" flat outlined>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <img src="@/assets/prep.png" width="50px" />
                      </v-col>
                      <v-col cols="12" lg="8" style="text-align:center;">
                        <h6 style="color:#ffffff">{{ $t("Flipped Classroom") }}</h6>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </span>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-row>
            <span @click="view = 'sections'" style="cursor: pointer">
              <v-card dark style="background-color:#e25141">
                <v-card-text>
                  <v-icon style="margin-left: 15px" size="50"
                    >mdi-server</v-icon
                  >
                  <div>{{ $t("Lesson Page") }}</div>
                </v-card-text>
              </v-card>
            </span>

            <span
              @click="view = 'timeline'"
              style="cursor: pointer; margin-left: 20px"
            >
              <v-card dark style="background-color:#67ac5b">
                <v-card-text>
                  <v-icon style="margin-left: 15px" size="50"
                    >mdi-timeline-text-outline</v-icon
                  >
                  <div>{{ $t("Flipped Classroom") }}</div>
                </v-card-text>
              </v-card>
            </span>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" sm="8" xs="8">
        <span v-if="view == 'sections'">
          <v-card
            flat
            v-if="this.objectives"
            outlined
            style="margin-inline:20px;"
          >
            <v-card-title>{{$t("Lesson Objectives")}}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="10">
                  <div
                    v-html="this.objectives"
                    :class="
                      subject == 'Arabic' || subject == 'Arabic Social Studies'
                        ? 'fooar'
                        : 'foo'
                    "
                  ></div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br />

          <v-card
            @click="goToVideos($store.state.weekpath, 'videos')"
            flat
            outlined
            style="margin-inline:10px;"
            ><v-card-text>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">{{$t("Videos")}}</div>
                  <v-list-item-title class="headline mb-1">
                   {{ $t("Lesson Videos") }} 
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t("Educational Shows") }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar tile size="80"
                  ><img src="@/assets/videos.png" width="100px"
                /></v-list-item-avatar>
              </v-list-item> </v-card-text
          ></v-card>
          <v-card
            style="margin-top: 20px;margin-inline: 10px;"
            @click="goToVideos($store.state.weekpath, 'internet')"
            flat
            outlined
            ><v-card-text
              ><v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">{{$t("Videos")}}</div>
                  <v-list-item-title class="headline mb-1">
                    {{$t("Related videos")}}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{$t("Educational Videos")}}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-avatar tile size="80"
                  ><img src="@/assets/videos2.png" width="100px"
                /></v-list-item-avatar>
              </v-list-item> </v-card-text
          ></v-card>
          <v-card
            style="margin-top: 20px;margin-inline: 10px;"
            @click="goToShows($store.state.weekpath)"
            flat
            outlined
            ><v-card-text
              ><v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">{{$t("Files")}}</div>
                  <v-list-item-title class="headline mb-1">
                    {{$t("Lesson Documents")}}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{$t("Presentation Shows")}}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-avatar tile size="80"
                  ><img src="@/assets/powerpoint.png" width="100px"
                /></v-list-item-avatar>
              </v-list-item> </v-card-text
          ></v-card>
        </span>

        <span v-else>
          <v-card color="#1d2430" style="margin-left:20px;">
            <v-card-title
              style="color: #ffffff; cursor: pointer"
              @click="goToLesson($store.state.weekpath)"
            >
              <v-icon color="green" size="30">mdi-play</v-icon>{{$t("Start Flipped Classroom")}}
            </v-card-title>

            <v-card-text>
              <LessonList :path="$store.state.weekpath"></LessonList>
            </v-card-text>
          </v-card>
        </span>
      </v-col>
      <v-col cols="12" sm="4" xs="4" :style="$i18n.locale=='Arabic'? {'margin-right': '-10px'} : ''">
        <div @click="goToReport()">
          <activityWidget
            style="cursor: pointer"
            :path="this.$store.state.weekpath"
          />
        </div>

        <v-card
          dark
          color="green"
          @click="goToFlipped"
          style="margin-top:20px"
          v-if="false"
        >
          <v-card-title style="background-color:#1d2430;"
            ><img
              src="@/assets/flipped.png"
              width="50px"
              style="margin-right:10px"
            />
            {{ $t("Flipped Classroom") }}
          </v-card-title>

          <v-card-text></v-card-text>
        </v-card>
        <v-card
          dark
          color="red"
          style="margin-top:20px"
          @click="goToExit"
          v-if="false"
        >
          <v-card-title style="background-color:#1d2430;"
            ><img
              src="@/assets/exit.png"
              width="50px"
              style="margin-right:10px"
            />
            Exit Ticket
          </v-card-title>
          <v-card-text></v-card-text>
        </v-card>

        <v-card dark color="blue" @click="goToInClass" style="margin-top:20px">
          <v-card-title style="background-color:#1d2430;"
            ><img
              src="@/assets/gradebook.png"
              width="50px"
              style="margin-right:10px"
            />
            {{$t("Guided Practice")}}
          </v-card-title>

          <v-card-text></v-card-text>
        </v-card>

        <v-card dark color="#1e1e1e" style="margin-top:20px">
          <v-card-title style="background-color: #1d2430"
            >{{ $t("Lesson Assignments") }}
          </v-card-title>
          <v-card-subtitle style="background-color: #1d2430"
            >{{$t("Assignments")}}</v-card-subtitle
          >

          <v-card-text style="text-align: center">
            <div v-if="assignments.length == 0">
              <img src="@/assets/nodata.png" width="50%" />
              <br />
              <small>No Assignments In This Lesson</small>
            </div>

            <v-list dense dark v-else>
              <v-list-item-group color="primary">
                <span v-for="(item, i) in assignments" :key="i">
                  <v-list-item @click="goToAssignIntro(item.key, item.path)">
                    <h6 style="width:20px">{{ i + 1 }}</h6>
                    <img
                      src="@/assets/quiz.png"
                      width="50px"
                      style=" margin-left:20px"
                      v-if="item.type == 'Quiz Assignment'"
                    />

                    <img
                      src="@/assets/textbook.png"
                      width="50px"
                      style=" margin-left:20px"
                      v-else
                    />

                    <v-list-item-content
                      style="text-align:left; margin-left:20px"
                    >
                      <v-list-item-title class="fooar">{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle
                        style="color: #eb3223"
                        v-if="item.type == 'Quiz Assignment'"
                        >{{ item.type }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="color: #53a451" v-else>{{
                        item.type
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </span>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- Upcoming Live Sessions -->
        <v-card
          flat
          dark
          style="cursor: pointer; text-align:center; margin-top:20px; background-color:#1e1e1e;"
        >
          <v-card-title style="background-color: #1d2430"
            >{{$t("Upcoming Live Sessions")}}</v-card-title
          >
          <v-card-text>
            <div v-if="sessions.length == 0">
              <img src="@/assets/nodata.png" width="80px" />
              <br />
              <small>{{$t("No Upcoming Live Sessions")}}</small>
            </div>

            <v-list dense dark v-else>
              <v-list-item-group color="primary">
                <span v-for="(item, i) in sessions" :key="i">
                  <v-list-item
                    @click="
                      goToSession(
                        item.title,
                        item.date,
                        item.time,
                        item.link,
                        item.prerecorded
                      )
                    "
                  >
                    <img
                      src="@/assets/live2.png"
                      width="50px"
                      style=" margin-left:20px"
                    />
                    <v-list-item-content
                      style="text-align:left; margin-left:20px"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle
                        style="color: #53a451"
                        v-if="item.date"
                        >{{ item.date }} - {{ item.time }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="color: #53a451" v-else
                        >{{$t("Available Now")}}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </span>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- End Upcoming Live Sessions -->

        <v-card
          dark
          color="info"
          style="margin-top:20px"
          v-if="this.$store.state.practice"
          @click="goToTest($store.state.weekpath)"
        >
          <v-card-title style="background-color:#1d2430;"
            ><img
              src="@/assets/practice.png"
              width="50px"
              style="margin-right:10px"
            />
            {{$t("Lesson Practice")}}  
          </v-card-title>
          <v-card-text>
            <button v-if="practice==true" @click="goToPracticeReport()">Go to report</button>
          </v-card-text>
        </v-card>

        <br />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import LessonList from "../weeks/LessonList";
import activityWidget from "@/components/reports/activityWidget";
// import  moment  from "moment";
export default {
  name: "WeekPage",
  components: {
    LessonList,
    activityWidget
  },
  data() {
    return {
      assignments: {},
      practice:false,
      lesson: {},
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      sessions: [],
      unique_random_numbers: [],
      view: "",
      objectives: "",
      attempts:0,
      lessonview: this.$store.state.lessonview,
      sessionsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/sessions")
    };
  },
  methods: {
    goToLesson(path) {
      console.log(this.$store.state.weekid);
      console.log(path);
      this.$store.state.rightAnswers = 0;
      this.$store.state.missedAnswers = 0;
      this.$store.state.lessonrightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.lessonwrongAnswers = 0;
      this.$store.state.current = 0;

      this.$store.state.flippedrightAnswers = 0;
      this.$store.state.exitrightAnswers = 0;

   firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped")
              .doc(this.$store.state.weekid).get().then((doc) => {  
                console.log(doc.data())
                doc.data()?.attempts == undefined ? this.attempts = 0 : this.attempts = doc.data().attempts 
                  console.log(this.attempts);       
                  if(this.attempts == 4){
                    router.push({
                name: "LessonPage",
                params: { path: path,attempt: 4}
              });
                  } else{
                    router.push({
                name: "LessonPage",
                params: { path: path,attempt: this.attempts + 1}
              });
                  } 
  
              });
    
    },
    goToVideos(path, source) {
      this.$store.commit("videotype", source);

      router.push({
        name: "LessonVideos",
        params: { path: path, source: source }
      });
    },
    goToShows(path, source) {
      router.push({
        name: "LessonShows",
        params: { path: path, source: source }
      });
    },
    goToTest(path) {
      this.$store.state.rightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.missedAnswers = 0;
      this.$store.state.current = 0;
      router.push({
        name: "TestPage",
        params: { path: path }
      });
    },
    goToFlipped() {
      router.push({
        name: "FlippedIntro"
      });
    },
    goToInClass() {
      router.push({
        name: "InclassIntro"
      });
    },
    goToExit() {
      router.push({
        name: "ExitIntro"
      });
    },
    goToAssignIntro(assid, asspath) {
      this.$store.commit("assid", assid);
      this.$store.commit("asspath", asspath);

      router.push({
        name: "AssignmentIntro"
        //name: "Assignment"
      });
    },

    goToReport() {
      router.push({
        name: "activityReport",
        params: {
          path: this.$store.state.weekpath,
          title: this.$store.state.weektitle
        }
      });
    },
    goToPracticeReport() {
      router.push({
                    name: "PracticeReport"
                  });
    },

    goToSession(title, date, time, link, recorded) {
      this.$store.commit("livetitle", title);
      this.$store.commit("livedate", date);
      this.$store.commit("livetime", time);
      this.$store.commit("livelink", link);
      this.$store.commit("liverecorded", recorded);

      router.push({
        name: "LiveSession"
      });
    }
  },

  created() {

    this.sessionsRef
      .where("sessionclass", "==", this.$store.state.sclass)
      .where("week", "==", this.$store.state.weekpath)
      .get()
      .then(querySnapshot => {
        this.sessions = [];
        querySnapshot.forEach(doc => {
          this.sessions.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            date: doc.data().date,
            time: doc.data().time,
            link: doc.data().link,
            prerecorded: doc.data().prerecorded
          });
        });
      });

    firebase
      .firestore()
      .collection(this.$store.state.subject + "/tasks")
      .where("week", "==", this.$store.state.weekpath)
      .get()
      .then(querySnapshot => {
        this.assignments = [];
        querySnapshot.forEach(doc => {
          const ass = doc.data();
          console.log(ass)
          this.assignments.push({
            key: doc.id,
            path: doc.ref.path,
            title: ass.title,
            type: ass.type
            // grade: ass.grade,
            // weight: ass.weight,
            // weightcode: ass.weightcode,
            // opendate: ass.opendate,
            // closedate: ass.closedate,
            // description: doc.data().description,
            // quiztype: ass.quiztype,
            // count: ass.count,
            // week: ass.week,
            // note: ass.note,
            // file: ass.file,
            // attempts: ass.attempts
          });
        });
      });
      firebase
      .firestore()
      .collection(this.$store.state.studentpath +
              "/WeekPractice/" +
              this.$store.state.weekid +
              "/answers")
      .get()
      .then(querySnapshot => {
        if(!querySnapshot.empty){
          this.practice = true
        }
      });
    firebase
      .firestore()
      .doc(this.$store.state.weekpath)
      .get()
      .then(doc => {
        this.lesson = doc.data();
        //! Get Objective
        firebase
          .firestore()
          .doc(this.$store.state.weekpath + "/details/1")
          .get()
          .then(doc => {
            if (doc.exists) {
              this.objectives = doc.data().objectives;
            }
          });
      });
  },
  mounted() {
    if (this.lessonview == "Lesson Page") this.view = "sections";
  }
};
</script>
<style scoped>
.fooar >>> img {
  width: 400px;
}
.fooar {
  text-align: right;
  direction: rtl;
  width: 100%;
}
.foo >>> img {
  width: 400px;
}
</style>

<template>
  <div>
   
    <v-navigation-drawer
      src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/subjectheader.jpg?alt=media&token=f8356179-ca22-4d4b-9266-9ce302e848ab"
      style="z-index:2;position:absolute;"
      height="100%"
      width="100%"
      permanent
    >
    

      <div style="padding-left:30px; padding-top:50px; color:#fff;">
        <v-row align="center">
          <v-col cols="2" lg="1" style="margin-inline: 20px;">
            <img src="@/assets/cer-w.png" width="100px" />
          </v-col>
          <v-col cols="3" style="margin-inline: 10px;">
            <h3>
              {{$t("Hello")}}
              {{ this.$store.state.firstname }}
            </h3>
            {{ grade + " Class " + $store.state.sclass }}

            <div></div>
          </v-col>
          <!-- <v-spacer />
          <SwitchLocale /> -->
        </v-row>
      </div>
    </v-navigation-drawer>

    <v-row
      style="position:absolute; z-index:200; margin-top:200px;margin-right: 20px;  margin-left:20px; width:80%;"
    >
      <v-col cols="12" sm="4" xs="4">
        <v-card flat @click="goToCalendar">
          <v-card-text>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-avatar tile size="50">
                    <img src="@/assets/goals.png"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("calendar") }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      ><h6>
                        <span style="color:#3096b2;">
                          {{ $t("yourweek") }}</span
                        >
                      </h6>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
        <br />

        <v-card
          flat
          :href="this.gclass.timetable"
          target="_blank"
          rel="noopener"
        >
          <v-card-text>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-avatar tile size="50">
                    <img src="@/assets/timetable.png"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("schedule") }}</v-list-item-title>
                    <v-list-item-subtitle
                      ><h6>
                        <span style="color:#3096b2;">{{
                          $t("timetable")
                        }}</span>
                      </h6>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <br />

        <v-card
          flat
          style="cursor: pointer; text-align:center;"
          @click="goLeaderboard()"
        >
          <v-card-text>
            <img src="@/assets/leaderboard.png" height="100px" />

            <h6 style="padding-top:20px">
              <span style="color:#3096b2;">{{ $t("leaderboard") }} </span>
            </h6>
          </v-card-text>
        </v-card>

        <br />
      </v-col>
      <v-col cols="12" sm="8" xs="8">
        <v-card flat height="800px" width="100%" v-if="boards.length > 0">
          <v-card-text>
            <v-container v-if="$store.state.admin == true">
              <v-row>
                <v-col class="d-flex" cols="6" sm="6">
                  <v-select
                    :items="schools"
                    v-model="selectedschool"
                    label="School"
                    @change="onSchoolChange()"
                  ></v-select>
                </v-col>
                <!-- Grade Level -->

                <v-col class="d-flex" cols="6" sm="6">
                  <v-select
                    :items="level"
                    v-model="selectedgrade"
                    label="Grade Level"
                    @change="onGradeChange()"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>

            <v-list dense style="max-height: 700px;  ">
              <v-list-item-group color="primary">
                <div style="overflow: auto; height: 700px;">
                  <v-list-item
                    three-line
                    v-for="subject in boards"
                    :key="subject.id"
                    dark
                    @click="
                      goToWeeks(subject.path, subject.title, subject.mirror)
                    "
                  >
                    <v-card
                      width="100%"
                      style="width='500px'; margin-bottom:50px; background-color:#262626; margin-right:20px;"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="3">
                            <img
                              v-if="subject.icon"
                              :src="
                                require(`@/assets/subjects/${subject.icon}`)
                              "
                              width="80px"
                            />
                            <img
                              v-else
                              :src="require('@/assets/subjects/english.png')"
                              width="80px"
                            />
                          </v-col>
                          <v-col class="fooar" >
                            <h5  >
                              {{ $t(subject.title) }}
                            </h5 >
                            {{ $t(grade) }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-list-item>
                </div>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <v-sheet v-else color="darken-2" class="pa-3" height="800px">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
// import SwitchLocale from "@/components/dna/SwitchLocale.vue";

export default {
  name: "Home-page",
  // components: { SwitchLocale },

  data() {
    return {
      admission: [],
      schools: [],
      level: [],
      students: [],
      employees: [],
      fleet: [],
      errors: [],
      boards: [],
      gclass: [],
      //test: this.$store.state.string,
      logo: localStorage.getItem("logo"),
      icon: "",
      domain: "",
      timestamp: "",
      grade: this.$store.state.grade,

      selectedschool: "",
      selectedgrade: "",

      langs: ["en", "be", "da", "hr"],
      ref: firebase
        .firestore()
        .collection(this.$store.state.educationPath + "/subjects"),
      // .where("hide", "==", false),
      Classref: firebase
        .firestore()
        .doc(
          this.$store.state.educationPath +
            "/classes/" +
            this.$store.state.sclass
        )
    };
  },
  created() {
    // setInterval(this.getNow, 1000);

    this.domain = window.location.host;
    this.ref.get().then(querySnapshot => {
      this.boards = [];
      querySnapshot.forEach(doc => {
        this.boards.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          mirror: doc.data().mirror,
          icon:
            doc.data().icon == "Statistics.png"
              ? "english.png"
              : doc.data().icon
        });
      });
    });

    this.Classref.get().then(snapshot => {
      if (!snapshot.exists) return;
      this.gclass = snapshot.data();
    });

    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/branches/")
      .where("active", "==", true)
      .get()
      .then(querySnapshot => {
        this.schools = [];
        querySnapshot.forEach(doc => {
          this.schools.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });
  },
  methods: {
    goToWeeks(id, title, mirror) {

      this.$store.commit("mirror", mirror);
      if (mirror) {
        this.$store.commit("subject", mirror);
      } else {
        this.$store.commit("subject", id);
      }

      this.$store.commit("subjecttitle", title);
      this.$store.commit("oraginalsubject", id);
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.$store.state.branch
        )
        .get()
        .then(querySnapshot => {
          this.$store.commit("breakdown", querySnapshot?.data()?.breakdown);
          router.push({
        name: "SubjectDash",
        params: { path: id, title: title, mirror: mirror }
      });
        });

    },
    goToCalendar() {
      router.push({ name: "Calendar" });
    },
    goToWeekly() {
      router.push({ name: "Weekly" });
    },

    goLeaderboard() {
      router.push({ name: "Leaderboard" });
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },

    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.selectedschool +
            "/grades/"
        )
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    onGradeChange() {
      // this.$store.commit("educationPath", user.data().educationPath);
      this.$store.commit("branch", this.selectedschool);
      this.$store.commit("grade", this.selectedgrade);
      this.$store.commit(
        "educationPath",
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.selectedschool +
          "/grades/" +
          this.selectedgrade
      );

      this.$store.commit("sclass", "Test");
      location.reload();

      // firebase
      //   .firestore()
      //   .collection("schools/" + this.$store.state.school + "/students")
      //   .where("branch", "==", this.student.school)
      //   .where("grade", "==", this.student.grade)
      //   .onSnapshot(querySnapshot => {
      //     this.studentInfo = [];
      //     querySnapshot.forEach(doc => {
      //       this.studentInfo.push({
      //         key: doc.id,
      //         path: doc.ref.path,
      //         grade: doc.data().grade,
      //         branch: doc.data().branch,
      //         class: doc.data().class,
      //         firstname: doc.data().firstname,
      //         middlename: doc.data().middlename,
      //         lastname: doc.data().lastname,
      //         username: doc.data().username,
      //         password: doc.data().password
      //       });
      //     });
      //   });
    }
  },
  mounted() {
    // if (localStorage.getItem("reloaded") == "false") {
    //   localStorage.setItem("reloaded", "true");
    //   console.log("reload");
    //   location.reload();
    // }
  }
};
</script>
<style scoped>


.body {
font-family: 'arabic';
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(24, 146, 0, 0.904);
  box-shadow: 0 0 1px rgba(24, 146, 0, 0.904);
}
</style>

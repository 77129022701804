import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueRouter from "vue-router";
import router from "./router";
import "./plugins/element.js";
import {
    BootstrapVue,
    BootstrapVueIcons
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueConfirmDialog from "vue-confirm-dialog";
import VueGoodTablePlugin from "vue-good-table";
// import the styles
import "vue-good-table/dist/vue-good-table.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueEllipseProgress from "vue-ellipse-progress";
import VCalendar from "v-calendar";

import ImageUploader from "vue-image-upload-resize";
import VueTimeline from "@growthbunker/vuetimeline";

import {
    ValidationProvider
} from "vee-validate";
import {
    firestorePlugin
} from "vuefire";

import JsonExcel from "vue-json-excel";

import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import "./assets/css/main.css";

import CKEditor from "@ckeditor/ckeditor5-vue";
import VueQuillEditor from 'vue-quill-editor'
import Embed from 'v-video-embed';
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import VueApexCharts from 'vue-apexcharts';
import VueHtmlToPaper from "vue-html-to-paper";
import fullscreen from "vue-fullscreen";
// import VueKatex from 'vue-katex';
// import 'katex/dist/katex.min.css';
import vuescroll from "vuescroll";
import VueRecord from '@codekraft-studio/vue-record';
import AudioRecorder from 'vue-audio-recorder';
import VueSpeech from '@codekraft-studio/vue-speech';
import stringSimilarity from 'string-similarity';


import axios from 'axios';
import VueI18n from 'vue-i18n';
import English from './lang/en'
import Arabic from './lang/ar'


Vue.use(axios);
Vue.use(VueI18n);


const messages = {
    English,
    Arabic
};

const i18n = new VueI18n({
    locale: 'English',
    messages
});

Vue.config.productionTip = false

Vue.component('vueInternetChecker', require('vue-internet-checker'));


Vue.use(VueSpeech);
Vue.use(stringSimilarity);

Vue.use(VueRecord);
Vue.use(AudioRecorder);

// Vue.use(VueKatex, {
//     globalOptions: {
//         //... Define globally applied KaTeX options here
//     }
// });


const options = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "https://unpkg.com/kidlat-css/css/kidlat.css"
    ]
};






Vue.use(vuescroll);

Vue.use(fullscreen);
Vue.use(VueSweetalert2);
Vue.use(VueHtmlToPaper, options);


Vue.use(VueQuillEditor, /* { default global options } */ );
Vue.use(firestorePlugin);
Vue.use(CKEditor);
Vue.use(VCalendar);
Vue.use(VueTimeline);
Vue.use(Embed);
Vue.use(VueApexCharts);
Vue.component('apexchart-chart', VueApexCharts);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(moment);
Vue.use(require("moment"));
Vue.use(ImageUploader);
Vue.use(VueEllipseProgress);

Vue.use(Datepicker);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueGoodTablePlugin);
Vue.component("downloadExcel", JsonExcel);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount("#app");
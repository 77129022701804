<template>
  <div>
    <v-row @click="gotoDetails" style="cursor: pointer;">
      <v-col cols="3" :style="'background-color:' + this.color">
        <v-row>
          <v-card
            :min-height="this.weight * 10"
            width="200px"
            dark
            tile
            flat
            :style="'background-color:' + this.color"
          >
            <v-card-title>{{ this.weight }}%</v-card-title>
            <v-card-subtitle style="color:#ffffff">
              {{ this.title }}
            </v-card-subtitle></v-card
          >
        </v-row>
      </v-col>
      <v-col cols="7" :style="'background-color:' + this.fadecolor">
        {{ this.attendance.length }} Total Days in Q4
        <br />
        {{ this.present.length }} Present Days
        <br />
        {{ this.excuse.length }} Excused Days

        <br />
        {{ this.absence.length }} Absent Days
      </v-col>
      <v-col cols="2" :style="' text-align:center; align-self: center;'">
        {{ this.title }}<br />
        <h3>
          {{
            Math.round(
              this.attendance.length == 0
                ? "0"
                : ((this.attendance.length - this.absence.length) /
                    this.attendance.length) *
                    100
            )
          }}
          %
        </h3>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name:"att-page",
  components: {},
  props: ["title", "weight", "subject", "color", "fadecolor"],

  data() {
    return {
      attendance: [],

      absence: [],
      present: [],
      excuse: [],

      subjectpath: this.$store.state.subject,
      subjecttitle: this.$store.state.subjecttitle,
      studentpath: this.$store.state.studentpath
    };
  },

  created() {
    this.attendance = [];
    this.absence = [];
    this.present = [];
    this.excuse = [];

    //! Get Student Attentance
    firebase
      .firestore()
      .collection(this.studentpath + "/attendance")
      .where("subject", "==", this.subjecttitle)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach(doc => {
            //! All Days
            this.attendance.push({
              key: doc.id,
              date: doc.data().date,
              type: doc.data().type
            });

            if (doc.data().type == "absence") {
              this.absence.push({
                key: doc.id,
                date: doc.data().date,
                type: doc.data().type
              });
            } else if (doc.data().type == "present") {
              this.present.push({
                key: doc.id,
                date: doc.data().date,
                type: doc.data().type
              });
            } else if (doc.data().type == "excuse") {
              this.excuse.push({
                key: doc.id,
                date: doc.data().date,
                type: doc.data().type
              });
            }
          });

          //! Flag As completed
          this.$store.commit("weightcount", this.$store.state.weightcount + 1);

          this.$store.commit("gradebook", {
            title: "Attendance",
            grade:
              ((this.attendance.length - this.absence.length) /
                this.attendance.length) *
              100,
            weight: this.weight
          });
        } else {
          //! Flag As completed
          this.$store.commit("weightcount", this.$store.state.weightcount + 1);

          this.$store.commit("gradebook", {
            title: "Attendance",
            grade: 0,
            weight: this.weight
          });
        }
      });
  },
  methods: {
    gotoDetails() {
      this.$store.commit("weighttitle", this.title);
      this.$store.commit("weightpre", this.weight);

      router.push({
        name: "AttendanceDetails"
      });
    }
  }
};
</script>

import { render, staticRenderFns } from "./SonDash.vue?vue&type=template&id=63e6cf68&scoped=true&"
import script from "./SonDash.vue?vue&type=script&lang=js&"
export * from "./SonDash.vue?vue&type=script&lang=js&"
import style0 from "./SonDash.vue?vue&type=style&index=0&id=63e6cf68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e6cf68",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('fullscreen',{ref:"fullscreen",staticStyle:{"background-color":"#fff"},on:{"change":_vm.fullscreenChange}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(this.$route.params.title))]),(_vm.fullscreen != false)?_c('v-card-subtitle',{staticStyle:{"color":"red"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Any attempt to exit Fullscreen Mode will End the Exam")],1):_vm._e(),_c('v-card-text',[_c('div',[_c('a',{attrs:{"href":this.exam.live,"target":"_blank"}},[_vm._v("Join Live Monitoring Session")]),(this.fullscreen)?_c('div',[_c('ExamPaper',{attrs:{"path":this.$route.params.path}})],1):_vm._e()])]),_c('v-card-actions',[(
                _vm.checkTime(
                  _vm.exam.startTime,
                  _vm.exam.endTime,
                  _vm.exam.opendate,
                  _vm.exam.disable
                )
              )?_c('div',[_vm._v(" Please Click Enter Exam To Start "),_c('v-divider'),_c('v-btn',{attrs:{"type":"button","color":"info","small":"","depressed":""},on:{"click":_vm.toggle}},[_vm._v("Enter Exam")])],1):_c('div',[_c('h4',{staticStyle:{"padding-top":"20px"}},[_vm._v("Exam Not Opened Yet")])])])],1)],1),_c('br')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
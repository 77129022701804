<template>
  <v-app id="inspire">
    <vue-confirm-dialog></vue-confirm-dialog>

    <!-- Sidebar Begin -->
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      permanent
      color="#12101d"
      data-color="#ffffff"
      style="z-index:300;direction: ltr;"
      width="50px"
    >
      <v-list nav dark >
        <v-list-item @click="goToPage('Home')" style="margin-top:70px">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home Page</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToPage('Room')">
          <v-list-item-icon>
            <v-icon>mdi-bell-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Messaging</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToPage('Leaderboard')">
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Leaderboard</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToPage('progressReport')">
          <v-list-item-icon>
            <v-icon>mdi-finance</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Progress</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-text-search</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Setting</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <!-- <v-list-item-icon>
            <v-icon>mdi-translate</v-icon>
          </v-list-item-icon> -->
          <SwitchLocale />
          <v-list-item-title>translate</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list nav dense dark style="background-color:#1e1b30">
          <v-list-item @click="signOut()" dark>
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <!-- End Sidebar -->

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import router from "@/router";
import firebase from "@/Firebase";
import SwitchLocale from "./components/dna/SwitchLocale.vue";

export default {
  name: "App",
  components: { SwitchLocale },
  props: {
    source: String
  },

  data: () => ({
    dialog: false,
    drawer: null,
    domain: "",
    onLine: null,
    onlineSlot: "online",
    offlineSlot: "offline"
  }),
  methods: {
    goToPage(page) {
      router.push({ name: page });
    },
    signOut() {
      localStorage.setItem("loggedIn", false);
      this.$store.state.loggedIn = false;
      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.href = "/school/" + this.$store.state.school;
        });
    }
  },
  created() {
    this.domain = window.location.host;
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Lalezar"); */
.v-list-item__icon {
    align-self: flex-start;
    margin: 10px 0;
}
#inspire {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  background-color: #eff0f5;
}

.card-text {
  padding: 20px;
}
.offline {
  color: red;
  font-size: small;
}
.online {
  visibility: hidden;
}
</style>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/calendar.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">Weekly Report</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-sheet class="mx-auto">
        <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
          <v-slide-item
            v-for="subject in subjects"
            :key="subject.key"
            v-slot="{ active, toggle }"
          >
            <v-card
              :color="active ? 'primary' : '#333333'"
              class="ma-4"
              height="100"
              width="200"
              @click="toggle"
              flat
            >
              <v-card-text @click="getSubject(subject.path, subject.mirror)"
                ><h6 style="color:#ffffff;">
                  {{ subject.title }}
                </h6></v-card-text
              >
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <br />
      <v-card dark flat style="background-color:#002147"
        ><v-card-text>
          <v-row>
            <v-col cols="3">
              <v-chip color="red" label style="margin-right:10px" />Overdue
              Assignments: {{ this.overdue }}</v-col
            >
            <v-col cols="3">
              <v-chip color="green" label style="margin-right:10px" />Upcoming
              Assignments: {{ this.upcoming }}</v-col
            >
          </v-row>
        </v-card-text></v-card
      >
      <br />
      <v-card flat
        ><v-card-text>
          <v-card
            v-for="event in events"
            :key="event.key"
            class="ma-4"
            flat
            outlined
          >
            <v-card-text
              ><v-row
                ><v-col cols="1"><v-chip :color="event.color" label /> </v-col
                ><v-col cols="11">
                  <h6>{{ event.title }}</h6></v-col
                ></v-row
              ></v-card-text
            >

            <v-card-actions>{{
              event.start + "-------" + event.end
            }}</v-card-actions>
          </v-card>
        </v-card-text></v-card
      >
    </v-col></v-row
  >
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Weekly-page",
  data() {
    return {
      grade: this.$store.state.grade,
      overdue: 0,
      upcoming: 0,

      loading: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      focus: "",
      subjects: [],
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1"
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.$store.state.educationPath + "/subjects/")
      .get()
      .then(querySnapshot => {
        this.subjects = [];
        querySnapshot.forEach(subject => {
          this.subjects.push({
            key: subject.id,
            path: subject.ref.path,
            title: subject.data().title,
            mirror: subject.data().mirror
          });
        });
      });
  },
  methods: {
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },

    showEvent({ nativeEvent, event }) {
      console.log(nativeEvent, event);
    },

    getSubject(path, mirror) {
      this.loading = false;
      this.overdue = 0;
      this.upcoming = 0;
      this.events = [];
      var subject = "";
      if (mirror != null || mirror != "") {
        subject = mirror;
      } else {
        subject = path;
      }

      //! Get Lessons
      firebase
        .firestore()
        .collection(subject + "/weeks/")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().date != null && doc.data().date != "")
            //   this.events.push({
            //     title: doc.data().title,
            //     start: doc.data().date,
            //     end: doc.data().date,
            //     color: "blue",
            //     timed: false
            //   });

            //! Get Lesssons assignments
            firebase
              .firestore()
              .collection(doc.ref.path + "/assignments/")
              .orderBy("closedate")
              .get()
              .then(querySnapshot => {
                var fromdate = new Date();
                fromdate.setDate(fromdate.getDate() - 7);

                var todate = new Date();
                todate.setDate(todate.getDate() + 2);

                querySnapshot.forEach(doc => {
                  if (
                    fromdate < Date.parse(doc.data().closedate) &&
                    todate >= Date.parse(doc.data().closedate)
                  ) {
                    if (Date.now() <= Date.parse(doc.data().closedate)) {
                      this.events.push({
                        key: doc.ref.id,
                        title: doc.data().title,
                        start: doc.data().opendate,
                        end: doc.data().closedate,
                        color: "green",
                        timed: false
                      });
                      this.upcoming++;
                    } else {
                      this.events.push({
                        key: doc.ref.id,
                        title: doc.data().title,
                        start: doc.data().opendate,
                        end: doc.data().closedate,
                        color: "red",
                        timed: false
                      });
                      this.overdue++;
                    }
                  }
                });
              })
              .then(() => {
                this.loading = true;
              });
          });
        });
    }
  }
};
</script>

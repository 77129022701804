<template>
  <div>
    <!--    <p v-for="q in examquestions" :key="q.key">{{ q.path }}</p> -->
    <v-card flat height="1000px">
      <v-card-title>
        <v-spacer></v-spacer>
        <small> {{ this.current + 1 }} / {{ this.examquestions.length }}</small>
        <v-btn
          color="primary"
          style="margin-left: 20px"
          rounded
          @click="next()"
          small
          v-if="this.current < this.examquestions.length - 1"
        >
          <v-icon>mdi-chevron-right</v-icon> {{$t("Next Question")}}
        </v-btn>
        <v-btn
          color="orange"
          style="margin-left: 20px"
          rounded
          @click="finish()"
          small
          v-else
        >
          <v-icon>mdi-chevron-right</v-icon> Finish Exam
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div style="width: 100%; height: 600px">
          <Question
            :path="examquestions[this.current].path"
            source="Exam"
            :key="current"
          ></Question>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import Question from "./Question";

export default {
  props: ["path"],
  components: {
    Question
  },
  data() {
    return {
      subject: this.$store.state.subject,
      current: 0,
      count: 5,

      exam: {},
      qmatrix: [],
      examquestions: [],
      lessonquestions: [],
      record: []
    };
  },
  created() {
    this.$store.state.rightAnswers = 0;
    this.$store.state.wrongAnswers = 0;
    var i = 0;
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;

        this.exam = snapshot.data();
        this.qmatrix = snapshot.data().qmatrix;
        // console.log(this.qmatrix);
        // this.qmatrix.forEach(lesson => console.log(lesson));
        this.examquestions = [];
        for (const [lesson, questions] of Object.entries(this.qmatrix)) {
          // console.log(`${lesson}: ${questions}`);

          //! Lesson Questions
          firebase
            .firestore()
            .collection(this.subject + "/weeks/" + lesson + "/questions")
            .get()
            .then(querySnapshot => {
              this.lessonquestions = [];
              querySnapshot.forEach(doc => {
                this.lessonquestions.push({
                  key: doc.id,
                  path: doc.ref.path,
                  title: doc.data().title,
                  type: doc.data().qtype,
                  answer: doc.data().answer,
                  bloom: doc.data().bloom,
                  standard: doc.data().standard,
                  difficulty: doc.data().difficulty
                });
              });

              i = 1;
              // console.log(this.lessonquestions);
              while (i <= questions) {
                // console.log(
                //   this.lessonquestions[
                //     Math.floor(Math.random() * this.lessonquestions.length)
                //   ].path
                // );
                this.examquestions.push(
                  this.lessonquestions[
                    Math.floor(Math.random() * this.lessonquestions.length)
                  ]
                );
                i++;
              }
            });
        }
      });
  },

  methods: {
    finish() {
      this.record.date = new Date().toLocaleString();
      this.record.right = this.$store.state.rightAnswers;
      this.record.wrong = this.$store.state.wrongAnswers;
      this.record.skipped =
        this.count -
        (this.$store.state.wrongAnswers + this.$store.state.rightAnswers);

      this.record.subject = this.$store.state.subject;
      this.record.subject_title = this.$store.state.subjecttitle;
      this.record.type = "Exam";
      this.record.weektitle = this.$store.state.weektitle;
      this.record.weekpath = this.$store.state.weekpath;
      this.record.totalQuestions = this.count;

      router.push({ name: "Home" });

      // firebase
      //   .firestore()
      //   .collection(
      //     "schools/" +
      //       this.$store.state.school +
      //       "/students/" +
      //       this.$store.state.id +
      //       "/activities"
      //   )
      //   .add(this.record)
      //   .then(() => {
      //     this.$store.state.rightAnswers = 0;
      //     this.$store.state.wrongAnswers = 0;
      //     this.$store.state.lessonrightAnswers = 0;
      //     this.$store.state.lessonwrongAnswers = 0;

      //     router.push({ name: "Home" });
      //   })
      //   .catch(error => {
      //     alert("Error adding document: ", error);
      //   });
    },
    next() {
      if (this.current < this.examquestions.length - 1) {
        this.current++;
      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    }
  }
};
</script>

<template>
  <div>
    <v-card flat outlined style="margin-bottom:10px" hover>
      <v-card-subtitle>
        <span v-show="this.master" style="color:green">Master Teacher</span>
      </v-card-subtitle>
      <v-card-text>
        <v-row
          ><v-col cols="1">
            <v-avatar
              size="54px"
              color="#121212"
              @click="goToPage('EditProfile')"
            >
              <img
                src="@/assets/avatar.png"
                width="100px"
                v-if="employee.image == null"
              />
              <img :src="employee.image" width="100px" v-else />
            </v-avatar>
          </v-col>
          <v-col cols="11">
            <b style="font-size:16px">
              {{ this.employee.firstname + " " + this.employee.lastname }}</b
            >
            <br />
            {{ this.employee.job }}
            <br />

            <v-btn
              small
              depressed
              color="blue"
              @click="startChat(employeeid)"
              style=" color:#ffffff;"
            >
              <v-icon style="margin-right:20px;">mdi-forum-outline</v-icon
              >&nbsp; Start Chat
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-divider />
        <v-avatar
          v-for="c in classes"
          :key="c"
          color="red"
          size="24"
          style="margin-right:2px"
        >
          <span class="white--text ">{{ c }}</span>
        </v-avatar>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "Teacher-page",

  data() {
    return {
      employee: {},
      selectedStudent: {},
      employeeid: ""
    };
  },
  props: ["path", "master", "classes", "id"],
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(emp => {
        this.employeeid = emp.id;
        this.employee = emp.data();
      });
  },
  methods: {
    startChat(teacher) {
      this.selectedStudent.owner = this.$store.state.id;
      this.selectedStudent.receiver = teacher;

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/chats")
        .doc(teacher + this.$store.state.id)
        .set(this.selectedStudent)
        .then(() => {
          //! Add to Student Chat Collection
          firebase
            .firestore()
            .collection(
              "/schools/" +
                this.$store.state.school +
                "/StudentsData/" +
                this.$store.state.id +
                "/chats"
            )
            .doc(teacher + this.$store.state.id)
            .set({
              chatpath:
                "schools/" +
                this.$store.state.school +
                "/chats/" +
                teacher +
                this.$store.state.id,
              receiver: teacher,
              owner: this.$store.state.id
            })
            .then(() => {});

          //! Add to Employee Chat Collection
          firebase
            .firestore()
            .collection(
              "/schools/" +
                this.$store.state.school +
                "/employees/" +
                teacher +
                "/chats"
            )
            .doc(teacher + this.$store.state.id)
            .set({
              chatpath:
                "schools/" +
                this.$store.state.school +
                "/chats/" +
                teacher +
                this.$store.state.id,
              receiver: teacher,
              owner: this.$store.state.id
            })
            .then(() => {});
          this.dialog = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

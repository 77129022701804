<template>
  <div>
    <!-- style="cursor: pointer;" @click="goToAssignIntro" -->
    <v-row style="cursor: pointer;" @click="goToAssignIntro()">
      <v-col cols="3" :style="'background-color:' + this.color">
        <v-row>
          <v-card
            :min-height="this.weight * 10"
            width="200px"
            dark
            tile
            flat
            :style="'background-color:' + this.color"
          >
            <v-card-title>{{ this.weight }}%</v-card-title>
            <v-card-subtitle style="color:#ffffff">
              {{ this.title }}
            </v-card-subtitle></v-card
          >
        </v-row>
      </v-col>
      <v-col cols="7" :style="'background-color:' + this.fadecolor">
        {{ this.AssignmentGrade }} Student Grade
      <br/>
      {{ this.totalAssignmentGrade }} Total Grade
      </v-col>
      <v-col cols="2" :style="' text-align:center; align-self: center;'">
        {{ this.title }}<br />
        <h3>
          {{
            this.grade == 0
              ? "0"
              : this.grade
          }}
          %
        </h3>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "assignment--page",
  components: {},
  props: [
    "title",
    "id",
    "assgrade",
    "path",
    "weekpath",
    "weekid",
    "student",
    "color",
    "fadecolor",
    "weight",
    "subject"
  ],

  data() {
    return { exist: false, grade: 0,assignments:[],rightAnswer: [],totalQuestions:0,AssignmentGrade:0,totalAssignmentGrade:0,
      assid: localStorage.getItem("assignmentid"),
      studentid: this.$store.state.id,
      studentpath: this.$store.state.studentpath};
  },
  created() {

    this.assignments = []
    this.rightAnswer = []
    this.AssignmentGrade = 0;
    this.totalAssignmentGrade = 0;

    //get assignments grade
          firebase
        .firestore()
        .collection(this.$store.state.oraginalsubject + "/submissions")
        .where("student","==",this.studentid)
        .where("breakdown","==",this.$vnode.key)
        .where("weight","==",this.title)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.assignments.push(doc.data());
            if(doc?.data()?.right != undefined){
              this.AssignmentGrade = this.AssignmentGrade + Number(doc?.data()?.right)
            }
           // this.totalAssignmentGrade = this.totalAssignmentGrade + Number(doc.data().assgrade)
          });
          this.grade = (this.AssignmentGrade / this.totalAssignmentGrade * 100).toFixed(2)
        });

//total ass grades from /tasks
firebase
        .firestore()
        .collection(this.$store.state.oraginalsubject + "/tasks")
        .where("breakdown","==",this.$vnode.key)
        .where("weight","==",this.title)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if(doc.data().grade){
            this.totalAssignmentGrade = this.totalAssignmentGrade + Number(doc.data().grade)
            }
          });
          this.grade = (this.AssignmentGrade / this.totalAssignmentGrade * 100).toFixed(2)
        });
  },
  methods: {
    goToAssignIntro() {
      console.log(this.$store.state)

      sessionStorage.setItem("Assgrade",this.grade)
      sessionStorage.setItem("TotalAssgrade",this.totalAssignmentGrade)
      sessionStorage.setItem("TotalStudentAss",this.assignments.length)
      this.$store.commit("weightpre", this.weight);
      this.$store.commit("weighttitle", this.title);

      router.push({
        name:"WeightDetails",
        params: {
          path: this.student,
          breakdown: this.$vnode.key
        }
      });
    }
  }
};
</script>

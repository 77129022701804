<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button pill variant="warning" v-bind="attrs" v-on="on" size="sm">
        <v-icon size="20">mdi-face-recognition</v-icon>
        <small style="margin-left: 20px;">{{ $t("Select Teacher") }} </small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="padding-right: 20px;">mdi-face-recognition</v-icon
          >{{ $t("Select Teacher") }}
        </span>

        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- Fillter -->
        {{ this.$store.state.branch }} / {{ this.$store.state.grade }} /
        {{ this.$store.state.sclass }}
        <br />

        <v-col class="d-flex" cols="4" sm="4">
          <v-select
            :items="subjects"
            v-model="subject"
            label="Subject"
            @change="onSubjectChange()"
          ></v-select>
        </v-col>

        <div v-for="teacher in teachers" :key="teacher.key">
          <Teacher
            :path="teacher.employeepath"
            :classes="teacher.class"
            :id="teacher.key"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">{{ $t("Back") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
import Teacher from "./Teacher";
export default {
  name: "selectStudent",
  components: { Teacher },
  props: ["path", "order"],
  data() {
    return {
      school: "",
      grade: "",
      sclass: "",
      dialog: false,
      activities: [],
      students: [],
      classes: [],
      grades: [],
      level: [],
      schools: [],
      subjects: [],
      teachers: [],
      selectedStudent: {},
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      subject: "",
      sfields: [
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "name",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "250px"
        }
      ]
    };
  },

  created() {
    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.$store.state.branch +
          "/grades/" +
          this.$store.state.grade +
          "/subjects"
      )
      .get()
      .then(querySnapshot => {
        this.subjects = [];
        querySnapshot.forEach(doc => {
          this.subjects.push({
            text: doc.data().title,
            value: doc.ref.path
          });
        });
      });
  },
  methods: {
    startChat(student) {
      this.selectedStudent.category = "Student";
      this.selectedStudent.owner = this.$store.state.username;
      this.selectedStudent.receiver = student;

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/chats")
        .doc(this.$store.state.userID + student)
        .set(this.selectedStudent)
        .then(() => {
          //! Add to Employee Chat Collection
          firebase
            .firestore()
            .collection(
              "/schools/" +
                this.$store.state.school +
                "/employees/" +
                this.$store.state.userID +
                "/chats"
            )
            .doc(this.$store.state.userID + student)
            .set({
              chatpath:
                "schools/" +
                this.$store.state.school +
                "/chats/" +
                this.$store.state.userID +
                student,
              receiver: student,
              owner: this.$store.state.userID
            })
            .then(() => {});

          //! Add to Employee Chat Collection
          firebase
            .firestore()
            .collection(
              "/schools/" +
                this.$store.state.school +
                "/StudentsData/" +
                student +
                "/chats"
            )
            .doc(this.$store.state.userID + student)
            .set({
              chatpath:
                "schools/" +
                this.$store.state.school +
                "/chats/" +
                this.$store.state.userID +
                student,
              receiver: student,
              owner: this.$store.state.userID
            })
            .then(() => {});
          this.dialog = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    onSubjectChange() {
      this.teachers = [];
      firebase
        .firestore()
        .collection(this.subject + "/teachers")
        .get()
        .then(querySnapshot => {
          this.teachers = [];
          querySnapshot.forEach(doc => {
            this.teachers.push({
              key: doc.id,
              employeeid: doc.data().employeeid,
              employeepath: doc.data().employeepath,
              class: doc.data().class
            });
          });
        });
    }
  }
};
</script>

<template>
    <div>
      <v-row>
        <v-col cols="3" :style="'background-color:' + this.color">
          <v-row>
            <v-card
              :min-height="this.weight * 10"
              width="200px"
              dark
              tile
              flat
              :style="'background-color:' + this.color"
            >
              <v-card-title>{{ this.weight }}%</v-card-title>
              <v-card-subtitle style="color:#ffffff">
                {{ this.title }}
              </v-card-subtitle></v-card
            >
          </v-row>
        </v-col>
        <v-col cols="7" :style="'background-color:' + this.fadecolor">
          {{ this.right == 0? "0" : this.right }} right
        <br />
      {{ this.wrong }} wrong

<br />

{{ (this.total) }} total
        </v-col>
        <v-col cols="2" :style="' text-align:center; align-self: center;'">
          {{ this.title }}<br />
          <h3>
            {{
              this.grade == 0
                ? "0"
                : this.grade
            }}
            %
          </h3>
        </v-col>
      </v-row>
      <v-divider />
    </div>
  </template>
  <script>
import firebase from 'firebase';

  export default {
    name:"part--page",
    components: {},
    props: [
    "title",
    "student",
    "color",
    "fadecolor",
    "weight",
    "subject"
    ],
  
    data() {
      return {
        preteach: [],
        weeks: [],
        
        grade: 0,
        heights: 0,
        right:0,
        wrong:0,
        missed:0,
        total:0,
        subjectgrade: 0,

        subjectpath: this.$store.state.subject,
      subjecttitle: this.$store.state.subjecttitle,
      studentpath: this.$store.state.studentpath
        
      };
    },
    created() { 

      this.right = 0;
      this.wrong = 0;
      this.missed = 0;
      this.heights = 0;
      this.grade = 0;
      firebase
      .firestore()
      .collection(this.$store.state.oraginalsubject + "/weeks")
      .where("breakdown", "==", this.$vnode.key)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firebase
      .firestore()
      .collection(this.$store.state.oraginalsubject + "/weeks/"+ doc.id + "/lesson")
      .get()
      .then(el => {
        el.forEach(l=>{
        if(!l.data().link){
          this.total = this.total + 1
        }
        })

      })
        });
        firebase
      .firestore()
      .collection(this.studentpath + "/flipped")
      .where("subjectTitle","==",this.subjecttitle)
      .where("breakdown", "==", this.$vnode.key)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if(doc.data().right != undefined){
            this.right = this.right + doc.data().right;
          }
        
          if(doc.data().wrong || doc.data().wrong > 0){
            console.log(doc.data().wrong )
            this.wrong = this.wrong + doc.data().wrong;
          }
          this.missed = this.missed + doc.data().missed;
          this.heights = Number(doc.data().assgrade)
        });
        //this.grade = ((this.right * this.heights) / (this.heights * (this.right + this.wrong + this.missed)) * 100).toFixed(2)
        this.grade = ((this.right/(this.total))*100).toFixed(2)
      });
      })
//? Get total flipped questions
// firebase
//       .firestore()
//       .collection(this.$store.state.oraginalsubject + "/lesson")
//       .get()
//       .then(querySnapshot => {
//         querySnapshot.forEach(doc => {
//           if(doc.data().totalQuestions){
//             this.total = this.total + Number(doc.data().totalQuestions)
//           }
//         });
//         firebase
//       .firestore()
//       .collection(this.studentpath + "/flipped")
//       .where("subjectTitle","==",this.subjecttitle)
//       .where("breakdown", "==", this.$vnode.key)
//       .get()
//       .then(querySnapshot => {
//         querySnapshot.forEach(doc => {
//           if(doc.data().right != undefined){
//             this.right = this.right + doc.data().right;
//           }
        
//           if(doc.data().wrong || doc.data().wrong > 0){
//             console.log(doc.data().wrong )
//             this.wrong = this.wrong + doc.data().wrong;
//           }
//           this.missed = this.missed + doc.data().missed;
//           this.heights = Number(doc.data().assgrade)
//         });
//         //this.grade = ((this.right * this.heights) / (this.heights * (this.right + this.wrong + this.missed)) * 100).toFixed(2)
//         this.grade = ((this.right/(this.total))*100).toFixed(2)
//       });
//       })
    },
    methods:{

    }
  };
  </script>
  
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-navigation-drawer',{staticStyle:{"z-index":"2","padding-left":"50px"},attrs:{"fixed":"","dark":"","src":"@/assets/exit.jpg","width":"28%","permanent":""}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',{staticStyle:{"padding-top":"30px"}},[_vm._v("Flipped Questions")])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(this.subject + " " + this.grade)+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-container',[(
          this.lesson[_vm.studentclass + _vm.branch + 'flipped'] == null ||
            this.lesson[_vm.studentclass + _vm.branch + 'flipped'] == false ||
            this.thisstudent['blended'] == false
        )?_c('div',{staticStyle:{"text-align":"center"}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('img',{attrs:{"src":require("@/assets/waiting.png"),"width":"40%"}}),_c('h5',{staticStyle:{"padding-top":"50px"}},[_vm._v(" Waiting for Flipped Questions to be Opened ")]),_c('br'),_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":_vm.onRetry}},[_vm._v("Retry")])],1)],1)],1):_c('div',[(this.before.week != null)?_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('h5',{staticStyle:{"padding-top":"50px"}},[_vm._v(" You Already Submitted Before ")])])],1)],1):_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Question "+_vm._s(this.current + 1)+" "),_c('v-divider')],1),_c('v-card-text',[_c('div',[_c('Question',{key:_vm.current,attrs:{"path":_vm.examquestions[_vm.arr[this.current]].path,"student":this.studentpath +
                    '/assignments/' +
                    this.weekid +
                    'flipped/assignmentattemps/1',"source":"Flipped","subject":this.subject}})],1)]),_c('v-card-actions',[_c('v-footer',{staticStyle:{"background-color":"#1d2835"},attrs:{"dark":"","padless":"","fixed":"","height":"80px"}},[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-progress-linear',{attrs:{"color":"light-blue","height":"10","value":((this.current + 1) / this.count) * 100,"striped":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[(this.count > 0)?_c('div',[_c('small',{staticStyle:{"margin-right":"20px"}},[_vm._v(" "+_vm._s(this.current + 1)+" / "+_vm._s(this.count))]),(this.current < this.count - 1)?_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(_vm._s(_vm.$t("Next Question"))+" ")],1):_c('v-btn',{staticStyle:{"margin-right":"40px"},attrs:{"color":"green","small":"","rounded":"","dark":""},on:{"click":_vm.onSubmit}},[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Submit Flipped ")],1)],1):_vm._e()])],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
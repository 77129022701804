/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
    name: "store",

    state: {
        videotype: "",
        studentpath: "",
        educationPath: "",
        branch: "",
        email: "",
        grade: "",
        sclass: "",
        lessonview: "",
        firstname: "",
        lastname: "",
        id: "",

        breakdown:"",
        lessonbreakdown:"",
        weekpath: "",
        weekid: "",
        weektitle: "",


        assid: "",
        asspath: "",


        livetitle: "",
        livedate: "",
        livetime: "",
        livelink: "",
        liverecorded: "",


        submissionpath: "",

        school: "",


        admin: false,



        mirror: "",
        subject: "",
        subjecttitle: "",
        oraginalsubject: "",
        selectAss: "",
        subjectTitle: "",
        weektitle: "",
        weekpath: "",
        current: "",


        weighttitle: "",
        weightpre: 0,
        weightcount: 0,

        //! Parent
        parentname: "",
        parentid: "",
        sonfirst: "",
        sonlast: "",
        sonbranch: "",
        songrade: "",
        sonclass: "",
        sonpath: "",




        religion: "",
        secondlanguage: "",


        //Activity Reset
        rightAnswers: 0,
        wrongAnswers: 0,

        flippedrightAnswers: 0,
        exitrightAnswers: 0,

        missedAnswers: 0,
        lessonrightAnswers: 0,
        lessonwrongAnswers: 0,
        totalQuestions: 0,
        type: 0,
        weightcode:0,
        asstype:"",
        current: 0,

        rules: [],

        gradebook: [],
        inclasssanswers: [],

        user: {
            loggedIn: false,
            data: null
        },

        loggedIn: null
    },

    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],
    getters: {
        loggedIn: state => state.loggedIn,
        user(state) {
            return state.user;
        }
    },
    mutations: {

        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        studentpath: (state, value) =>
            value ? (state.studentpath = value) : (state.studentpath = ""),
        breakdown: (state, value) =>
        value ? (state.breakdown = value) : (state.breakdown = ""),
        lessonbreakdown: (state, value) =>
        value ? (state.lessonbreakdown = value) : (state.lessonbreakdown = ""),
        educationPath: (state, value) =>
            value ? (state.educationPath = value) : (state.educationPath = ""),
        branch: (state, value) =>
            value ? (state.branch = value) : (state.branch = ""),
        email: (state, value) =>
            value ? (state.email = value) : (state.email = ""),
        grade: (state, value) =>
            value ? (state.grade = value) : (state.grade = ""),
        sclass: (state, value) =>
            value ? (state.sclass = value) : (state.sclass = ""),
        lessonview: (state, value) =>
            value ? (state.lessonview = value) : (state.lessonview = ""),
        firstname: (state, value) =>
            value ? (state.firstname = value) : (state.firstname = ""),
        lastname: (state, value) =>
            value ? (state.lastname = value) : (state.lastname = ""),
        id: (state, value) =>
            value ? (state.id = value) : (state.id = ""),
        subject: (state, value) =>
            value ? (state.subject = value) : (state.subject = ""),
        mirror: (state, value) =>
            value ? (state.mirror = value) : (state.mirror = ""),
        subjecttitle: (state, value) =>
            value ? (state.subjecttitle = value) : (state.subjecttitle = ""),
        oraginalsubject: (state, value) =>
            value ? (state.oraginalsubject = value) : (state.oraginalsubject = ""),
        weekpath: (state, value) =>
            value ? (state.weekpath = value) : (state.weekpath = ""),
        weekid: (state, value) =>
            value ? (state.weekid = value) : (state.weekid = ""),
        weektitle: (state, value) =>
            value ? (state.weektitle = value) : (state.weektitle = ""),
        assid: (state, value) =>
            value ? (state.assid = value) : (state.assid = ""),
        asspath: (state, value) =>
            value ? (state.asspath = value) : (state.asspath = ""),

        livetitle: (state, value) =>
            value ? (state.livetitle = value) : (state.livetitle = ""),
        livedate: (state, value) =>
            value ? (state.livedate = value) : (state.livedate = ""),
        livetime: (state, value) =>
            value ? (state.livetime = value) : (state.livetime = ""),
        livelink: (state, value) =>
            value ? (state.livelink = value) : (state.livelink = ""),
        liverecorded: (state, value) =>
            value ? (state.liverecorded = value) : (state.liverecorded = ""),

        submissionpath: (state, value) =>
            value ? (state.submissionpath = value) : (state.submissionpath = ""),
        admin: (state, value) =>
            value ? (state.admin = value) : (state.admin = ""),
        videotype: (state, value) =>
            value ? (state.videotype = value) : (state.videotype = ""),
            asstype: (state, value) =>
            value ? (state.asstype = value) : (state.asstype = ""),
            weightcode: (state, value) =>
            value ? (state.weightcode = value) : (state.weightcode = 0),
        school: (state, value) =>
            value ? (state.school = value) : (state.school = ""),


        religion: (state, value) =>
            value ? (state.religion = value) : (state.religion = ""),
        secondlanguage: (state, value) =>
            value ? (state.secondlanguage = value) : (state.secondlanguage = ""),

        //! Parent
        parentname: (state, value) =>
            value ? (state.parentname = value) : (state.parentname = ""),
        parentid: (state, value) =>
            value ? (state.parentid = value) : (state.parentid = ""),
        sonpath: (state, value) =>
            value ? (state.sonpath = value) : (state.sonpath = ""),
        sonfirst: (state, value) =>
            value ? (state.sonfirst = value) : (state.sonfirst = ""),
        sonlast: (state, value) =>
            value ? (state.sonlast = value) : (state.sonlast = ""),
        sonbranch: (state, value) =>
            value ? (state.sonbranch = value) : (state.sonbranch = ""),
        songrade: (state, value) =>
            value ? (state.songrade = value) : (state.songrade = ""),
        sonclass: (state, value) =>
            value ? (state.sonclass = value) : (state.sonclass = ""),


        //! Weights For Report Card
        weighttitle: (state, value) =>
            value ? (state.weighttitle = value) : (state.weighttitle = ""),
        weightpre: (state, value) =>
            value ? (state.weightpre = value) : (state.weightpre = ""),

        weightcount: (state, value) =>
            value ? (state.weightcount = value) : (state.weightcount = 0),

        current: (state, value) =>
            value ? (state.current = value) : (state.current = 0),


        cleargradebook(state) {
            state.gradebook = [];
        },
        gradebook(state, value) {
            value ? (state.gradebook.push(value)) : (state.gradebook = []);
        },

        clearinclass(state) {
            state.inclasssanswers = [];
        },

        inclasssanswers(state, value) {
            value ? (state.inclasssanswers.push(value)) : (state.inclasssanswers = []);
        }
        //state.gradebook.push(value)




    },
    actions: {
        fetchUser({ commit }, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                commit("SET_USER", null);
            }
        }
    },
    modules: {}
});
<template>
  <div>
    <!-- Topbar Begin -->

    <v-navigation-drawer
      src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/subjectheader2.jpg?alt=media&token=9016a131-0218-439e-a8e0-6bebd1133201"
      height="300px"
      width="100%"
      permanent
    >
      <div style="padding-inline:30px; padding-top:70px; color:#fff; font-family: arabic;">
        <h3>{{$t("Welcome to") +" " +  this.$route.params.title }}</h3>

        <span style="color:#f2b938"> {{ grade + " " + subject }}</span>
      </div>
    </v-navigation-drawer>

    <!-- End Topbar -->
    <v-dialog v-model="dialog" max-width="50%">
      <v-card>
        <v-card-title
          >Subject Resources
          <v-divider></v-divider>

          <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
        </v-card-title>
        <v-card-text style="text-align:center;">
          <div style="overflow: auto; height: 20%">
            <vuescroll :ops="ops">
              <v-card
                :href="res.link"
                target="_blank"
                flat
                outlined
                v-for="res in resources"
                :key="res.key"
              >
                <v-card-text>
                  <v-row
                    ><v-col cols="3">
                      <img src="@/assets/book.png" width="70px" />
                    </v-col>

                    <v-col cols="9"> {{ res.title }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </vuescroll>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" sm="8" xs="8">
        <v-card color="#c7cfdd">
          <v-card-text>
            <WeeksList
              :path="this.$route.params.path"
              :mirror="this.mirror"
            ></WeeksList>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" xs="4" :style="$i18n.locale=='Arabic'? {'margin-right': '-10px'} : ''">
        <!--  <lessonActivityWidget :path="this.$route.params.path" />-->

        <!-- Enrichment -->
        <v-card
          v-if="this.enrichment"
          flat
          dark
          class="text-center"
          @click="goToEnrichment(enrichment, enrichmenttitle, enrichmentid)"
          style="cursor: pointer; text-align:center; margin-top:20px; background-color:#121212;"
        >
          <v-card-text>
            <v-list style="background-color:#121212;">
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-avatar size="100" tile>
                    <img src="@/assets/enrichment.png"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="font-size:20px"
                      >{{ $t("Remedial") }} </v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ $t("You have Remedial in this subject") }} 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- Subject Progress -->
        <v-card
          flat
          dark
          class="text-center"
          @click="goToReport"
          style="cursor: pointer; text-align:center; margin-top:20px; background-color:#002147;"
        >
          <v-card-text>
            <v-list style="background-color:#002147;">
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-avatar size="50" tile>
                    <img src="@/assets/chart.png"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Subject Progress") }} </v-list-item-title>
                    <v-list-item-subtitle
                      >{{ $t("Find your progress and achievement") }} 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- Course Gradebook -->
        <v-card
          flat
          dark
          class="text-center"
          style="cursor: pointer; text-align:center; margin-top:10px; background-color:#002147;"
          @click="goToGrades"
        >
          <v-card-text>
            <v-list style="background-color:#002147;">
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-avatar size="50" tile>
                    <img src="@/assets/gradebook.png"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{
                        this.$store.state.subjecttitle
                      }}
                     {{ $t("Gradebook") }} </v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ $t("Assignments and Activities Grades") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
        <!-- Course Resources & Books -->
        <v-card
          flat
          dark
          class="text-center"
          style="cursor: pointer; text-align:center; margin-top:10px; background-color:#002147;"
          @click.stop="dialog = true"
        >
          <v-card-text>
            <v-list style="background-color:#002147;">
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-avatar size="50" tile>
                    <img src="@/assets/books.png"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Course Resources") }} </v-list-item-title>
                    <v-list-item-subtitle
                      >{{ $t("Books, Notes & Subject Policies") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <v-card
          flat
          dark
          style="cursor: pointer; text-align:center; margin-top:20px; background-color:#002147;"
        >
          <v-card-text>
            <div v-if="exams.length == 0">
              <img src="@/assets/nodata.png" width="20%" />
              <br />
              <small>{{ $t("No Upcoming Exams") }} </small>
            </div>

            <v-list dense dark v-else color="#002147">
              <v-list-item-group color="primary">
                <span v-for="(item, i) in exams" :key="i">
                  <v-list-item @click="goToExam(item.path, item.title)">
                    <v-list-item-avatar style="background-color: #53a451">
                      <v-icon color="white" size="24"
                        >mdi-frequently-asked-questions</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        ><h6>{{ item.title }}</h6></v-list-item-title
                      >
                      <v-list-item-subtitle style="color: #53a451">{{
                        item.start
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <!--      <v-card
          flat
          style="cursor: pointer; text-align:center; margin-top:20px"
        >
          <v-card-text>
            <img src="@/assets/revision.jpg" width="80%" />

            <br />
            <h4><span style="color:#3096b2;">Revision</span></h4>
          </v-card-text>
        </v-card>-->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import WeeksList from "../weeks/WeeksList";

export default {
  name: "SubjectDash",
  components: {
    WeeksList
  },

  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#c1c1c1",
          opacity: 1,
          specifyBorderRadius: false,
          minSize: 0,
          size: "6px",
          disable: false
        }
      },

      dialog: false,

      mirror: this.$store.state.mirror,
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      student: this.$store.state.studentpath,

      weeksRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/weeks/"),
      examsRef: firebase
        .firestore()
        .collection(this.$store.state.oraginalsubject + "/exams"),
      sessionsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/sessions"),
      resourcesRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/resources/"),
      weeks: [],
      exams: [],
      sessions: [],
      resources: [],
      standards: [],
      concepts: [],
      enrichment: "",
      enrichmentid: "",
      enrichmenttitle: ""
    };
  },
  created() {
    this.examsRef.get().then(querySnapshot => {
      this.exams = [];
      querySnapshot.forEach(doc => {
        if (
          doc.data().class == null ||
          doc.data().class == this.$store.state.sclass
        )
          this.exams.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            start: doc.data().opendate
          });
      });
    });
    this.sessionsRef
      .where("sessionclass", "==", this.$store.state.sclass)
      .get()
      .then(querySnapshot => {
        this.sessions = [];
        querySnapshot.forEach(doc => {
          this.sessions.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            date: doc.data().date,
            time: doc.data().time,
            link: doc.data().link
          });
        });
      });
    this.weeksRef.get().then(querySnapshot => {
      this.weeks = [];
      querySnapshot.forEach(doc => {
        this.weeks.push({
          key: doc.id
        });

        if (
          doc.data().enrichment == true &&
          doc.data().students.includes(this.student)
        ) {
          this.enrichment = doc.ref.path;
          this.enrichmentid = doc.id;
          this.enrichmenttitle = doc.data().title;
        }
      });
    });
    this.resourcesRef.get().then(querySnapshot => {
      this.resources = [];
      querySnapshot.forEach(doc => {
        if (doc.data().hide != true)
          this.resources.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            link: doc.data().link
          });
      });
    });
  },
  methods: {
    goToExam(path, title) {
      router.push({
        name: "Exam",
        params: { path: path, title: title }
      });
    },

    goToEnrichment(path, title, id) {
      this.$store.state.rightAnswers = 0;
      this.$store.state.lessonrightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.lessonwrongAnswers = 0;

      this.$store.commit("weekpath", path);
      this.$store.commit("weekid", id);
      this.$store.commit("weektitle", title);

      router.push({
        name: "ReviewPage"
      });
    },
    goToSession(link) {
      window.open(link, "_blank");
    },
    goToGrades() {
      router.push({ name: "Gradebook" });
    },
    goToReport() {
      this.$store.commit("cleargradebook");

      router.push({ name: "SubjectProgress", 
      params:{
        path: this.student,
          subject:this.$route.params.title
      }
          });
    }
  }
};
</script>

<template>
  <div>
    <v-card style="margin-top:20px; background-color:#033b59;" dark flat>
      <!--  Overdue Assignments -->

      <div v-if="Object.keys(this.assignments).length === 0">
        <div
          v-if="Date.now() > Date.parse(closedate)"
          style="margin-top:20px; background-color:red; padding-left:10px;"
        >
       
          <v-row
            style="margin-top:20px;  padding-left:10px; padding-right:20px;"
          >
            <span>
              <b>{{ this.weight }}</b>

              <v-spacer />{{ this.opendate }}</span
            >

            <v-spacer />
            <div
              @click="goToAssignIntro()"
              small
              light
              style="cursor: pointer;"
            >
              Go To Assignment
            </div>
          </v-row>
        </div>

        <!--  Waiting Submission -->
        <div
          v-else
          style="margin-top:20px; background-color:#40b1e5; padding-left:10px;"
        >
          <v-row
            style="margin-top:20px;  padding-left:10px; padding-right:20px;"
          >
            <span>
              <b>{{ this.weight }}</b>

              <v-spacer />{{ this.opendate }}</span
            >

            <v-spacer />
            <div
              @click="goToAssignIntro()"
              small
              light
              style="cursor: pointer;"
            >
              Go To Assignment
            </div>
          </v-row>
        </div>
      </div>

      <!--  Delivered Assignments -->

      <div
        v-else
        style="margin-top:20px; background-color:green; padding-left:10px;"
      >
        <v-row style="margin-top:20px;  padding-left:10px; padding-right:20px;">
          <span
            ><b> {{ this.weight }}</b
            ><v-spacer />{{ this.opendate }}</span
          >
          <v-spacer />
          <div @click="goToAssignIntro()" small light style="cursor: pointer;">
            Go To Assignment
          </div>
        </v-row>
      </div>
      <v-card-subtitle>
        <v-row>
          <v-col cols="10">
            {{ this.title }}
          </v-col>
          <v-col
            cols="2"
            v-if="Object.keys(this.assignments).length != 0"
            style="background-color:green; color:#ffffff; font-size:16px;"
          >
            <!-- {{ this.assignments.right != ""?this.assignments.right:"0" }} /{{ this.grade }} -->
            {{ this.Assgrade +"/"+ this.grade }}
          </v-col>
          <v-col
            cols="2"
            v-if="Object.keys(this.assignments).length == 0 && Date.now() > Date.parse(closedate)"
            style="background-color:red; color:#ffffff; font-size:16px;"
          >
            <!-- {{ this.assignments.right != ""?this.assignments.right:"0" }} /{{ this.grade }} -->
            {{ this.Assgrade +"/"+ this.grade }}
          </v-col>
          <v-col
            cols="2"
            v-if="Object.keys(this.assignments).length == 0 && Date.now() <= Date.parse(closedate)"
            style="background-color:#40b1e5; color:#ffffff; font-size:16px;"
          >
            <!-- {{ this.assignments.right != ""?this.assignments.right:"0" }} /{{ this.grade }} -->
            {{ this.Assgrade +"/"+ this.grade }}
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <div v-if="this.assignments != ''">
          <div v-if="this.assignments.feedback">
            <v-card light
              ><v-card-text>
                <!-- {{ this.assignments.feedback }} -->
                {{ this.feedback }}
                </v-card-text
              ></v-card
            >
          </div>
        </div>
        <br />
        <!-- <br />
      Number Of Attempts: {{ this.assignments.length }}-->
        <v-row></v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "AssignLesson",
  props: [
    "path",
    "type",
    "title",
    "grade",
    "opendate",
    "closedate",
    "weight",
    "assid",
    "weekpath",
    "weektitle",
    "weekid",
    "oraginal"
  ],
  data() {
    return {
      assignments: {},
      lessons: [],
      feedback:'',
      Assgrade:0,
      maxAttempt:0,
      gradeList:[],
      week: "",
      loading: false,
      studentid: this.$store.state.email
    };
  },
  created() {

    firebase
      .firestore()
      .doc(this.oraginal + "/submissions/" + this.studentid + this.assid)
      .get()
      .then(querySnapshot => {
        if(querySnapshot.exists){

          this.maxAttempt = querySnapshot?.data()?.attempts
          this.assignments = querySnapshot.data()
          if(querySnapshot?.data()?.attempts == 1){
            this.feedback = this.assignments.feedback ?? "";
          this.Assgrade = this.assignments.right;
          } else{
            this.getAttempts();
          }
        }

      });
  },
  methods: {
    goToAssignIntro() {
      this.$store.commit("assid", this.assid);
      this.$store.commit("asspath", this.path);

      this.$store.commit("weekpath", this.weekpath);
      this.$store.commit("weekid", this.weekid);
      this.$store.commit("weektitle", this.weektitle);

      this.$store.state.rightAnswers = 0;
      this.$store.state.lessonrightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.lessonwrongAnswers = 0;
      // this.$store.state.weektitle = this.weektitle;
      // this.$store.state.weekpath = this.weekpath;

      router.push({
        name: "AssignmentIntro"
        //name: "Assignment"
      });
    },
    getAttempts(){

         firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.studentid +
            "/quizes/" +
            this.assid +
            "/attempts"
        )
        .get()
        .then((querySnapshot) => {
         // this.StudentAttempts = [];
          this.gradeList = []
          querySnapshot.forEach((doc) => {
            // this.StudentAttempts.push({
            //   attempt: doc.data().attempts,
            //   text:String(doc.data().attempts),
            //   value:doc.data().attempts,
            //   date:doc.data().date,
            //   right: doc.data().right
            // });
            if(Number(doc.data().attempts) != 0 && (Number(doc.data().attempts) < Number(this.maxAttempt) || Number(doc.data().attempts) == 1)){
              this.gradeList.push(Number(doc.data().right))
              console.log(Number(doc.data().right),doc.data().title)
            }
          }); 
          let max = Math.max(...this.gradeList) == -Infinity ? 0: Math.max(...this.gradeList)
          if(max != 0){
            if(!Number.isNaN(max)){
              this.Assgrade = max
            }else{
              this.Assgrade = "n/a"
            }
          }

        });
    }
  }
};
</script>

<template>
  <div v-if="this.exam.quiztype == 'Random From Question Bank'">
    <RandomExam :path="this.path" />
  </div>
  <div v-else>
    <FixedExam :path="this.path" />
  </div>
</template>
<script>
import firebase from "@/Firebase";
import RandomExam from "./RandomExam";
import FixedExam from "./FixedExam";

export default {
  name:"paper-exam",
  props: ["path"],
  components: { RandomExam, FixedExam },
  data() {
    return { exam: {} };
  },
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.exam = snapshot.data();
      });
  }
};
</script>

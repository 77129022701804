<template>
  <div>
    <v-card flat outlined style="margin-bottom:10px">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-slide-group multiple show-arrows>
          <v-slide-item v-for="v in videos" :key="v.key">
            <v-card
              class="ma-5 d-flex align-center"
              style="cursor: pointer;"
              flat
              hover
              height="300"
              width="400"
            >
              <v-card-text>
                <iframe
                  v-if="v.link.includes('vimeo')"
                  :src="v.link"
                  width="100%"
                  height="300px"
                  marginwidth="0"
                  marginheight="0"
                  hspace="0"
                  vspace="0"
                  frameborder="0"
                  scrolling="no"
                  allowfullscreen
                />

                <video-embed :src="v.link" v-else></video-embed>

                {{ v.title }}
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "WeekVideos",
  props: ["path", "title"],
  data() {
    return { videos: [], type: this.$store.state.videotype, videospath: "" };
  },
  created() {
    if (this.type == "videos") this.videospath = this.path + "/videos/";
    else this.videospath = this.path + "/internetvideos";

    firebase
      .firestore()
      .collection(this.videospath)
      .get()
      .then(querySnapshot => {
        this.videos = [];
        querySnapshot.forEach(doc => {
          this.videos.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            link: doc.data().link,
            section: doc.data().section,
            description: doc.data().description
          });
        });
      });
  },

  methods: {}
};
</script>

<style lang="scss" scoped></style>

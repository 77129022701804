<template>
  <div>
    <!-- Topbar Begin -->

    <v-navigation-drawer
      src="@/assets/sondash.jpg"
      height="300px"
      width="100%"
      permanent
    >
      <div style="padding-left:30px; padding-top:70px; color:#fff;">
        <h3>
          {{ this.$store.state.sonfirst + " " + this.$store.state.sonlast }}
        </h3>

        <span style="color:#f2b938"> {{ this.$store.state.sonbranch }}</span>
        <br />
        <span style="color:#f2b938"> {{ grade }}</span>
      </div>
    </v-navigation-drawer>

    <!-- End Topbar -->
    <v-card
      ><v-card-text>
        <v-card flat outlined
          ><v-card-text>
            <v-row>
              <b style="font-size:20px; color:green;"
                >Cash Balance {{ studentdata.balance }} L.E</b
              >
              <v-spacer />
              <v-btn depressed color="info" small>School Requests</v-btn>
            </v-row>
          </v-card-text></v-card
        >
        <v-row>
          <v-col cols="6">
            <v-card color="#c9ee75" flat outlined>
              <v-card-title>Subjects</v-card-title>
              <v-card-text>
                <v-list dense style="max-height: 700px;  ">
                  <v-list-item-group color="primary">
                    <div style="overflow: auto; height: 700px;">
                      <v-list-item
                        three-line
                        v-for="subject in subjects"
                        :key="subject.id"
                        dark
                      >
                        <v-card
                          width="100%"
                          shaped
                          flat
                          light
                          outlined
                          style="width='500px'; margin-bottom:50px; background-color:#d7e9f9; margin-right:20px;"
                        >
                          <v-card-text>
                            <v-row>
                              <v-col cols="3">
                                <img
                                  v-if="subject.icon"
                                  :src="
                                    require(`@/assets/subjects/${subject.icon}`)
                                  "
                                  width="80px"
                                />
                                <img
                                  v-else
                                  :src="
                                    require('@/assets/subjects/english.png')
                                  "
                                  width="80px"
                                />
                              </v-col>
                              <v-col cols="9">
                                <h5>
                                  {{ $t(subject.title) }}
                                </h5>
                                {{ $t(grade) }}
                                <br />

                                <v-btn
                                  small
                                  depressed
                                  @click="
                                    goGradebook(
                                      subject.path,
                                      subject.title,
                                      subject.mirror
                                    )
                                  "
                                  >Gradebook</v-btn
                                >
                                <v-btn
                                  small
                                  depressed
                                  style="margin-left:10px"
                                  @click="
                                    goProgress(
                                      subject.path,
                                      subject.title,
                                      subject.mirror
                                    )
                                  "
                                  >{{$t("Progress Reports")}}</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-list-item>
                    </div>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card color="#c7cfdd" flat outlined>
              <v-card-title
                >Collection

                <v-divider /><span style="font-size:12px; margin-right:10px;"
                  >Balance
                </span>
                <span style="color:red"> {{ this.balance }}</span>
                <span style="font-size:12px; margin-left:5px;"> L.E</span>
              </v-card-title>
              <v-card-text>
                <span style="font-size:16px; color:red;">Fees</span>
                <v-card
                  flat
                  outlined
                  v-for="fee in fees"
                  :key="fee.key"
                  style="margin-bottom:5px;margin-top:5px;"
                >
                  <v-card-text>
                    <v-row>
                      {{ fee.year }}<v-spacer /> <b>{{ fee.type }}</b>
                      <v-divider />{{ fee.amount }} L.E</v-row
                    ></v-card-text
                  >
                </v-card>
                <v-divider />

                <span style="font-size:16px; color:green;">Payments</span>
                <v-card
                  flat
                  outlined
                  v-for="payment in payments"
                  :key="payment.key"
                  style="margin-bottom:5px;margin-top:5px;"
                >
                  <v-card-text>
                    <v-row>
                      {{ payment.year }}<v-spacer /> <b>{{ payment.type }}</b>
                      <v-divider />{{ payment.amount }} L.E</v-row
                    ></v-card-text
                  >
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text></v-card
    >
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "SubjectDash",
  components: {},

  data() {
    return {
      dialog: false,

      mirror: this.$store.state.mirror,
      grade: this.$store.state.songrade,
      student: this.$store.state.studentpath,
      branch: this.$store.state.sonbranch,

      totalfees: 0,
      totalpayments: 0,
      balance: 0,

      fees: [],
      payments: [],
      attendance: [],
      subjects: [],
      studentdata: {}
    };
  },
  created() {
    this.totalfees = 0;
    this.totalpayments = 0;
    this.balance = 0;

    firebase
      .firestore()
      .doc(this.student)
      .onSnapshot(doc => {
        this.studentdata = doc.data();
      });

    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.branch +
          "/grades/" +
          this.grade +
          "/subjects"
      )
      .get()
      .then(querySnapshot => {
        this.subjects = [];
        querySnapshot.forEach(doc => {
          this.subjects.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            mirror: doc.data().mirror,
            icon:
              doc.data().icon == "Statistics.png"
                ? "english.png"
                : doc.data().icon
          });
        });
      });

    firebase
      .firestore()
      .collection(this.$store.state.sonpath + "/fees")
      .orderBy("type")
      .get()
      .then(querySnapshot => {
        this.fees = [];
        querySnapshot.forEach(doc => {
          // this.totalfees += doc.data().amount;
          this.totalfees += Number(doc.data().amount);
          this.fees.push({
            key: doc.id,
            path: doc.ref.path,
            amount: doc.data().amount,
            type: doc.data().type,
            year: doc.data().year
          });
        });
      });

    firebase
      .firestore()
      .collection(this.$store.state.sonpath + "/payments")
      .orderBy("type")
      .get()
      .then(querySnapshot => {
        this.payments = [];
        querySnapshot.forEach(doc => {
          this.totalpayments += Number(doc.data().amount);
          this.payments.push({
            key: doc.id,
            path: doc.ref.path,
            amount: doc.data().amount,
            type: doc.data().type,
            year: doc.data().year
          });
        });

        this.balance = this.totalpayments - this.totalfees;
      });

    firebase
      .firestore()
      .collection(this.$store.state.sonpath + "/attendance")
      .get()
      .then(querySnapshot => {
        this.attendance = [];
        querySnapshot.forEach(doc => {
          this.attendance.push({
            key: doc.id,
            path: doc.ref.path,
            amount: doc.data().amount,
            type: doc.data().type,
            date: doc.data().date,
            subject: doc.data().subject
          });
        });
      });
  },
  methods: {
    goGradebook(path, title, mirror) {
      this.$store.commit("mirror", mirror);
      if (mirror) {
        this.$store.commit("subject", mirror);
      } else {
        this.$store.commit("subject", path);
      }

      this.$store.commit("subjecttitle", title);
      this.$store.commit("oraginalsubject", path);

      router.push({
        name: "ParentGradebook",
        params: { path: path, title: title }
      });
    },
    goProgress(path, title, mirror) {
      this.$store.commit("mirror", mirror);
      if (mirror) {
        this.$store.commit("subject", mirror);
      } else {
        this.$store.commit("subject", path);
      }

      this.$store.commit("subjecttitle", title);
      this.$store.commit("oraginalsubject", path);
      router.push({
        name: "ParentSubjectProgress",
        params: { path: path, title: title }
      });
    },
    goToSession(link) {
      window.open(link, "_blank");
    },
    goToGrades() {
      router.push({ name: "ParentGradebook" });
    },
    goToReport() {
      this.$store.commit("cleargradebook");

      router.push({ name: "SubjectProgress" });
    }
  }
};
</script>
<style scoped>
.body {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(24, 146, 0, 0.904);
  box-shadow: 0 0 1px rgba(24, 146, 0, 0.904);
}
</style>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/examside.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">Practice</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
                <br />
                <span>{{ this.week }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-sheet v-if="loading == false" color="darken-2" class="pa-3">
          <v-skeleton-loader
            v-bind="attrs"
            type="article, actions"
          ></v-skeleton-loader>
        </v-sheet>
        <v-card flat v-else>
          <!--  <v-card-title v-html="activities[current].title"></v-card-title>-->

          <v-card-text>
            <div style="width: 100%;margin-bottom:50px;">
              <h4 style="color:#0fa1e8">
                <b> Question {{ this.current + 1 }}</b>
              </h4>

              <PracticeQuestion
                :path="questions[arr[this.current]].path"
                source="Quiz"
                :subject="this.subject"
                :key="current"
                :student="this.$store.state.studentpath"
              ></PracticeQuestion>
            </div>
          </v-card-text>

          <v-card-actions>
            <!-- Quiz Footer -->
            <v-footer
              dark
              padless
              fixed
              height="80px"
              style="background-color:#1d2835;"
            >
              <v-col cols="6"> </v-col>

              <v-col cols="4">
                <v-progress-linear
                  color="light-blue"
                  height="10"
                  :value="((this.current + 1) / 10) * 100"
                  striped
                ></v-progress-linear>
              </v-col>

              <!-- Next Button-->
              <v-col cols="2">
                <div v-if="10 > 0">
                  <small style="margin-right: 20px">
                    {{ this.current + 1 }} / 10</small
                  >
                  <v-btn
                    color="primary"
                    style="margin-right: 20px"
                    rounded
                    @click="next()"
                    small
                    v-if="this.current < 9"
                  >
                    <v-icon>mdi-chevron-right</v-icon>{{$t("Next Question")}}
                  </v-btn>

                  <v-btn
                    v-else
                    color="green"
                    small
                    rounded
                    dark
                    @click="onSubmit"
                    style="margin-right:40px"
                  >
                    <v-icon>mdi-chevron-right</v-icon>Finish Practice
                  </v-btn>
                </div>
              </v-col>
            </v-footer>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import PracticeQuestion from "@/components/dna/PracticeQuestion.vue";
import router from "@/router";
export default {
  name: "ListPage",
  props: ["path"],
  components: {
    PracticeQuestion
},
  data() {
    return {
      questions: [],
      current: 0,
      record: {},
      loading: false,
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      week: this.$store.state.weektitle,
      student: this.$store.state.studentpath
    };
  },
  methods: {
    next() {
      if (this.$store.state.answered == true) {
        if (this.current < 9) {
          this.current++;
        }
      } else {
        this.$swal(
          {
            title: "Missed Answer",
            icon: "warning",
            text: "Please Answer or Skip this Question to Continue",
            showLoaderOnConfirm: true
          }
        );
      }
    },
    onSubmit() {
      //this.updatePoints(this.$store.state.rightAnswers);
      // router.back();
      //router.push({ name: "Home" });
      router.replace({
                    name: "PracticeReport"
                  });
    },

    updatePoints(count) {
      //! increment points
      const increment = firebase.firestore.FieldValue.increment(count);
      try {
        firebase
          .firestore()
          .doc(this.$store.state.studentpath)
          .update({ points: increment })
          .then(() => {})
          .catch(e => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
      //! End increment points
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    },
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      const videoid = match && match[2].length === 11 ? match[2] : null;

      return "https://www.youtube.com/embed/" + videoid;
    }
  },
  created() {
    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/WeekPractice/"+this.$store.state.weekid+"/answers")
                .get()
                .then((docs) => {
                  docs.forEach(el => {
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/WeekPractice/"+this.$store.state.weekid+"/answers")
                .doc(el.id)
                .delete()
                  })
        })
        firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/WeekPractice/"+this.$store.state.weekid+"/weakness")
                .get()
                .then((docs) => {
                  docs.forEach(el => {
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/WeekPractice/"+this.$store.state.weekid+"/weakness")
                .doc(el.id)
                .delete()
                  })
        })
    firebase
      .firestore()
      .collection(this.$route.params.path + "/questions")

      .get()
      .then(querySnapshot => {
        this.questions = [];
        querySnapshot.forEach(doc => {
          if (
            doc.data().exam != "Yes" &&
            doc.data().flipped != "Yes" &&
            doc.data().exit != "Yes" &&
            doc.data().inclass != true
          )
            this.questions.push({
              key: doc.id,
              path: doc.ref.path
              // title: doc.data().title,
              // type: doc.data().qtype,
              // answer: doc.data().answer,
              // bloom: doc.data().bloom,
              // standard: doc.data().standard,
              // difficulty: doc.data().difficulty
            });
        });

        // ! Generate Unique Random List
        this.arr = [];
        while (this.arr.length < this.questions.length) {
          var r = Math.floor(Math.random() * this.questions.length);
          if (this.arr.indexOf(r) === -1) this.arr.push(r);
        }

        this.loading = true;
      });
  }
};
</script>

import { render, staticRenderFns } from "./Assignment.vue?vue&type=template&id=61c5b443&"
import script from "./Assignment.vue?vue&type=script&lang=js&"
export * from "./Assignment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
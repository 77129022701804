<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/videoheader.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">
                  Live Session
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-card>
          <v-card-title>{{ this.title }}</v-card-title>
          <v-card-text>
            <v-card flat outlined
              ><v-card-text>
                <a :href="recorded" target="_blank"
                  >Click Here To Open The PreRecorder Session</a
                >
              </v-card-text>
            </v-card>

            <br />
            <v-card flat outlined>
              <v-card-text>
                <h6>The session starts at:</h6>
                <br />
                {{ this.date }}
                <br />
                {{ this.time }}

                <br />
                <br />

                <b-button variant="success" size="sm" @click="goToLive(link)"
                  >Join The Video Session</b-button
                >
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "List-Page",
  props: ["path"],

  components: {},
  data() {
    return {
      activities: [],
      questions: [],
      current: 0,
      record: {},
      total: 0,
      title: this.$store.state.livetitle,
      date: this.$store.state.livedate,
      time: this.$store.state.livetime,
      link: this.$store.state.livelink,
      recorded: this.$store.state.liverecorded,
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade
    };
  },
  methods: {
    goToLive(link) {
      window.open(link, "_blank");
    }
  }
};
</script>

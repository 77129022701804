<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/lesson.jpg?alt=media&token=2626d835-a0c0-4fab-912b-4e21d373d18e"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h6 style="padding-top:30px;">{{ this.week }}</h6>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
                <span>{{ this.title }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9" :style="$i18n.locale=='Arabic'? {'margin-left': '28%'} : ''">
      <!--  <v-card-title v-html="activities[current].title"></v-card-title>-->
      <v-container>
        <v-card flat>
          <v-card-text>
            <div v-if="activities[current].type == 'video'">
              <Video
                :path="activities[current].link"
                :title="activities[current].title"
                :system="activities[current].system"
                :grade="activities[current].grade"
                :subject="activities[current].subject"
                :week="activities[current].week"
                :key="activities[current].key"
              ></Video>
            </div>

            <div v-else-if="activities[current].type == 'show'">
              <iframe
                width="100%"
                height="600"
                :src="activities[current].link"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>

            <div v-else-if="activities[current].type == 'Live Session'">
              <Video
                :path="activities[current].prerecorded"
                :title="activities[current].title"
                :system="activities[current].system"
                :grade="activities[current].grade"
                :subject="activities[current].subject"
                :week="activities[current].week"
                :key="activities[current].key"
              ></Video>

              <h4>The session starts at:</h4>
              <br />
              {{ activities[current].livedate }}
              <br />
              {{ activities[current].livetime }}
              <br />
              <br />
              <b-button
                variant="success"
                size="sm"
                @click="goToLive(activities[current].livelink)"
                >Join The Video Session</b-button
              >
            </div>

            <div v-else-if="activities[current].type == 'flipped'">
              <Random
                :path="this.$route.params.path + '/questions'"
                :count="activities[current].qcount"
                :title="activities[current].title"
                :type="activities[current].type"
                color="green"
              ></Random>
            </div>
            <div v-else-if="activities[current].type == 'exit'">
              <Random
                :path="this.$route.params.path + '/questions'"
                :count="activities[current].qcount"
                :title="activities[current].title"
                :type="activities[current].type"
                color="red"
              ></Random>
            </div>

            <div
              v-else
              style="width:100%;
      height:800px;"
            >
              <LessonQuestion
                :path="activities[current].path"
                source="Lesson"
                :key="current"
                :subject="this.subject"
                :attempt="this.$route.params.attempt"
              ></LessonQuestion>
            </div>
          </v-card-text>

          <v-card-actions>
            <!-- Quiz Footer -->
            <v-footer
              dark
              padless
              fixed
              height="80px"
              style="background-color:#1d2835;"
            >
              <v-col cols="4"> </v-col>

              <v-col cols="4">
                <v-progress-linear
                  color="light-blue"
                  height="10"
                  :value="((this.current + 1) / this.activities.length) * 100"
                  striped
                ></v-progress-linear>
              </v-col>

              <!-- Next Button-->
              <v-col cols="4">
                <div v-if="this.activities.length > 0">
                  <small style="margin-right: 20px">
                    {{ this.current + 1 }} / {{ this.activities.length }}</small
                  >
                  <!-- <v-btn
                    color="primary"
                    style="margin-right: 20px"
                    rounded
                    @click="back()"
                    small
                  >
                    <v-icon>mdi-chevron-left</v-icon> Previous Step
                  </v-btn> -->
            
                  <v-btn
                    color="primary"
                    style="margin-right: 20px"
                    rounded
                    @click="next()"
                    small
                    v-if="this.current < this.activities.length - 1"
                  >
                    <v-icon>mdi-chevron-right</v-icon>{{$t("Next Step")}}
                  </v-btn>

                  <v-btn
                    v-else
                    color="green"
                    small
                    rounded
                    dark
                    @click="next()"
                    style="margin-right:40px"
                  >
                    <v-icon>mdi-chevron-right</v-icon>{{$t("Back To Week") }}
                  </v-btn>
                </div>
              </v-col>
            </v-footer>
          </v-card-actions>
          <!-- End Footer -->
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import LessonQuestion from "@/components/dna/LessonQuestion";
import Video from "@/components/dna/Video";
import Random from "@/components/lms/quiz/Random";
import router from "@/router";
import  moment  from "moment";

export default {
  name: "ListPage",
  props: ["path"],
  components: {
    LessonQuestion,
    Video,
    Random
  },
  data() {
    return {
      activities: [],
      questions: [],
      current: 0,
      firstCurrent:0,
      record: {},
      total: 0,
      attempts:0,
      rightAnswers:0,
      wrongAnswers:0,
      totalAnswers:0,
      highestRight:0,
      oldhighestRight:0,
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      week: this.$store.state.weektitle
    };
  },
  methods: {
    goToLive(link) {
      window.open(link, "_blank");
    },

    next() {
      this.attempts = 0;
      this.rightAnswers = 0;
      this.totalAnswers = 0;

      if (this.current < this.activities.length - 1) {
//&& this.activities[this.current].type == 'question'
        if( this.$store.state.answered==true ){

        console.log("questoin type: ",this.activities[this.current].type)
        console.log("question: ",this.activities[this.current])
          this.$store.state.answered=false;

          if(Number(this.$route.params.attempt) <= 3){

              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped")
              .doc(this.$store.state.weekid)
              .set({
                'start_date': moment().format('DD-MM-YY'),
                'start_time': moment().format('h:mm:ss a'),
                'attempts': Number(this.$route.params.attempt),
                'breakdown': this.$store.state?.lessonbreakdown,
                "subjectTitle": this.$store.state.subjecttitle,
                "grade": "",
                "assgrade": "",
                "score":0,
                "class": this.$store.state.sclass,
                "student":this.$store.state.email,
                "branch": this.$store.state.branch,
                "school": this.$store.state.school,
                "weektitle": this.$store.state.weektitle,
                "week": this.$store.state.weekpath,
                "gradelevel":this.$store.state.grade
              })
              .then(() => {
                console.log("data to be set: ",{
                'start_date': moment().format('DD-MM-YY'),
                'start_time': moment().format('h:mm:ss a'),
                'attempts': Number(this.$route.params.attempt),
                'breakdown': this.$store.state?.lessonbreakdown,
                "subjectTitle": this.$store.state.subjecttitle,
                "grade": "",
                "assgrade": "",
                "score":0,
                "class": this.$store.state.sclass,
                "student":this.$store.state.email,
                "branch": this.$store.state.branch,
                "school": this.$store.state.school,
                "weektitle": this.$store.state.weektitle,
                "week": this.$store.state.weekpath,
                "gradelevel":this.$store.state.grade
              })
                firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+'/attempts/'+this.$route.params.attempt)
              .set({
                'date':new Date().toISOString(),
                'attempts': Number(this.$route.params.attempt),
                'breakdown': this.$store.state?.lessonbreakdown,
                "subjectTitle": this.$store.state.subjecttitle,
                "grade": "",
                "assgrade": "",
                "score":0,
                "class": this.$store.state.sclass,
                "student":this.$store.state.email,
                "branch": this.$store.state.branch,
                "school": this.$store.state.school,
                "weektitle": this.$store.state.weektitle,
                "week": this.$store.state.weekpath,
                "gradelevel":this.$store.state.grade
              })
              .then(() => { })

              });
            }else{

                firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped")
              .doc(this.$store.state.weekid)
              .set({
                'start_date': moment().format('DD-MM-YY'),
                'start_time': moment().format('h:mm:ss a'),
                'attempts': 4,
                'breakdown': this.$store.state?.lessonbreakdown,
                "subjectTitle": this.$store.state.subjecttitle,
                "grade": "",
                "assgrade": "",
                "score":0,
                "class": this.$store.state.sclass,
                "student":this.$store.state.email,
                "branch": this.$store.state.branch,
                "school": this.$store.state.school,
                "weektitle": this.$store.state.weektitle,
                "week": this.$store.state.weekpath,
                "gradelevel":this.$store.state.grade
                })
              .then(() => {
                firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/flipped/" +this.$store.state.weekid + "/attempts/4")
              .set({
                'date':new Date().toISOString(),
                'attempts': Number(this.$route.params.attempt),
                'breakdown': this.$store.state?.lessonbreakdown,
                "subjectTitle": this.$store.state.subjecttitle,
                "grade": "",
                "assgrade": "",
                "score":0,
                "class": this.$store.state.sclass,
                "student":this.$store.state.email,
                "branch": this.$store.state.branch,
                "school": this.$store.state.school,
                "weektitle": this.$store.state.weektitle,
                "week": this.$store.state.weekpath,
                "gradelevel":this.$store.state.grade
              })
              .then(() => { })
            })
        }

    
       // } 
        this.current++;
      } else{
          this.$store.state.answered=false;
          this.current++;
          console.log("question is a ",this.activities[this.current].type,"and ",this.activities[this.current])
        }
    }
    else {

        firebase
        .firestore()
    .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
    .where("attempts","==",this.$route.params.attempt)
    .get()
    .then(querySnapshot=>{
      querySnapshot.forEach(doc=>{
        if(doc.data().answer == "Right"){
          this.rightAnswers = this.rightAnswers+1
        }
        if(doc.data().answer == "Wrong"){
          this.wrongAnswers = this.wrongAnswers+1
        }
        this.totalAnswers = this.rightAnswers + this.wrongAnswers

      })

                firebase
              .firestore()
              //.doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/attempts/"+this.attempts)
              .doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/attempts/"+this.$route.params.attempt)
              .set({
                'date':new Date().toISOString(),
                'attempts': this.$route.params.attempt,
                'breakdown': this.$store.state?.lessonbreakdown,
                "subjectTitle": this.$store.state.subjecttitle,
                'right':this.rightAnswers,
                'wrong':this.wrongAnswers,
                'highestRight':this.rightAnswers,
                'highestScore': (this.rightAnswers / this.totalAnswers)*100,
                'score':(this.rightAnswers / this.totalAnswers)*100,
                'assgrade':this.totalAnswers,
                "class": this.$store.state.sclass,
                "student":this.$store.state.email,
                "branch": this.$store.state.branch,
                "school": this.$store.state.school,
                "weektitle": this.$store.state.weektitle,
                "week": this.$store.state.weekpath,
                "gradelevel":this.$store.state.grade
              })
              .then(() => { 
 
                    firebase
                    .firestore()
                    .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/attempts")
                    .get()
                    .then((querySnapshot) => { 
                    if(querySnapshot){
                      querySnapshot.forEach(doc=>{
                        if(querySnapshot.size == 1){
                    this.oldhighestRight = doc.data()?.right;
                    this.highestRight = doc.data()?.right;
                    }else{
                    if(doc.data()?.right > this.oldhighestRight){
                    this.highestRight = doc.data()?.right;
                    this.oldhighestRight = this.highestRight;
                    }else{
                    this.highestRight = this.oldhighestRight;
                    }
                    }
                      })
                    }
                    if(querySnapshot.size <= 3){
                      firebase
                    .firestore()
                    .doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid)
                    .update({
                    'right':this.highestRight,
                    'wrong':this.totalAnswers - this.highestRight,
                    'highestRight':this.highestRight,
                    'highestScore': (this.highestRight / this.totalAnswers)*100,
                    'score':(this.highestRight / this.totalAnswers)*100,
                    "grade":this.totalAnswers,
                    "assgrade":this.totalAnswers
                    })
                    .then(() => {})
                    }
                    })
              //}
               // }
                        // TODO: Get Real Total With Fliped and Exit Questions
        this.record.totalQuestions =
          Number(this.questions.length) + Number(this.total);

        this.record.date = new Date().toLocaleString();


        this.record.subject = this.$store.state.subject;
        this.record.subject_title = this.$store.state.subjecttitle;
        this.record.type = "Lesson";
        this.record.weektitle = this.$store.state.weektitle;
        this.record.weekpath = this.$store.state.weekpath;
        this.record.weekid = this.$store.state.weekpath.split("/")[9];

        this.record.flippedright = this.$store.state.flippedrightAnswers;
        this.record.exitright = this.$store.state.exitrightAnswers;
        this.record.student = this.$store.state.id;

        firebase
          .firestore()
          .collection(this.$store.state.oraginalsubject + "/lesson")
          .doc(this.$store.state.id + this.$store.state.weekpath.split("/")[9])
          .set(this.record)
          .then(() => {
            router.replace({
              name: "activityReport",
              params: {
                path: this.$store.state.weekpath,
                title: this.$store.state.weektitle,
                attempts:this.$route.params.attempt
              }
            });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
              })
              })


      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    }
  },
  created() {
    if(this.$route.params.attempt > 3){

      firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
                .where("attempts","==",4)
                .get()
                .then((docs) => {
                  docs.forEach(el => {
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
                .doc(el.id)
                .delete()
                  })
        })
          
        firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .where("attempts","==",4)
                .get()
                .then((docs) => {
                  docs.forEach(el => {
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .doc(el.id)
                .delete()
                  })
        })
    }

    firebase
      .firestore()
      .collection(this.$route.params.path + "/lesson")
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        this.activities = [];

        querySnapshot.forEach(doc => {
          this.activities.push({
            key: doc.id,
            path: doc.ref.path,
            link: doc.data().link,
            order: doc.data().order,
            title: doc.data().title,
            type: doc.data().type,
            livelink: doc.data().livelink,
            livedate: doc.data().livedate,
            livetime: doc.data().livetime,
            qcount: doc.data().qcount,
            filetype: doc.data().filetype,
            prerecorded: doc.data().prerecorded,
            system: doc.data().system,
            grade: doc.data().grade,
            subject: doc.data().subject,
            week: doc.data().week
          });
          console.log(doc.data())
        });
      });

    firebase
      .firestore()
      .collection(this.$route.params.path + "/lesson")
      .where("type", "==", "question")
      .get()
      .then(querySnapshot => {
        this.questions = [];

        querySnapshot.forEach(doc => {
          this.questions.push({
            key: doc.id
          });
        });
      });

    firebase
      .firestore()
      .collection(this.$route.params.path + "/lesson")
      .where("type", "==", "flipped")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.total = Number(this.total) + Number(doc.data().qcount);
        });
      });
    firebase
      .firestore()
      .collection(this.$route.params.path + "/lesson")
      .where("type", "==", "exit")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.total = Number(this.total) + Number(doc.data().qcount);
        });
      });
  }
};
</script>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/exit.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">Flipped Questions</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
                <!--  <br />
                <span>{{ this.title }}</span>-->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <div
          v-if="
            this.lesson[studentclass + branch + 'flipped'] == null ||
              this.lesson[studentclass + branch + 'flipped'] == false ||
              this.thisstudent['blended'] == false
          "
          style="text-align:center"
        >
          <v-card flat outlined
            ><v-card-text>
              <img src="@/assets/waiting.png" width="40%" />

              <h5 style="padding-top:50px">
                Waiting for Flipped Questions to be Opened
              </h5>
              <br />

              <v-btn @click="onRetry" color="success" small>Retry</v-btn>
            </v-card-text>
          </v-card>
        </div>

        <div v-else>
          <div v-if="this.before.week != null">
            <v-card flat
              ><v-card-text>
                <h5 style="padding-top:50px">
                  You Already Submitted Before
                </h5>
              </v-card-text>
            </v-card>
          </div>

          <v-card class="mx-auto" v-else>
            <v-card-title
              >Question {{ this.current + 1 }}

              <v-divider />
            </v-card-title>
            <v-card-text>
              <div>
                <Question
                  :path="examquestions[arr[this.current]].path"
                  :student="
                    this.studentpath +
                      '/assignments/' +
                      this.weekid +
                      'flipped/assignmentattemps/1'
                  "
                  source="Flipped"
                  :key="current"
                  :subject="this.subject"
                ></Question>
              </div>
            </v-card-text>

            <v-card-actions>
              <!-- Quiz Footer -->
              <v-footer
                dark
                padless
                fixed
                height="80px"
                style="background-color:#1d2835;"
              >
                <v-col cols="6"> </v-col>

                <v-col cols="4">
                  <v-progress-linear
                    color="light-blue"
                    height="10"
                    :value="((this.current + 1) / this.count) * 100"
                    striped
                  ></v-progress-linear>
                </v-col>

                <!-- Next Button-->
                <v-col cols="2">
                  <div v-if="this.count > 0">
                    <small style="margin-right: 20px">
                      {{ this.current + 1 }} / {{ this.count }}</small
                    >
                    <v-btn
                      color="primary"
                      style="margin-right: 20px"
                      rounded
                      @click="next()"
                      small
                      v-if="this.current < this.count - 1"
                    >
                      <v-icon>mdi-chevron-right</v-icon>{{$t("Next Question")}}
                    </v-btn>

                    <v-btn
                      v-else
                      color="green"
                      small
                      rounded
                      dark
                      @click="onSubmit"
                      style="margin-right:40px"
                    >
                      <v-icon>mdi-chevron-right</v-icon>Submit Flipped
                    </v-btn>
                  </div>
                </v-col>
              </v-footer>
            </v-card-actions>
          </v-card>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import Question from "@/components/dna/Question";

export default {
  name: "flipped-page",
  components: { Question },
  data() {
    return {
      //Upload Image
      imageData: null,
      examquestions: [],
      battemps: [],
      arr: [],
      picture: null,
      uploadValue: 0,
      files: "",

      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      week: this.$store.state.weekpath,

      current: 0,
      count: 3,

      assign: [],
      temp: [],

      //!Ahmed Kamal
      studentclass: this.$store.state.sclass,
      branch: this.$store.state.branch,
      //!---------------------------------------

      student: this.$store.state.id,
      studentpath: this.$store.state.studentpath,

      assignment: this.$store.state.assid,

      weekpath: this.$store.state.weekpath,
      weekid: this.$store.state.weekid,

      path: this.$store.state.weekpath + "/questions",

      submit: {},
      before: {},
      lesson: {},
      thisstudent: {},
      attemps: "",
      clicked: false
    };
  },
  methods: {
    next() {
      if (this.$store.state.answered == true) {
        if (this.current < this.examquestions.length - 1) {
          this.current++;
        }
      } else {
        this.$swal(
          {
            title: "Missed Answer",
            icon: "warning",
            text: "Please Answer or Skip this Question to Continue",
            showLoaderOnConfirm: true
          }
        );
      }
    },
    onSubmit(evt) {
      if (this.clicked == false) {
        this.clicked = true;

        evt.preventDefault();
        this.submit.week = this.weekpath;
        this.submit.date = new Date().toLocaleString();
        this.submit.right = this.$store.state.rightAnswers ?? 0;
        this.submit.grade = this.$store.state.rightAnswers ?? 0;
        this.submit.wrong = this.$store.state.wrongAnswers;
        this.submit.type = "flipped";

        //! Analytics Data
        this.submit.school = this.$store.state.school;
        this.submit.branch = this.$store.state.branch ?? "";
        this.submit.gradelevel = this.grade ?? "";
        this.submit.subject = this.oraginalsubject ?? "";
        // this.submit.subject_title = this.subject;
        this.submit.student = this.student + "";

        this.submit.week = this.weekpath ?? "";
        this.submit.week_title = this.weekid ?? "";

        this.submit.class = this.$store.state.sclass ?? "";

        this.submit.missed =
          3 - (this.$store.state.rightAnswers + this.$store.state.wrongAnswers);

        firebase
          .firestore()
          .collection(this.$store.state.oraginalsubject + "/flipped")
          .doc(this.student + this.weekid)
          .set(this.submit)
          .then(() => {
            //! Add Attemp Record to students --> assignments Collection

            firebase
              .firestore()
              .doc(
                this.studentpath +
                  "/assignments/" +
                  this.weekid +
                  "flipped/assignmentattemps/1"
              )
              .set(this.submit)
              .then(() => {
                this.$bvToast.toast("Flipped Submitted", {
                  title: "Assignment Flipped Successfully",
                  variant: "success",
                  solid: true
                });

                // router.back();
                this.$store.commit(
                  "submissionpath",
                  this.studentpath +
                    "/assignments/" +
                    this.weekid +
                    "flipped/assignmentattemps/1"
                );

                //! Go to Submission Report
                router.replace({
                  name: "SubmissionReport"
                });
                this.updatePoints(this.$store.state.rightAnswers);
              });
          });
      }
    },
    onRetry() {
      firebase
        .firestore()
        .doc(this.weekpath)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) return;
          this.lesson = snapshot.data();
        });
      firebase
        .firestore()
        .doc(this.studentpath)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) return;
          this.thisstudent = snapshot.data();
        });
    },
    updatePoints(count) {
      //! increment points
      const increment = firebase.firestore.FieldValue.increment(count * 10);
      try {
        firebase
          .firestore()
          .doc(this.$store.state.studentpath)
          .update({ points: increment })
          .then(() => {})
          .catch(e => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
      //! End increment points
    }
  },
  created() {
    try {
      this.examquestions = [];

      this.$store.state.rightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.missedAnswers = 0;

      firebase
        .firestore()
        .doc(this.weekpath)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) return;
          this.lesson = snapshot.data();
        });

      firebase
        .firestore()
        .doc(this.studentpath)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) return;
          this.thisstudent = snapshot.data();
        });

      firebase
        .firestore()
        .collection(this.$store.state.oraginalsubject + "/flipped")
        .doc(this.student + this.weekid)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) return;
          this.before = snapshot.data();
        });

      //! Get Student Grade Data
      // firebase
      //   .firestore()
      //   .doc(this.student + "/assignments/" + this.assignment)
      //   .onSnapshot(querySnapshot => {
      //     this.assrecord = querySnapshot.data();
      //   });

      this.attemps =
        this.$store.state.oraginalsubject +
        "/flipped/" +
        this.student +
        this.weekid +
        "/flipped/assignmentattemps/1";

      //! Get attempts Grade Data
      firebase
        .firestore()
        .collection(this.attemps)
        .get()
        .then(querySnapshot => {
          this.battemps = [];
          querySnapshot.forEach(doc => {
            this.battemps.push({
              key: doc.id,
              path: doc.ref.path,
              right: doc.data().right,
              wrong: doc.data().wrong,
              missed: doc.data().missed,
              date: doc.data().date,
              grade: doc.data().grade,
              time: doc.data().time
            });
          });
        });
      // }

      //! Generate Flipped
      firebase
        .firestore()
        .collection(this.week + "/questions")
        .where("flipped", "==", "Yes")
        .get()
        .then(querySnapshot => {
          // if (!querySnapshot.exists) return;

          this.examquestions = [];
          querySnapshot.forEach(doc => {
            if (doc.data().exam != "Yes")
              this.examquestions.push({
                key: doc.id,
                path: doc.ref.path
              });
          });

          //! Generate Unique Random List
          this.arr = [];
          while (this.arr.length < this.count) {
            var r = Math.floor(Math.random() * this.examquestions.length);
            if (this.arr.indexOf(r) === -1) this.arr.push(r);
          }
          this.loading = true;
          //this.loading = true;
        });

      //! Create Attempt
      this.submit.week = this.weekpath;
      this.submit.date = new Date().toLocaleString();
      this.submit.right = 0;
      this.submit.wrong = 0;
      this.submit.type = "flipped";
      this.submit.missed = 0;

      firebase
        .firestore()
        .collection(this.$store.state.oraginalsubject + "/flipped")
        .doc(this.student + this.weekid)
        .set(this.submit)
        .then(() => {
          //! Add Attemp Record to students --> assignments Collection

          firebase
            .firestore()
            .doc(
              this.$store.state.oraginalsubject +
                "/flipped/" +
                this.student +
                this.weekid +
                "/flipped/assignmentattemps/1"
            )
            .set(this.submit)
            .then(() => {});
        });
    } catch (e) {
      console.log(e);
    }
  }
};
</script>

<template>
  <v-card class="mx-auto" flat>
    <v-card-title>
      <v-chip label :color="this.color" dark
        ><h6 style="margin: 10px">{{ this.title }}</h6></v-chip
      >
      <!--  {{ arr }}
      <ul>
        <li v-for="q in questions" :key="q.key">{{ q.path }}</li>
      </ul>
      {{ questions[arr[this.current]].path }}-->
      <v-spacer></v-spacer>
      <small> {{ this.current + 1 }} / {{ this.count }}</small>
      <v-btn
        color="primary"
        style="margin-left: 20px"
        rounded
        dark
        @click="next()"
        small
        v-if="this.current < this.count - 1"
      >
        <v-icon>mdi-chevron-right</v-icon> Next Question
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <div style="width: 100%;margin-bottom:50px;">
        <Question
          :path="questions[arr[this.current]].path"
          :source="this.type"
          :key="current"
          :subject="this.subject"
        ></Question>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
import Question from "@/components/dna/Question";
export default {
  name: "RandomQuiz",
  props: ["path", "count", "title", "type", "color"],
  components: {
    Question
  },
  data() {
    return {
      loading: false,
      questions: [],
      arr: [],
      current: 0,
      record: {},
      kamal: this.$store.state.school,
      unique_random_numbers: [],
      subject: this.$store.state.subjecttitle
    };
  },
  methods: {
    finish() {
      this.record.date = new Date().toLocaleString();
      this.record.right = this.$store.state.rightAnswers;
      this.record.wrong = this.$store.state.wrongAnswers;
      this.record.skipped =
        this.count -
        (this.$store.state.wrongAnswers + this.$store.state.rightAnswers);

      this.record.subject = this.$store.state.subject;
      this.record.subject_title = this.$store.state.subjecttitle;
      this.record.type = this.type;
      this.record.weektitle = this.$store.state.weektitle;
      this.record.weekpath = this.$store.state.weekpath;
      this.record.totalQuestions = this.count;

      // console.log(this.record);

      firebase
        .firestore()
        .collection(this.$store.state.studentpath + "/activities")
        .add(this.record)
        .then(() => {
          this.$swal(
            "You Finished " + this.title + " Successfully",
            "You Can now Resume Your Lesson",
            "success"
          );
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    next() {
      if (this.$store.state.answered == true) {
        if (this.current < this.count - 1) {
          this.current++;
        }
      } else {
        this.$swal(
          {
            title: "Missed Answer",
            showCancelButton: true,
            confirmButtonText: "Yes Skip",
            cancelButtonText: "Back To Question",
            icon: "warning",

            text: "Are you sure You want to Leave This Question Without Answer",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (confirm) {
                if (this.current < this.count - 1) {
                  this.current++;
                }
              }
            }
          }
        );
      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    },
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      const videoid = match && match[2].length === 11 ? match[2] : null;

      return "https://www.youtube.com/embed/" + videoid;
    }
  },
  created() {
    // this.$store.state.rightAnswers = 0;
    // this.$store.state.wrongAnswers = 0;
    //! Flipped Activity
    if (this.type == "flipped") {
      firebase
        .firestore()
        .collection(this.path)
        .where("flipped", "==", "Yes")
        .get()
        .then(querySnapshot => {
          this.questions = [];
          querySnapshot.forEach(doc => {
            if (doc.data().exam != "Yes")
              this.questions.push({
                key: doc.id,
                path: doc.ref.path
              });
          });
          //! Generate Unique Random List
          this.arr = [];
          while (this.arr.length < 3) {
            var r = Math.floor(Math.random() * this.questions.length);
            if (this.arr.indexOf(r) === -1) this.arr.push(r);
          }
        })
        .then(() => {
          this.loading = true;
        });
    }
    //! Exit Activity
    else if (this.type == "exit") {
      firebase
        .firestore()
        .collection(this.path)
        .where("exit", "==", "Yes")
        .get()
        .then(querySnapshot => {
          this.questions = [];
          querySnapshot.forEach(doc => {
            if (doc.data().exam != "Yes")
              this.questions.push({
                key: doc.id,
                path: doc.ref.path
              });
          });
          //! Generate Unique Random List
          this.arr = [];
          while (this.arr.length < 3) {
            var r = Math.floor(Math.random() * this.questions.length);
            if (this.arr.indexOf(r) === -1) this.arr.push(r);
          }
        })
        .then(() => {
          this.loading = true;
        });
    }
    //! Random Quiz Activity
    else {
      firebase
        .firestore()
        .collection(this.path)
        .get()
        .then(querySnapshot => {
          this.questions = [];
          querySnapshot.forEach(doc => {
            if (doc.data().exam != "Yes")
              this.questions.push({
                key: doc.id,
                path: doc.ref.path
              });
          });

          //! Generate Unique Random List
          this.arr = [];
          while (this.arr.length < this.count) {
            var r = Math.floor(Math.random() * this.questions.length);
            if (this.arr.indexOf(r) === -1) this.arr.push(r);
          }
        })
        .then(() => {
          this.loading = true;
        });
    }
  }
};
</script>

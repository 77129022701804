<template>
  <v-row style="direction: ltr;">
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/videoheader.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">{{$t("Progress Reports")}}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.grade }}
                <br />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-card flat :style="$i18n.locale=='Arabic'? {'text-align': 'right'} : ''">
          <v-card-text>
            <b-row>
              <b-col cols="12">
                <v-toolbar color="blue darken-1" dark>
                  <v-toolbar-title>
                    <v-icon left size="40px">mdi-finance</v-icon>{{$t("Progress")}}
                  </v-toolbar-title>
                </v-toolbar>

                <vue-good-table
                  :columns="fields"
                  :rows="progress"
                  :search-options="{ enabled: true }"
                  :pagination-options="{ enabled: true, mode: 'records' }"
                >
                  <template slot="table-row" slot-scope="props">
                    <!-- <template v-slot:cell(actions)="row"> -->

                    <span v-if="props.column.field == 'percent'"
                      >{{
                        (props.row.right /
                          (props.row.right + props.row.wrong)) *
                          100
                      }}
                      %</span
                    >

                    <span v-else-if="props.column.field == 'actions'">
                      <button
                        class="btn btn-success btn-sm"
                        @click="goToReport(props.row.path)"
                      >
                        <v-icon>mdi-card-account-details-outline</v-icon>
                      </button>
                    </span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "progressReport",
  components: {},

  data() {
    return {
      fields: [
        {
          field: this.$t("Date"),
          label: this.$t("Date"),
          sortable: true,
          class: "text-left"
        },
        {
          field: this.$t("subject"),
          label: this.$t("subject"),
          sortable: true,
          class: "text-left"
        },
        {
          field: this.$t("week"),
          label: this.$t("week"),
          sortable: true,
          class: "text-left"
        },
        {
          field: this.$t("type"),
          label:this.$t("type"),
          sortable: true,
          class: "text-left"
        },
        {
          field: this.$t("right"),
          label:this.$t("right"),
          sortable: true,
          class: "text-left"
        },
        {
          field: this.$t("wrong"),
          label: this.$t("wrong"),
          sortable: true,
          class: "text-left"
        },
        {
          field: this.$t("percent"),
          label: this.$t("percent"),
          sortable: true,
          class: "text-left"
        },
        { field: this.$t("details"), label: this.$t("details"), class: "text-center" }
      ],
      progress: [],
      errors: [],
      grade: this.$store.state.grade
    };
  },
  created() {
    firebase
      .firestore()
      .collection("profiles")
      .doc(this.$store.state.email)
      .collection("activities")
      .orderBy("date", "desc")
      .get()
      .then(querySnapshot => {
        this.progress = [];
        querySnapshot.forEach(doc => {
          this.progress.push({
            key: doc.id,
            path: doc.ref.path,
            date: doc.data().date,
            type: doc.data().type,
            week: doc.data().weektitle,
            subject: doc.data().subject_title,
            right: doc.data().right,
            wrong: doc.data().wrong
          });
        });
      });
  },
  methods: {
    goToReport(path) {
      router.push({
        name: "activityReport",
        params: { path: path }
      });
    }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>

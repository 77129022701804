<template>
  <div>
    <v-card flat outlined>
      <v-card-text>
        <fullscreen
          ref="fullscreen"
          @change="fullscreenChange"
          style="background-color:#fff;"
        >
          <v-card flat>
            <v-card-title> {{ this.$route.params.title }}</v-card-title>
            <v-card-subtitle style="color:red" v-if="fullscreen != false">
              <v-icon color="red">mdi-alert-circle-outline</v-icon>

              Any attempt to exit Fullscreen Mode will End the
              Exam</v-card-subtitle
            >
            <v-card-text>
              <!-- Waiting for Exam To Start-->

              <div>
                <a :href="this.exam.live" target="_blank"
                  >Join Live Monitoring Session</a
                >
                <div v-if="this.fullscreen">
                  <ExamPaper :path="this.$route.params.path" />
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <div
                v-if="
                  checkTime(
                    exam.startTime,
                    exam.endTime,
                    exam.opendate,
                    exam.disable
                  )
                "
              >
                Please Click Enter Exam To Start
                <v-divider />
                <v-btn
                  type="button"
                  @click="toggle"
                  color="info"
                  small
                  depressed
                  >Enter Exam</v-btn
                >
              </div>

              <div v-else>
                <h4 style="padding-top:20px">Exam Not Opened Yet</h4>
              </div>
            </v-card-actions>
          </v-card>
        </fullscreen>

        <br />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import ExamPaper from "./ExamPaper";
export default {
  name:"exam-page",
  components: { ExamPaper },
  data() {
    return {
      fullscreen: false,
      exam: {}
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.exam = snapshot.data();
      });
  },

  methods: {
    toggle() {
      this.$refs["fullscreen"].toggle(); // recommended
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    getMinutes(str) {
      var time = str.split(":");
      return time[0] * 60 + time[1] * 1;
    },

    getMinutesNow() {
      var timeNow = new Date();
      return timeNow.getHours() * 60 + timeNow.getMinutes();
    },
    checkDate(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },

    checkTime(startexam, endexam, opendate, disable) {
      // console.log(startexam + opendate);
      var now = this.getMinutesNow();
      var start = this.getMinutes(startexam);
      var end = this.getMinutes(endexam);
      if (start > end) end += this.getMinutes("24:00");
      if (
        now > start &&
        now < end &&
        new Date().getDate() == new Date(opendate).getDate() &&
        disable != true
      )
        return true;
      else return false;
    }
  }
};
</script>

<template>
  <div>
    <v-card flat>
      <!-- <v-row>
        <v-spacer />
        <v-btn
          color="error"
          style="margin-right: 20px"
          small
          depressed
          @click="skipQuestion"
          v-if="$store.state.answered == false"
          >Skip Question</v-btn
        >
        
        </v-row
      > -->

      <v-chip small>{{ question.bloom }}</v-chip>
      <v-chip color="success" small
        >Mental Power {{ bloomlist.get(question.bloom) }}</v-chip
      >

      <img
        :src="require(`@/assets/bloom/${bloomlist.get(question.bloom)}.png`)"
        width="30px"
      />




      <v-card-title>
     <Audio style="margin-left:10px" :src="this.question_sound" controls v-if="this.question_sound"></Audio>
        <div v-katex:auto v-html="this.question_body" class="fooar"></div>

        
      </v-card-title>

      <v-card-text>
        <!-- Multiple Choice -->
        <div v-if="question.qtype == 'mc' || question.qtype == null">
          <v-list-item-group>
            <!-- Options Before Answers-->
            <div v-if="$store.state.answered == false">
              <v-list-item
                class="fooar"
                v-for="option in options"
                :key="option.key"
                @click="
                  selectedAnswer = option.key;
                  selectedGrade = option.grade;
                "
              >
                <v-list-item-content>
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-chip
                      v-if="!option.sound"
                        outlined
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        color="success"
                        text-color="black"
                      >
                        {{ option.title }}</v-chip
                      >
                      <Audio style="margin-left:10px" :src="option.sound" controls  v-if="option.sound"></Audio>
                      <v-btn
                        style="height: 50px; margin-left: 10px"
                        rounded
                        x-small
                        depressed
                        color="info"
                        @click="checkAnswer(selectedGrade)"
                      >
                        <v-icon>mdi-check-all</v-icon>{{ $t("Confirm") }} </v-btn
                      >
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <v-chip
                        v-if="!option.sound"
                        color="primary"
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        outlined
                        text-color="black"
                        >{{ option.title }}</v-chip
                      >
                      <Audio style="margin-left:10px" :src="option.sound" controls  v-if="option.sound"></Audio>
                    </div>
                  </h6>
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- Options After Answers-->
            <div v-else>
              <v-list-item
                class="fooar"
                v-for="option in options"
                :key="option.key"
              >
                <v-list-item-content>
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-chip
                      v-if="!option.sound"
                        dark
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        :color="option.grade == 1 ? 'success' : 'error'"
                        text-color="white"
                      >
                        {{ option.title }}</v-chip
                      >
                      <Audio style="margin-left:10px" :src="option.sound" controls  v-if="option.sound"></Audio>
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <v-chip
                      v-if="!option.sound"
                        :color="option.grade == 1 ? 'success' : 'error'"
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        text-color="white"
                        >{{ option.title }}</v-chip
                      >
                      <Audio style="margin-left:10px" :src="option.sound" controls  v-if="option.sound"></Audio>
                    </div>
                  </h6>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-item-group>
        </div>
        <!-- Listening -->
        <div v-if="question.qtype == 'listening'">
          <v-list-item-group>
            <!-- Options Before Answers-->
            <div v-if="$store.state.answered == false">
              <v-list-item
                class="fooar"
                v-for="option in options"
                :key="option.key"
                @click="
                  selectedAnswer = option.key;
                  selectedGrade = option.grade;
                "
              >
                <v-list-item-content>
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-chip v-if="option.title"
                        outlined
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        color="success"
                        text-color="black"
                      >
                        {{ option.title }}</v-chip
                      >

                       <Audio style="margin-left:10px" :src="option.sound" controls  v-if="option.sound"></Audio>

                      <v-btn
                        style="height: 50px; margin-left: 10px"
                        rounded
                        x-small
                        depressed
                        color="info"
                        @click="checkAnswer(selectedGrade)"
                      >
                        <v-icon>mdi-check-all</v-icon> {{ $t("Confirm") }} </v-btn
                      >
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <v-chip v-if="option.title"
                        color="primary"
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        outlined
                        text-color="black"
                        >{{ option.title }}</v-chip
                      >
                      <Audio style="margin-left:10px" :src="option.sound" controls v-if="option.sound"></Audio>
                    </div>
                  </h6>
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- Options After Answers-->
            <div v-else>
              <v-list-item
                class="fooar"
                v-for="option in options"
                :key="option.key"
              >
                <v-list-item-content>
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-chip v-if="option.title"
                        dark
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        :color="option.grade == 1 ? 'success' : 'error'"
                        text-color="white"
                      >
                        {{ option.title }}</v-chip
                      >
                     <Audio style="margin-left:10px" :src="option.sound" controls  v-if="option.sound"></Audio>
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <v-chip  v-if="option.title"
                        :color="option.grade == 1 ? 'success' : 'error'"
                        style="min-width: 50%; height: 50px; margin-left: 10px"
                        text-color="white"
                        >{{ option.title }}</v-chip
                      >
                      <Audio style="margin-left:10px" :src="option.sound" controls  v-if="option.sound"></Audio>
                    </div>
                  </h6>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-item-group>
        </div>
        <!-- True/False -->
        <div v-else-if="question.qtype == 'tf'">
          <v-btn
            class="mx-2"
            fab
            dark
            x-large
            color="red"
            depressed
            @click="checkRight(question.correct, 'wrong')"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            dark
            x-large
            color="green"
            depressed
            @click="checkRight(question.correct, 'right')"
          >
            <v-icon dark> mdi-check </v-icon>
          </v-btn>
        </div>

        <!-- Complete -->
        <div v-else-if="question.qtype == 'complete'">
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="The Missing Word"
                outlined
                rounded
                v-model="missing"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" style="text-align: right">
              <v-btn
                depressed
                rounded
                color="green"
                dark
                @click="checkComplete(question.correct, missing)"
                >Check Answer</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <!-- Complete -->
        <div v-else-if="question.qtype == 'essay'">
          <v-row>
            <v-col cols="6">
              <v-textarea
                label="Write Your Answer"
                outlined
                v-model="missing"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" style="text-align: right">
              <v-btn
                depressed
                rounded
                color="green"
                dark
                @click="checkComplete(missing, path)"
                >Submit Answer</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <br />
        <br />

        <div>
          <v-alert
            :value="wrong"
            color="pink"
            dark
            border="top"
            icon="mdi-alert-circle"
            >{{ $t("Your Answer is Incorrect")  }}</v-alert
          >

          <v-alert
            :value="right"
            color="green"
            dark
            border="top"
            icon="mdi-checkbox-marked-circle-outline"
            >{{ $t("Your Answer is Correct")  }}</v-alert
          >
        </div>
        <!-- Answer Feedback -->
        <div
          v-if="this.right == true || this.wrong == true"
          style="text-align: center"
        >
          <!--  <v-alert
            text
            dense
            color="teal"
            icon="mdi-clock-fast"
            border="left"
            v-html="question.answer"
            class="foo"
          >-->

          <div v-katex:auto v-html="question_answer" class="fooar"></div>
          <!--</v-alert>-->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "Qustion-Card",
  props: ["path", "source", "student", "subject","attempt"],

  data() {
    return {
      missing: "",
      selectedAnswer: "",
      selectedGrade: "",
      questionID: "",
      questionAttempts:this.attempt,
      wrong: false,
      right: false,
      question: {},
      missed: false,
      options: [],
      answer: [],
      refOptions: firebase.firestore().collection(this.path + "/options/"),
      ref: firebase.firestore().doc(this.path),
      studentpath: this.$store.state.studentpath,
      question_body: "",
      question_sound: "",
      question_answer: "",
      bloomlist: new Map([
        ["Knowledge (Remembering)", 1],
        ["Comprehension (Understanding)", 2],
        ["Application (Transferring)", 4],
        ["Analysis (Relating)", 8],
        ["Evaluation (Judging)", 16],
        ["Synthesis (Creating)", 32]
      ])
    };
  },

  created() {
    this.$store.state.answered = false;

    this.ref.get().then((doc) => {
      if (doc.exists) {
        this.question = doc.data();
        this.questionID = doc.id;
        this.question_sound = doc.data().sound;

        firebase
          .firestore()
          .doc(this.path + "/details/details")
          .get()
          .then((querySnapshot) => {
            this.question_body = querySnapshot.data().body;
            
            this.question_answer = querySnapshot.data().answer;
          });
      } else {
        // alert("No such document!");
      }
    });

    this.refOptions.get().then((querySnapshot) => {
      this.options = [];
      querySnapshot.forEach((doc) => {
        this.options.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          grade: doc.data().grade,
          sound: doc.data().sound,
        });
      });
    });

  },

  methods: {
    skipQuestion() {
      if (this.$store.state.answered == false) {
        var today = new Date();

        this.$store.state.answered = true;

        this.answer.qtype = "mc";
        this.answer.difficulty =
          this.question.difficulty == null ? "" : this.question.difficulty;
        this.answer.question = this.path;
        this.answer.class = this.$store.state.sclass;
        this.answer.bloom =
          this.question.bloom == null ? "" : this.question.bloom;
        this.answer.dok = this.question.dok == null ? "" : this.question.dok;
        this.answer.standard =
          this.question.standard == null ? "" : this.question.standard;

        this.answer.date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        this.answer.time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        this.answer.subjectPath = this.$store.state.subject;
        this.answer.subjectTitle = this.$store.state.subjecttitle;
        this.answer.topic = this.$store.state.weektitle;
        this.answer.topicPath = this.$store.state.weekpath;
        this.answer.grade = this.$store.state.grade;
        this.answer.class = this.$store.state.sclass;
        this.answer.educationPath = this.$store.state.educationPath;
        this.answer.source = this.source;
        this.answer.studentId = this.$store.state.id;
        this.answer.branch = this.$store.state.branch;
        this.answer.school = this.$store.state.school;
        this.answer.answer = "Missed";
        this.$store.state.missedAnswers++;
        firebase
          .firestore()
          .collection(this.studentpath + "/answers")
          .doc(this.questionID)
          .set({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            this.$swal({
              icon: "info",
              title: "Question Skipped",
              html: "You Can Continue now",
              timer: 2000,
            });
            // firebase
            //   .firestore()
            //   .collection(this.student + "/questions")
            //   .doc(this.questionID)
            //   .set({ ...this.answer })
            //   .then(() => {
            //     this.missed = true;
            //   });
          })
          .catch(() => {});
      }
    },

    //! Multiple Choice Qoustions
    checkAnswer(grade) {

        if (this.$store.state.answered == false) {
          var today = new Date();

          this.$store.state.answered = true;

          this.answer.qtype = "mc";
          this.answer.difficulty =
            this.question.difficulty == null ? "" : this.question.difficulty;
          this.answer.question = this.path;
          this.answer.class = this.$store.state.sclass;
          this.answer.bloom =
            this.question.bloom == null ? "" : this.question.bloom;
          this.answer.dok = this.question.dok == null ? "" : this.question.dok;
          this.answer.attempts = this.questionAttempts;
          this.answer.date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
          this.answer.time =
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds();
          this.answer.subjectPath = this.$store.state.subject;
          this.answer.subjectTitle = this.$store.state.subjecttitle;
          this.answer.topic = this.$store.state.weektitle;
          this.answer.topicPath = this.$store.state.weekpath;
          this.answer.grade = this.$store.state.grade;
          this.answer.class = this.$store.state.sclass;
          this.answer.educationPath = this.$store.state.educationPath;
          this.answer.source = this.source;
          this.answer.studentId = this.$store.state.email;
          this.answer.branch = this.$store.state.branch;
          this.answer.school = this.$store.state.school;

          if (this.right == false && this.wrong == false) {
            if (grade == "1") {
              //Add the record to student answers Collection

              this.answer.answer = "Right";

              this.right = true;
              this.wrong = false;
              this.$store.state.rightAnswers++;

              this.$swal({
                icon: "success",
                title: this.$t("Right Answer"),
                html: this.$t("Your Answer is Correct"),
                timer: 2000,
              });
            } else {
              //Add the record to student answers Collection

              this.answer.answer = "Wrong";

              this.right = false;
              this.wrong = true;
              this.$store.state.wrongAnswers++;

              this.$swal({
                icon: "error",
                title: this.$t("Wrong Answer"),
                html: this.$t("Your Answer is Incorrect"),
                timer: 2000,
              });
            }
            console.log("mcq question")
    // firebase
    // .firestore()
    // .doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid)
    // .get()
    // .then(querySnapshot=>{
    //   if(querySnapshot.data()){
    //     this.answer.attempts = querySnapshot.data().attempts;
    //     this.questionAttempts = querySnapshot.data().attempts;
    //   }else{
    //     this.answer.attempts = 1;
    //     this.questionAttempts = 1;
    //   }
      if(Number(this.questionAttempts) <= 3){
        firebase
              .firestore()
              .collection(this.$store.state.subject + "/slink")
              .where("source", "==", this.path)
              .get()
              .then((standards) => {
                standards.forEach((standard) => {
                  var right = 0;
                  var wrong = 0;

                  if (this.answer.answer == "Right") {
                    wrong = firebase.firestore.FieldValue.increment(0);
                    right = firebase.firestore.FieldValue.increment(1);
                  } else {
                    wrong = firebase.firestore.FieldValue.increment(1);
                    right = firebase.firestore.FieldValue.increment(0);
                  }

                  firebase
                    .firestore()
                    .collection(this.$store.state.studentpath + "/flipped")
                    .doc(this.$store.state.weekid)
                    .collection("weakness")
                    .doc(standard.data().standard)
                    .set(
                      {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: this.questionAttempts,
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle,
                      }
                    )
                    .then(() => {
                      //! Add Question to student --> assignment Collection
                      firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                    })
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .where("standard","==",standard.data().standard)
                .where("attempts","==",this.questionAttempts)
                .get()
                .then((querySnapshot) => {
              if(querySnapshot.empty){
                console.log("empty database")
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .add(
                  {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: Number(this.questionAttempts),
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                  }else{
                    querySnapshot.forEach(el => {
                      console.log("exist database", el.id)
                      firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .doc(el.id)
                .update(
                  {
                    right: right,
                    wrong: wrong,
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                    })
                  }
                })
                    // firebase
                    // .firestore()
                    // .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                    // .add(
                    //   {
                    //     standard: standard.data().standard,
                    //     parent: standard.data().parent,
                    //     attempts: this.questionAttempts,
                    //     right: right,
                    //     wrong: wrong,
                    //     student: this.$store.state.email,
                    //     subjectPath: this.$store.state.subject,
                    //     subjectTitle: this.$store.state.subjecttitle,
                    //   }
                    // )
                    // .then(() => {})
                });
              });

            //? Kamal Record Answers
            firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped")
              .doc(this.$store.state.weekid)
              .collection("answers")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {
                //! Add Question to student --> assignment Collection
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
              .add({ ...this.answer })
              .then(() => {
               console.log("answer data: ",this.answer)
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })  
      }else{
        this.answer.attempts = 4;
        firebase
              .firestore()
              .collection(this.$store.state.subject + "/slink")
              .where("source", "==", this.path)
              .get()
              .then((standards) => {
                standards.forEach((standard) => {
                  //? Kamal Record Weeknees

                  var right = 0;
                  var wrong = 0;

                  if (this.answer.answer == "Right") {
                    wrong = firebase.firestore.FieldValue.increment(0);
                    right = firebase.firestore.FieldValue.increment(1);
                  } else {
                    wrong = firebase.firestore.FieldValue.increment(1);
                    right = firebase.firestore.FieldValue.increment(0);
                  }

                  firebase
                    .firestore()
                    .collection(this.$store.state.studentpath + "/flipped")
                    .doc(this.$store.state.weekid)
                    .collection("weakness")
                    .doc(standard.data().standard)
                    .set(
                      {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: 4,
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle,
                      }
                    )
                    .then(() => {
                      //! Add Question to student --> assignment Collection
                      firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                    })
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .where("standard","==",standard.data().standard)
                .where("attempts","==",4)
                .get()
                .then((querySnapshot) => {
              if(querySnapshot.empty){
                console.log("empty database")
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .add(
                  {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: 4,
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                  }else{
                    querySnapshot.forEach(el => {
                      console.log("exist database", el.id)
                      firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .doc(el.id)
                .update(
                  {
                    right: right,
                    wrong: wrong,
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                    })
                  }
                })
                });
              });

            firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped")
              .doc(this.$store.state.weekid)
              .collection("answers")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {
                //! Add Question to student --> assignment Collection
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
              .add({ ...this.answer })
              .then(() => {
                //! Add Question to student --> assignment Collection
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })  
      }
   // })

          }
        }

    },

    //! Complete Qoustions
    checkComplete(answer, question) {
      if (this.$store.state.answered == false) {
        this.$store.state.inclasssanswers.push({ answer, question });
      }

      this.$store.state.answered = true;

      // var today = new Date();
      // this.$store.state.answered = true;

      // //Add the record to student answers Collection
      // this.answer.qtype = "Easy";
      // this.answer.difficulty =
      //   this.question.difficulty == null ? "" : this.question.difficulty;
      // this.answer.question = this.path;
      // this.answer.class = this.$store.state.sclass;
      // this.answer.bloom =
      //   this.question.bloom == null ? "" : this.question.bloom;
      // this.answer.dok = this.question.dok == null ? "" : this.question.dok;
      // this.answer.standard =
      //   this.question.standard == null ? "" : this.question.standard;

      // this.answer.date =
      //   today.getFullYear() +
      //   "-" +
      //   (today.getMonth() + 1) +
      //   "-" +
      //   today.getDate();
      // this.answer.time =
      //   today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      // this.answer.subjectPath = this.$store.state.subject;
      // this.answer.subjectTitle = this.$store.state.subjecttitle;
      // this.answer.topic = this.$store.state.weektitle;
      // this.answer.topicPath = this.$store.state.weekpath;
      // this.answer.grade = this.$store.state.grade;
      // this.answer.class = this.$store.state.sclass;
      // this.answer.educationPath = this.$store.state.educationPath;
      // this.answer.source = this.source;
      // this.answer.studentId = this.$store.state.id;
      // this.answer.branch = localStorage.getItem("branch");
      // this.answer.school = this.$store.state.school;
      // //End of answers Collection

      // if (answer.trim().toLowerCase() == action.trim().toLowerCase()) {
      //   this.answer.answer = "Right";
      //   this.right = true;
      //   this.wrong = false;
      //   this.$store.state.rightAnswers++;
      //   this.$store.state.lessonrightAnswers++;
      // } else {
      //   //Add the record to student answers Collection

      //   this.answer.answer = "Wrong";

      //   this.right = false;
      //   this.wrong = true;
      //   this.$store.state.wrongAnswers++;
      //   this.$store.state.lessonwrongAnswers++;
      // }

      // firebase
      //   .firestore()
      //   .collection(this.studentpath + "/flipped/"+this.$store.state)
      //   .doc(this.questionID)
      //   .set({ ...this.answer })
      //   .then(() => {
      //     //! Add Question to student --> assignment Collection
      //     firebase
      //       .firestore()
      //       .collection(this.student + "/questions")
      //       .doc(this.questionID)
      //       .set({ ...this.answer })
      //       .then(() => {});
      //   })
      //   .catch(() => {});
    },

    updatePoints() {
      //! increment points
      const increment = firebase.firestore.FieldValue.increment(1);
      try {
        firebase
          .firestore()
          .doc(this.$store.state.studentpath)
          .update({ points: increment })
          .then(() => {})
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
      //! End increment points
    },

    //! True/ False Qoustions
    checkRight(answer, action) {
      if (this.$store.state.answered == false) {
        var today = new Date();

        this.$store.state.answered = true;
        //Add the record to student answers Collection
        this.answer.qtype = "True/ False";
        this.answer.difficulty =
          this.question.difficulty == null ? "" : this.question.difficulty;
        this.answer.question = this.path;
        this.answer.class = this.$store.state.sclass;
        this.answer.bloom =
          this.question.bloom == null ? "" : this.question.bloom;
        this.answer.dok = this.question.dok == null ? "" : this.question.dok;
        this.answer.attempts = this.questionAttempts;
        this.answer.date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        this.answer.time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        this.answer.subjectPath = this.$store.state.subject;
        this.answer.subjectTitle = this.$store.state.subjecttitle;
        this.answer.topic = this.$store.state.weektitle;
        this.answer.topicPath = this.$store.state.weekpath;
        this.answer.grade = this.$store.state.grade;
        this.answer.class = this.$store.state.sclass;
        this.answer.educationPath = this.$store.state.educationPath;
        this.answer.source = this.source;
        this.answer.studentId = this.$store.state.id;
        this.answer.branch = this.$store.state.branch;
        this.answer.school = this.$store.state.school;
        //End of answers Collection

        if (
          (answer == "true" && action == "right") ||
          (answer == "false" && action == "wrong")
        ) {
          this.answer.answer = "Right";
          this.right = true;
          this.wrong = false;
          this.$store.state.rightAnswers++;
          this.$store.state.lessonrightAnswers++;

          if (this.source == "flipped") {
            this.$store.state.flippedrightAnswers++;
          } else if (this.source == "exit") {
            this.$store.state.exitrightAnswers++;
          }

          this.$swal({
            icon: "success",
            title: this.$t("Right Answer"),
            html: this.$t("Your Answer is Correct"),
            timer: 2000,
          });
        } else {
          this.answer.answer = "Wrong";

          this.right = false;
          this.wrong = true;
          this.$store.state.wrongAnswers++;
          this.$store.state.lessonwrongAnswers++;

          this.$swal({
            icon: "error",
            title: "Wrong Answer",
            html: this.$t("Your Answer is Incorrect"),
            timer: 2000,
          });
        }
    //     firebase
    // .firestore()
    // .doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid)
    // .get()
    // .then(querySnapshot=>{
    //   if(querySnapshot.data()){
    //     this.answer.attempts = querySnapshot.data().attempts;
    //     this.questionAttempts = querySnapshot.data().attempts;
    //   }else{
    //     this.answer.attempts = 1;
    //     this.questionAttempts = 1;
    //   }
      if(Number(this.questionAttempts) <= 3){
        firebase
              .firestore()
              .collection(this.$store.state.subject + "/slink")
              .where("source", "==", this.path)
              .get()
              .then((standards) => {
                standards.forEach((standard) => {
                  var right = 0;
                  var wrong = 0;

                  if (this.answer.answer == "Right") {
                    wrong = firebase.firestore.FieldValue.increment(0);
                    right = firebase.firestore.FieldValue.increment(1);
                  } else {
                    wrong = firebase.firestore.FieldValue.increment(1);
                    right = firebase.firestore.FieldValue.increment(0);
                  }

                  firebase
                    .firestore()
                    .collection(this.$store.state.studentpath + "/flipped")
                    .doc(this.$store.state.weekid)
                    .collection("weakness")
                    .doc(standard.data().standard)
                    .set(
                      {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: this.questionAttempts,
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle,
                      }
                    )
                    .then(() => {
                      //! Add Question to student --> assignment Collection
                      firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                    })
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .where("standard","==",standard.data().standard)
                .where("attempts","==",this.questionAttempts)
                .get()
                .then((querySnapshot) => {
              if(querySnapshot.empty){
                console.log("empty database")
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .add(
                  {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: Number(this.questionAttempts),
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                  }else{
                    querySnapshot.forEach(el => {
                      console.log("exist database", el.id)
                      firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .doc(el.id)
                .update(
                  {
                    right: right,
                    wrong: wrong,
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                    })
                  }
                })
                });
              });

            //? Kamal Record Answers
            firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped")
              .doc(this.$store.state.weekid)
              .collection("answers")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {
                //! Add Question to student --> assignment Collection
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
              .add({ ...this.answer })
              .then(() => {
               console.log("answer to be put",this.answer)
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })  
      }else{
        this.answer.attempts = 4;
        firebase
              .firestore()
              .collection(this.$store.state.subject + "/slink")
              .where("source", "==", this.path)
              .get()
              .then((standards) => {
                standards.forEach((standard) => {
                  //? Kamal Record Weeknees

                  var right = 0;
                  var wrong = 0;

                  if (this.answer.answer == "Right") {
                    wrong = firebase.firestore.FieldValue.increment(0);
                    right = firebase.firestore.FieldValue.increment(1);
                  } else {
                    wrong = firebase.firestore.FieldValue.increment(1);
                    right = firebase.firestore.FieldValue.increment(0);
                  }

                  firebase
                    .firestore()
                    .collection(this.$store.state.studentpath + "/flipped")
                    .doc(this.$store.state.weekid)
                    .collection("weakness")
                    .doc(standard.data().standard)
                    .set(
                      {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: 4,
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle,
                      }
                    )
                    .then(() => {
                      //! Add Question to student --> assignment Collection
                      firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                    })
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .where("standard","==",standard.data().standard)
                .where("attempts","==",4)
                .get()
                .then((querySnapshot) => {
              if(querySnapshot.empty){
                console.log("empty database")
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .add(
                  {
                        standard: standard.data().standard,
                        parent: standard.data().parent,
                        attempts: 4,
                        right: right,
                        wrong: wrong,
                        student: this.$store.state.email,
                        subjectPath: this.$store.state.subject,
                        subjectTitle: this.$store.state.subjecttitle
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                  }else{
                    querySnapshot.forEach(el => {
                      console.log("exist database", el.id)
                      firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
                .doc(el.id)
                .update(
                  {
                    right: right,
                    wrong: wrong,
                  }
                )
                .then(() => {
                  firebase
                        .firestore()
                        .collection(this.$store.state.studentpath + "/assignments")
                        .doc(this.questionID)
                        .set({ ...this.answer })
                        .then(() => {});
                })
                    })
                  }
                })
                });
              });

            firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped")
              .doc(this.$store.state.weekid)
              .collection("answers")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {
                //! Add Question to student --> assignment Collection
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
              .add({ ...this.answer })
              .then(() => {
                //! Add Question to student --> assignment Collection
                firebase
                  .firestore()
                  .collection(this.$store.state.studentpath + "/assignments")
                  .doc(this.questionID)
                  .set({ ...this.answer })
                  .then(() => {});
              })  
      }
        // firebase
        //   .firestore()
        //   .collection(this.$store.state.subject + "/slink")
        //   .where("source", "==", this.path)
        //   .get()
        //   .then((standards) => {
        //     standards.forEach((standard) => {
        //       //? Kamal Record Weeknees

        //       var right = 0;
        //       var wrong = 0;

        //       if (this.answer.answer == "Right") {
        //         wrong = firebase.firestore.FieldValue.increment(0);
        //         right = firebase.firestore.FieldValue.increment(1);
        //       } else {
        //         wrong = firebase.firestore.FieldValue.increment(1);
        //         right = firebase.firestore.FieldValue.increment(0);
        //       }
        //       firebase
        //         .firestore()
        //         .collection(this.$store.state.studentpath + "/flipped")
        //         .doc(this.$store.state.weekid)
        //         .collection("weakness")
        //         .doc(standard.data().standard)
        //         .set(
        //           {
        //             standard: standard.data().standard,
        //             parent: standard.data().parent,
        //             attempts: this.questionAttempts,
        //             right: right,
        //             wrong: wrong,
        //             student: this.$store.state.email,
        //             subjectPath: this.$store.state.subject,
        //             subjectTitle: this.$store.state.subjecttitle,
        //           }
        //         )
        //         .then(() => {
        //           //! Add Question to student --> assignment Collection
        //           firebase
        //             .firestore()
        //             .collection(this.$store.state.studentpath + "/assignments")
        //             .doc(this.questionID)
        //             .set({ ...this.answer })
        //             .then(() => {});
        //         })
        //         firebase
        //             .firestore()
        //             .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/weakness_new")
        //             .add(
        //               {
        //                 standard: standard.data().standard,
        //             parent: standard.data().parent,
        //             attempts: this.questionAttempts,
        //             right: right,
        //             wrong: wrong,
        //             student: this.$store.state.email,
        //             subjectPath: this.$store.state.subject,
        //             subjectTitle: this.$store.state.subjecttitle,
        //               }
        //             )
        //             .then(() => {})
                
        //     });
        //   });

        // firebase
        //   .firestore()
        //   .collection(this.$store.state.studentpath + "/flipped")
        //   .doc(this.$store.state.weekid)
        //   .collection("answers")
        //   .doc(this.questionID)
        //   .set({ ...this.answer })
        //   .then(() => {
        //     //! Add Question to student --> assignment Collection
        //     firebase
        //       .firestore()
        //       .collection(this.$store.state.studentpath + "/assignments")
        //       .doc(this.questionID)
        //       .set({ ...this.answer })
        //       .then(() => {});
        //   })
        //   firebase
        //       .firestore()
        //       .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/answers_new")
        //       .add({ ...this.answer })
        //       .then(() => {
        //         //! Add Question to student --> assignment Collection
        //         firebase
        //           .firestore()
        //           .collection(this.$store.state.studentpath + "/assignments")
        //           .doc(this.questionID)
        //           .set({ ...this.answer })
        //           .then(() => {});
        //       })
      
    //})
       
      }
    },
  },
};
</script>

<style scoped>
  .fooar >>> img {
    width: 400px;
  }
  .fooar {
    text-align: right;
    direction: rtl;
    width: 100%;
  }
  .foo >>> img {
    width: 400px;
  }
  </style>

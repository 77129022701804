<template>
  <v-row style="direction: ltr;">
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/examside.jpg?alt=media&token=08792009-7398-4b89-b61b-33a9a1095ee8"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">{{ this.assign.weight }}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
                <br />
                <span>{{ this.title }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-sheet v-if="loading == false" color="darken-2" class="pa-3">
          <v-skeleton-loader
            v-bind="attrs"
            type="article, actions"
          ></v-skeleton-loader>
        </v-sheet>

        <v-card flat v-else>
          <v-card-text>
            <!--Fixed Questions -->
            <div v-if="assign.quiztype == 'Fixed Questions'">
              <div v-if="this.examquestions.length > 0">
                <v-card flat height="1000px">
                  <v-card-text>
                    <div style="width: 100%; height: 600px">
                      <h4 style="color:#0fa1e8">
                        <b>{{$t("Question") +" "+  ($i18n.locale=='Arabic' ?(this.current + 1 ).toLocaleString('ar-EG') : (this.current + 1))}}</b>
                      </h4>

                      <Question
                        :path="examquestions[this.current]"
                        :attempts="this.thisattemp"
                        :totalAttempts="this.assign.attempts"
                        :student="
                          this.student +
                            '/assignments/' +
                            this.assign.key +
                            '/assignmentattemps/' +
                            this.thisattemp
                        "
                        :source="this.assign.type"
                        :key="current"
                        :subject="this.subject"
                      ></Question>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>

            <!--Random Questions -->
            <div v-else>
              <div style="width: 100%;margin-bottom:50px;">
                <h4 style="color:#0fa1e8">
                  <b> Question {{ this.current + 1 }}</b>
                </h4>

                <Question
                  :path="examquestions[arr[this.current]].path"
                  :attempts="this.thisattemp"
                  :totalAttempts="this.assign.attempts"
                  :student="
                    this.student +
                      '/assignments/' +
                      this.assign.key +
                      '/assignmentattemps/' +
                      this.thisattemp
                  "
                  :source="this.assign.type"
                  :key="current"
                  :subject="this.subject"
                ></Question>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <!-- Quiz Footer -->
            <v-footer
              dark
              padless
              fixed
              height="80px"
              style="background-color:#1d2835;"
            >
              <v-col cols="6">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="cursor: pointer;" v-bind="attrs" v-on="on">
                      <img
                        src="@/assets/alarm.png"
                        width="40px"
                        style="margin-left:90px; "
                      />
                      <span style="margin-left:10px; font-size:small;"
                        >Teacher Note</span
                      >
                    </div>
                  </template>

                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      <img
                        src="@/assets/alarm.png"
                        width="50px"
                        style="margin-right:20px; "
                      />
                      Teacher Note
                    </v-card-title>

                    <v-card-text>
                      <br />
                      <audio
                        controls
                        v-if="this.assign.note"
                        style="width:100%; "
                        title="  Teacher Note"
                      >
                        <source :src="this.assign.note" type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">
                        Understood
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col cols="4">
                <v-progress-linear
                  color="light-blue"
                  height="10"
                  :value="((this.current + 1) / this.count) * 100"
                  striped
                ></v-progress-linear>
              </v-col>

              <!-- Next Button-->
              <v-col cols="2">
                <div v-if="this.count > 0">
                  <small style="margin-right: 20px">
                    {{ this.current + 1 }} / {{ this.count }}</small
                  >
                  <v-btn
                    color="primary"
                    style="margin-right: 20px"
                    rounded
                    @click="next()"
                    small
                    v-if="this.current < this.count - 1"
                  >
                    <v-icon>mdi-chevron-right</v-icon>{{$t("Next Question")}}
                  </v-btn>

                  <v-btn
                    v-else
                    color="green"
                    small
                    rounded
                    dark
                    @click="onSubmit"
                    style="margin-right:40px"
                  >
                    <v-icon>mdi-chevron-right</v-icon>Submit Quiz
                  </v-btn>
                </div>
              </v-col>
            </v-footer>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import Question from "@/components/dna/Question";
import moment from "moment";
export default {
  components: { Question },
  props: ["attempts", "thisattemp", "title","quiz","assAttempts","assignm"],

  data() {
    return {
      arr: [],
      dialog: false,
      hidden: false,
      assign: [],
      asspath: this.$store.state.asspath,
      student: this.$store.state.studentpath,
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      week: this.$store.state.weekpath,
      id: this.$store.state.id,
      highestRight:0,
      oldhighestRight:0,
      rightAnswers:0,
      totalAnswers:0,
      submit: {},

      current: 0,
      count: 5,

      exam: {},
      qmatrix: [],
      examquestions: [],
      loading: false,
      finished: false
    };
  },
  methods: {
    next() {
      if (this.$store.state.answered == true) {
        if (this.current < this.examquestions.length - 1) {
          if(this.current == 0){
          //first question solved
          firebase
            .firestore()
            .collection(this.$store.state.oraginalsubject + "/submissions")
            .doc(this.$store.state.id + this.assign.key)
            .set({
              week: this.assign.week,
        asspath: this.assign.path,
        assid: this.assign.key,
        assgrade: this.assign.grade,
       weight: this.assign.weight,
        weightcode: this.assign.weightcode,
       grade: this.assign.grade,
        title: this.assign.title,
        breakdown: this.$store.state?.lessonbreakdown,
        date: new Date().toLocaleString(),
       right: this.$store.state.rightAnswers,
        wrong: this.$store.state.wrongAnswers,
        attempts: this.thisattemp,
        missed: this.$store.state.missedAnswers,
       school: this.$store.state.asspath.split("/")[1],
        branch: this.$store.state.branch,
        gradelevel: this.$store.state.asspath.split("/")[5],
        subject: this.$store.state.asspath.split("/")[7],
       class: this.$store.state.sclass,
       score:(this.$store.state.rightAnswers / this.assign.count) * 100,
      type: this.assign.type,
        weektitle: this.$store.state.weektitle,
        student: this.id
            })
            .then(() => {
              //! Add Attemp Record to students --> assignments Collection
              firebase
                .firestore()
                .doc(
                  this.$store.state.oraginalsubject +
                    "/submissions/" +
                    this.$store.state.email +
                    this.assign.key
                )
                .set({              week: this.assign.week,
        asspath: this.assign.path,
        assid: this.assign.key,
        assgrade: this.assign.grade,
       weight: this.assign.weight,
        weightcode: this.assign.weightcode,
       grade: this.assign.grade,
        title: this.assign.title,
        date: new Date().toLocaleString(),
       right: this.$store.state.rightAnswers,
        wrong: this.$store.state.wrongAnswers,
        breakdown:this.$store.state?.lessonbreakdown,
        attempts: this.thisattemp,
        missed: this.$store.state.missedAnswers,
       school: this.$store.state.asspath.split("/")[1],
        branch: this.$store.state.branch,
        gradelevel: this.$store.state.asspath.split("/")[5],
        subject: this.$store.state.asspath.split("/")[7],
       class: this.$store.state.sclass,
       score:(this.$store.state.rightAnswers / this.assign.count) * 100,
      type: this.assign.type,
        weektitle: this.$store.state.weektitle,
        student: this.id})
                .then(() => {
                })
                .catch(() => {});
            });
          if(this.assAttempts <= Number(this.assignm.attempts)){
            console.log("this.assAttempts:",this.assAttempts)
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/quizes")
              .doc(this.$store.state.assid)
              .set({
                start_date: moment().format("DD-MM-YY"),
                start_time: moment().format("h:mm:ss a"),
                attempts: this.assAttempts,
                highestScore:"",
                highestRight:""
              })
              .then(() => {
                firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+'/attempts/'+this.assAttempts)
              .set({
                assid: this.$store.state.assid,
                asspath:this.asspath,
                assgrade:this.assignm.grade,
                date:moment().toISOString(),
                attempts: this.assAttempts,
                breakdown: this.$store.state?.lessonbreakdown,
                subjectTitle: this.$store.state.subjecttitle,
                class: this.$store.state.sclass,
                student:this.$store.state.email,
                branch: this.$store.state.branch,
                school: this.$store.state.school,
                weektitle: this.$store.state.weektitle,
                week: this.$store.state.weekpath,
                gradelevel:this.$store.state.grade,
                grade:this.assignm.grade,
                weight:this.$store.state.weighttitle,
                weightcode:this.$store.state.weightcode,
                missed:0,
                highestScore:0,
                highestRight:0,
                score:"",
                type:this.$store.state.asstype
              })
              .then(() => {
 
              })

              });
            }else{
              let att = Number(this.assignm.attempts) + 1
              console.log("att:",att)
              firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/quizes")
              .doc(this.$store.state.assid)
              .set({
                start_date: moment().format("DD-MM-YY"),
                start_time: moment().format("h:mm:ss a"),
                attempts: att,
                highestScore:"",
                highestRight:""
              })
              .then(() => {
                firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+'/attempts/'+att)
              .set({
                assid: this.$store.state.assid,
                asspath:this.asspath,
                assgrade:this.assignm.grade,
                date:moment().toISOString(),
                attempts: att,
                breakdown: this.$store.state?.lessonbreakdown,
                subjectTitle: this.$store.state.subjecttitle,
                class: this.$store.state.sclass,
                student:this.$store.state.email,
                branch: this.$store.state.branch,
                school: this.$store.state.school,
                weektitle: this.$store.state.weektitle,
                week: this.$store.state.weekpath,
                gradelevel:this.$store.state.grade,
                grade:this.assignm.grade,
                weight:this.$store.state.weighttitle,
                weightcode:this.$store.state.weightcode,
                missed:0,
                highestScore:0,
                highestRight:0,
                score:"",
                type:this.$store.state.asstype
              })
              .then(() => {
              })

              });
            }
        }
          this.current++;
        }
      } else {
        this.$swal(
          {
            title: "Missed Answer",
            icon: "warning",
            text: "Please Answer or Skip this Question to Continue",
            showLoaderOnConfirm: true
          }
        );
      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    },

    updatePoints(count) {
      //! increment points
      const increment = firebase.firestore.FieldValue.increment(count);
      try {
        firebase
          .firestore()
          .doc(this.$store.state.studentpath)
          .update({ points: increment })
          .then(() => {})
          .catch(e => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
      //! End increment points
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.finished == false) {
        this.submit.week = this.assign.week;
        this.submit.asspath = this.assign.path;
        this.submit.assid = this.assign.key;
        this.submit.assgrade = this.assign.grade;
        this.submit.weight = this.assign.weight;
        this.submit.weightcode = this.assign.weightcode;
        this.submit.grade = this.assign.grade;
        this.submit.title = this.assign.title;
        this.submit.date = new Date().toLocaleString();
        this.submit.right = this.$store.state.rightAnswers;
        this.submit.wrong = this.$store.state.wrongAnswers;
        this.submit.breakdown=this.$store.state?.lessonbreakdown;
        this.submit.attempts = this.thisattemp;

        this.submit.missed = this.$store.state.missedAnswers;

        //! Analytics Data
        this.submit.school = this.$store.state.asspath.split("/")[1];
        this.submit.branch = this.$store.state.branch;
        this.submit.gradelevel = this.$store.state.asspath.split("/")[5];
        this.submit.subject = this.$store.state.asspath.split("/")[7];
        this.submit.class = this.$store.state.sclass;

        this.submit.score =
          (this.$store.state.rightAnswers / this.assign.count) * 100;

        this.submit.type = this.assign.type;
        this.submit.subject = this.$store.state.subject;
        this.submit.weektitle = this.$store.state.weektitle;
        this.submit.student = this.id;

        if (this.$store.state.answered == true) {
          //! Update Assignment Doc in students --> assignments Collection
          firebase
            .firestore()
            .collection(this.$store.state.oraginalsubject + "/submissions")
            .doc(this.$store.state.id + this.assign.key)

            .set(this.submit)
            .then(() => {
              //! Add Attemp Record to students --> assignments Collection

              firebase
                .firestore()
                .doc(
                  this.$store.state.oraginalsubject +
                    "/submissions/" +
                    this.$store.state.email +
                    this.assign.key
                )
                .set(this.submit)
                .then(() => {
                  this.$bvToast.toast("Assignment Submitted", {
                    title: "Assignment Submitted Successfully",
                    variant: "success",
                    solid: true
                  });

                  this.$store.commit(
                    this.$store.state.oraginalsubject +
                      "/submissions/" +
                      this.$store.state.email +
                      this.assign.key +
                      "/assignmentattemps/" +
                      this.submit.attempts
                  );

                  //router.back();

                  //! Go to Submission Report

                  if (
                    this.submit.attempts == 1 ||
                    this.submit.attempts == "1"
                  ) {
                    // if (
                    //   this.$store.state.grade != "Grade 1" ||
                    //   this.$store.state.grade != "Grade 2"
                    // )
                    // this.updatePoints(this.$store.state.rightAnswers);
                  }

                })
                .catch(() => {});
            });
           // if(this.quiz.attempts <= this.attempts){
              this.totalAnswers = Number(this.$store.state.rightAnswers) + Number(this.$store.state.wrongAnswers)
this.rightAnswers = Number(this.$store.state.rightAnswers)
this.wrongAnswers = Number(this.$store.state.wrongAnswers)
console.log("this.thisattemp:",this.thisattemp)
            firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/attempts/"+this.thisattemp)
              .update({
                // date:moment().toISOString(),
                attempts: this.thisattemp,
                breakdown: this.$store.state?.lessonbreakdown,
                subjectTitle: this.$store.state.subjecttitle,
                right:this.rightAnswers,
                wrong:this.$store.state?.wrongAnswers,
                highestRight:this.rightAnswers,
                highestScore: ((this.rightAnswers / this.totalAnswers)*100).toFixed(2),
                score:((this.rightAnswers / this.totalAnswers)*100).toFixed(2),
                class: this.$store.state.sclass,
                student:this.$store.state.email,
                branch: this.$store.state.branch,
                school: this.$store.state.school,
                weektitle: this.$store.state.weektitle,
                week: this.$store.state.weekpath,
                gradelevel:this.$store.state.grade,
                weight:this.$store.state.weighttitle
              })
              .then(() => { 
                console.log("updated",this.attempts)
                // if(this.quiz.attempts <= this.attempts){
                  for (let index = 1; index <= this.thisattemp; index++) {
                    firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/attempts/"+index)
              .get()
              .then((querySnapshot) => { 
                if(querySnapshot){
                  if(index == 1){
                    this.oldhighestRight = querySnapshot.data()?.right;
                    this.highestRight = querySnapshot.data()?.right;
                  }else{
                    if(querySnapshot.data()?.right > this.oldhighestRight){
                      this.highestRight = querySnapshot.data()?.right;
                      this.oldhighestRight = this.highestRight;
                    }else{
                      this.highestRight = this.oldhighestRight;
                    }
                  }
   

                }
              })
                  }
   
                  firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid)
              .set({
                'attempts':this.thisattemp,
                'right':this.$store.state.rightAnswers,
                'wrong':this.$store.state.wrongAnswers,
                'highestRight':this.$store.state.rightAnswers,
                'highestScore': ((this.highestRight / (Number(this.$store.state.rightAnswers) + Number(this.$store.state.wrongAnswers)))*100).toFixed(2),
                'score':((this.highestRight / (Number(this.$store.state.rightAnswers) + Number(this.$store.state.wrongAnswers)))*100).toFixed(2)
                })
                .then(() => {
                  router.replace({
                    //name: "SubmissionReport"
                    name: "SubmissionReportAttempt", params: { attempt: this.thisattemp,total: this.assign.grade}
                  });
                })
                
              })
           // }

        } else {
          this.$swal(
            {
              title: "Missed Answer",
              icon: "warning",
              text: "Please Answer or Skip this Question to Continue",
              showLoaderOnConfirm: true
            }
          );
        }
        this.finished = true;
      }
    },
    checkDate(opendate, closedate) {
      if (
        Date.now() >= Date.parse(opendate) &&
        Date.now() <= Date.parse(closedate)
      )
        return true;
      else return false;
    }
  },
  created() {

    this.examquestions = [];

    this.$store.state.rightAnswers = 0;
    this.$store.state.wrongAnswers = 0;
    this.$store.state.missedAnswers = 0;

    //! Get Assignment Full Data
    firebase
      .firestore()
      .doc(this.asspath)
      .get()
      .then(doc => {
        this.temp = [];
        this.temp.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          type: doc.data().type,
          grade: doc.data().grade,
          weight: doc.data().weight,
          weightcode: doc.data().weightcode,
          opendate: doc.data().opendate,
          closedate: doc.data().closedate,
          description: doc.data().description,
          quiztype: doc.data().quiztype,
          count: doc.data().count,
          week: doc.data().week,
          note: doc.data().note,
          file: doc.data().file,
          attempts: doc.data().attempts
        });

        this.assign = this.temp[0];
        console.log("ass key",this.assign.key)
        //! Load Fixed Questions
        if (this.assign.quiztype == "Fixed Questions") {
          firebase
            .firestore()
            .doc(this.assign.path + "/details/fixedquestions")
            .get()
            .then(snapshot => {
              if (!snapshot.exists) return;
              console.log(snapshot.data())
              this.examquestions = snapshot.data().fixedquestions;
              this.count = this.examquestions.length;
              this.loading = true;
            });
        } else {
          //! Load Random Questions
          this.count = this.assign.count;

          firebase
            .firestore()
            .collection(this.week + "/questions")
            .get()
            .then(querySnapshot => {
              this.examquestions = [];
              querySnapshot.forEach(doc => {
                console.log(doc.data())
                if (doc.data().exam != "Yes")
                  this.examquestions.push({
                    key: doc.id,
                    path: doc.ref.path
                  });
              });

              //! Generate Unique Random List
              this.arr = [];
              while (this.arr.length < this.count) {
                var r = Math.floor(Math.random() * this.examquestions.length);
                if (this.arr.indexOf(r) === -1) this.arr.push(r);
              }
              this.loading = true;
              //this.loading = true;
            });
        }
      });
  }
};
</script>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/intro.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">
                  Reading
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-card>
          <v-card-text>
            <v-card flat outlined>
              <v-card-title style="color:green"
                >Start Reading<v-divider /><span
                  style="font-size:12px; color:#333;  margin-right:10px;"
                  >Click to Hear The Paragraph</span
                >
                <vue-speech-synthesis
                  lang="ar-EG"
                  :text="text"
                  color="red"
                  :rate="speed"
              /></v-card-title>
              <v-card-text>
                <v-btn color="info" small @click="testGrammar()"
                  >Check Grammar</v-btn
                >
                <v-divider />
                <br />

                {{ text }}
                <v-divider />
                <!--    <v-card
                  v-for="t in text"
                  :key="t.index"
                  flat
                  outlined
                  style="margin-bottom:10px;"
                >
                  <v-card-text
                    ><v-chip color="red" dark style="margin-right:10px"
                      >Character {{ t.index }}</v-chip
                    >{{ t.reason }}</v-card-text
                  >
                </v-card>-->
              </v-card-text></v-card
            >

            <br />

            <v-card flat outlined shaped>
              <v-card-title style="color:green"
                >Click Here and Start Speech<v-divider />

                <span style="font-size:12px; color:#333; margin-right:10px;"
                  >Similarity </span
                >{{ Math.round(sim * 100) }}%
                <span style="font-size:12px; color:green; margin-left:10px;"
                  >Accurate</span
                >

                <span
                  style="font-size:12px; color:#333; margin-right:10px; margin-left:10px;"
                  >Confidence </span
                >{{ Math.round(confidence * 100) }}%
                <span style="font-size:12px; color:green; margin-left:10px;">
                </span>
              </v-card-title>

              <v-card-text>
                <v-btn
                  @click="toggleStartStop()"
                  color="error"
                  depressed
                  v-if="start"
                  small
                >
                  <v-icon>mdi-stop</v-icon>Stop Reading</v-btn
                >
                <v-btn
                  @click="toggleStartStop()"
                  color="success"
                  depressed
                  v-if="!start"
                  small
                  ><v-icon>mdi-play</v-icon> Start Reading</v-btn
                >

                <v-divider />

                {{ this.rtext }}
              </v-card-text>
              <v-card-actions>
                <v-divider /><span
                  style="font-size:12px; color:#333; margin-right:10px;"
                >
                  Playback</span
                >
                <vue-speech-synthesis
                  lang="en"
                  :text="rtext"
                  color="green"
                  :rate="speed"
                  style=" margin-right:10px;"
                />
              </v-card-actions>
            </v-card>
            <div style="margin-top:10px">
              <v-icon>mdi-head-cog-outline</v-icon>
              <span style="font-size:12px; color:red; margin-right:10px;">
                It’s A.I. (Artificial Intelligence) you can't reach 100% aim for
                higher than 80%</span
              >
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import stringSimilarity from "string-similarity";
export default {
  name: "speech-page",
  components: {},
  data() {
    return {
      recognizing: "",
      recognition: new window.webkitSpeechRecognition(),

      play: "",
      record: "",
      text:
        "السينما أحدث الفنون جميعا ، فعمرها يكاد لا يتجاوز السبعين عاما في حين أن الأدب من أقدم الفنون",
      capturedText: [],

      rtext: ".....",
      confidence: 0,
      start: false,
      sim: 0,
      speed: 1
    };
  },

  methods: {
    toggleStartStop() {
      if (this.recognizing) {
        this.recognition.stop();
        this.recognizing = false;
        this.start = false;

        this.sim = stringSimilarity.compareTwoStrings(this.text, this.rtext);
      } else {
        this.rtext = "";
        this.recognition.start();
        this.recognizing = true;
        this.start = true;
      }
    },
    testGrammar() {
      var writeGood = require("write-good");
      var suggestions = writeGood(
        "A greenhouse is a glass building used to grow plants. A greenhouse has transparent glass that allows the sunlight to pass through, but does not allow the heat inside to escape. The same affect occurs on the earth. The suns radiation passes through the atmosphere to heat the earth's surface. When heated, the earth's surface produces infrared radiation, which has a longer wavelength than that of sunlight. This infrared radiation rises into the atmosphere where gases, such as carbon dioxide, prevents the infrared radiation from escaping into space. The concentrations of these gases which are called greenhouse gases, control how much infrared radiation escapes. The retained radiation heats the earth's atmosphere, thus keeping the planet warm."
      );
      this.text = suggestions;
    }
  },

  created() {
    this.recognition = new window.webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.lang = "ar-EG";
    this.recognizing = false;
  },
  async mounted() {
    let vm = this;
    this.recognition.onresult = function(event) {
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          vm.rtext += event.results[i][0].transcript;
          vm.confidence = event.results[i][0].confidence;
          console.log(event.results[i][0].transcript);
        }
      }
    };
  }
};
</script>

<template>
  <v-card style="margin-top:20px">
    <v-card-title style="color:#033b59; font-weight:700;">
      {{ this.title.substr(0, 80) }} <v-divider /><v-icon size="80"
        >mdi-calendar-text</v-icon
      ></v-card-title
    >
    <v-card-subtitle>{{ this.date }} </v-card-subtitle>
    <v-card-text>
      <v-card flat outlined>
        <v-card-subtitle
          >Participation
          <h4>
            {{ this.participation.grade }}/{{ this.par }}
          </h4></v-card-subtitle
        >
        <v-card>{{ this.participation.feedback }}</v-card>
      </v-card>
      <v-row>
        <v-col cols="4">
          <FlippedLesson :weekid="this.weekid" />
        </v-col>
        <v-col cols="4">
          <ExitLesson :weekid="this.weekid" />
        </v-col>
        <v-col cols="4">
          <InclassLesson :weekid="this.weekid" />
        </v-col>
      </v-row>

      <AssignLesson
        v-for="assign in assignments"
        :key="assign.key"
        :path="assign.path"
        :type="assign.type"
        :title="assign.title"
        :opendate="assign.opendate"
        :closedate="assign.closedate"
        :grade="assign.grade"
        :weight="assign.weight"
        :assid="assign.key"
        :weekpath="assign.week"
        :weektitle="title"
        :weekid="weekid"
        :oraginal="oraginal"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
import AssignLesson from "./AssignLesson";
import FlippedLesson from "./FlippedLesson";
import ExitLesson from "./ExitLesson";
import InclassLesson from "./InclassLesson";

export default {
  name: "GradeLesson",
  components: { AssignLesson, FlippedLesson, ExitLesson, InclassLesson },
  props: ["path", "title", "date", "weekid", "par", "subject", "oraginal"],
  data() {
    return {
      assignments: [],
      lessons: [],
      weights: [],
      participation: [],
      week: ""
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.subject + "/tasks")
      .where("week", "==", this.path)
      .get()
      .then(querySnapshot => {
        this.tasks = [];
        querySnapshot.forEach(doc => {
          if (doc.data().graded != true) {
            this.assignments.push({
              key: doc.id,
              path: doc.ref.path,
              type: doc.data().type,
              title: doc.data().title,
              grade: doc.data().grade,
              opendate: doc.data().opendate,
              closedate: doc.data().closedate,
              weight: doc.data().weight
            });
          }
        });
      });

//(to add) add breakdown filter
    firebase
      .firestore()
      .doc(this.$store.state.studentpath + "/participation/" + this.weekid)
      .get()
      .then(doc => {
        if (doc.exists) {
          this.participation = doc.data();
        }
      });
  },
  methods: {}
};
</script>

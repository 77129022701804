var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"background-color":"#002147"},attrs:{"dark":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"width":"50%"},attrs:{"label":_vm.$t('Filter by Month'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},on:{"change":_vm.changeFilter},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{staticStyle:{"direction":"ltr","width":"30%"},attrs:{"items":_vm.boards,"label":_vm.$t('Select Lesson')},on:{"change":_vm.search},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info","x-small":"","depressed":""},on:{"click":_vm.clearsearch}},[_vm._v(_vm._s(_vm.$t("Clear Search")))])],1)],1)],1),_c('br'),(_vm.boards.length > 0)?_c('div',[(_vm.summer != '')?_c('div',[_c('v-card',{staticClass:"mx-auto",staticStyle:{"margin-bottom":"20px","background-color":"#E53935"},attrs:{"max-width":"100%","dark":"","outlined":""},on:{"click":function($event){_vm.goToWeek(
              _vm.summer.ref.path,
              _vm.summer.data().title,
              _vm.summer.id,
              _vm.summer.data().revision
            )}}},[_c('v-list-item-title'),_c('v-list-item',[_c('v-row',[_c('v-col',{staticStyle:{"font-size":"x-large"},attrs:{"cols":"2"}},[_c('img',{attrs:{"src":require("@/assets/summer.png"),"width":"80px"}})]),_c('v-col',{attrs:{"cols":"10"}},[_c('h1',{staticStyle:{"color":"#fff","font-size":"1.5rem"}},[_vm._v("Summer Work")])])],1)],1)],1)],1):_vm._e(),(_vm.current != '')?_c('div',[_c('v-card',{staticClass:"mx-auto",staticStyle:{"margin-bottom":"20px","background-color":"#006e47"},attrs:{"shaped":"","max-width":"100%","dark":"","outlined":""},on:{"click":function($event){_vm.goToWeek(
              _vm.current.ref.path,
              _vm.current.data().title,
              _vm.current.id,
              _vm.current.data().revision
            )}}},[_c('v-list-item-title'),_c('v-list-item',[_c('v-row',[_c('v-col',{staticStyle:{"font-size":"x-large","color":"#c7cfdd"},attrs:{"cols":"2"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Week"))+" ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('h1',{staticStyle:{"color":"orange","font-size":"1.5rem"}},[_vm._v("Current Week")]),_c('div',{staticStyle:{"padding-left":"20px","padding-right":"20px"}},[_vm._v(" "+_vm._s(_vm.current.data().title)+" ")])])],1)],1)],1)],1):_vm._e(),(_vm.orientation != '')?_c('div',[_c('v-card',{staticClass:"mx-auto",staticStyle:{"margin-bottom":"20px","background-color":"#01579B"},attrs:{"shaped":"","max-width":"100%","dark":"","outlined":""},on:{"click":function($event){_vm.goToWeek(
              _vm.orientation.ref.path,
              _vm.orientation.data().title,
              _vm.orientation.id,
              _vm.orientation.data().revision
            )}}},[_c('v-list-item-title'),_c('v-list-item',[_c('v-row',[_c('v-col',{staticStyle:{"font-size":"x-large","color":"#c7cfdd"},attrs:{"cols":"2"}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/orientation.png"),"width":"80px"}})])]),_c('v-col',{attrs:{"cols":"10"}},[_c('h1',{staticStyle:{"color":"orange","font-size":"1.5rem"}},[_vm._v(" Orientation Week ")]),_c('div',{staticStyle:{"padding-right":"20px"}},[_vm._v(" "+_vm._s(_vm.orientation.data().title)+" ")])])],1)],1)],1)],1):_vm._e(),_vm._l((_vm.boards),function(week,index){return _c('div',{key:week.key},[(!week.revision)?_c('v-card',{staticClass:"mx-auto",staticStyle:{"margin-bottom":"20px"},attrs:{"max-width":"100%","shaped":"","flat":"","outlined":""},on:{"click":function($event){return _vm.goToWeek(
              week.path,
              week.title,
              week.key,
              week.revision,
              week.practice,
              week.lessonbreakdown
            )}}},[_c('v-list-item-title'),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('p',{staticStyle:{"font-size":"x-large","color":"#c7cfdd"}},[_vm._v(" "+_vm._s(_vm.$t("Week"))+" ")]),_c('br'),(week.section)?_c('v-chip',[_vm._v(" "+_vm._s(week.section))]):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"10"}},[_c('h1',{staticStyle:{"color":"#c7cfdd","font-size":"3rem"}},[_vm._v(_vm._s(_vm.$i18n.locale=='Arabic' ?(index + 1 ).toLocaleString('ar-EG') : (index + 1 )))]),_c('div',{staticClass:"fooar"},[_vm._v(" "+_vm._s(week.title)+" ")])])],1)],1)],1):_c('v-card',{staticClass:"mx-auto",staticStyle:{"margin-bottom":"20px","background-color":"#1976D2"},attrs:{"max-width":"100%","shaped":"","flat":"","outlined":"","dark":""},on:{"click":function($event){return _vm.goToWeek(week.path, week.title, week.key, week.revision,week.lessonbreakdown)}}},[_c('v-list-item-title'),_c('v-list-item',[_c('v-row',[_c('v-col',{staticStyle:{"font-size":"x-large","color":"#c7cfdd","background-color":"#1976D2"},attrs:{"cols":"2"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Revision"))+" ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('h1',{staticStyle:{"color":"#c7cfdd","font-size":"3rem"}},[_vm._v(_vm._s(_vm.$i18n.locale=='Arabic' ?(index + 1 ).toLocaleString('ar-EG') : (index + 1 )))]),_c('div',{staticStyle:{"padding-left":"20px","padding-right":"20px"}},[_vm._v(" "+_vm._s(week.title)+" ")])])],1)],1)],1)],1)})],2):_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"darken-2","height":"800px"}},[_c('v-skeleton-loader',{attrs:{"type":"article, actions"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
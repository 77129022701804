<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button variant="danger" v-bind="attrs" v-on="on" style="width:100%">
        <v-icon size="30" color="white">mdi-help-circle-outline</v-icon>
        <h6 style="margin-left: 10px;">Review Practice</h6>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-help-circle-outline</v-icon
          >Review Practice
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-subtitle>Select Week To Start Practice</v-card-subtitle>
      <v-card-text style="overflow: auto; height: 700px;">
        <v-card
          flat
          outlined
          v-for="(week, index) in weeks"
          tile
          :key="week"
          style="margin-bottom:5px;"
          @click="goToTest(week)"
        >
          <v-card-text>
            <img
              src="@/assets/livesession.png"
              width="40px"
              style="margin-right:20px;"
            />

            <b> {{$t("Week") +  (index + 1) }}</b></v-card-text
          >
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  props: ["path"],
  data() {
    return {
      dialog: false,
      weeks: []
    };
  },

  created() {
    this.weeks = [];
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(doc => {
        this.weeks = doc.data().weeks;
      });
  },
  methods: {
    goToTest(path) {
      this.$store.state.rightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.missedAnswers = 0;
      this.$store.state.current = 0;

      router.push({
        name: "TestPage",
        params: { path: path }
      });
    }
  }
};
</script>
<style scoped>
.body {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(24, 146, 0, 0.904);
  box-shadow: 0 0 1px rgba(24, 146, 0, 0.904);
}
</style>

<template>
  <v-app id="inspire">
    <vue-confirm-dialog></vue-confirm-dialog>

    <!-- End Sidebar -->

    <v-main>
      <!-- Topbar Begin -->
      <!-- <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        flat
        style="padding-left:390px"
      >
        <div v-if="this.domain == 'student.nis-schools.com'">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/logos%2Fnis.png?alt=media&token=56305566-d71d-4939-8f5c-a1b8777f15bc"
            width="50px"
          />&nbsp; &nbsp;
        </div>

        <img :src="this.$store.state.logo" width="50px" v-else />

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-apps</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
        <v-btn icon large>
          <v-avatar size="32px" item>
            <v-img :src="this.$store.state.logo" alt="Vuetify"></v-img>
          </v-avatar>
        </v-btn>
      </v-app-bar> -->

      <!-- End Topbar -->

      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import router from "@/router";
import firebase from "@/Firebase";

export default {
  name: "App",
  components: {},
  props: {
    source: String
  },

  data: () => ({
    dialog: false,
    drawer: null,
    domain: "",
    onLine: null,
    onlineSlot: "online",
    offlineSlot: "offline",
    mini: true
  }),
  methods: {
    goToPage(page) {
      this.mini = true;
      router.push({ name: page });
    },
    signOut() {
      localStorage.setItem("loggedIn", false);
      this.$store.state.loggedIn = false;
      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.href = "/school/" + this.$store.state.school;
        });
    }
  },
  created() {
    this.domain = window.location.host;
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Lalezar"); */

#inspire {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  background-color: #eff0f5;
}

.card-text {
  padding: 20px;
}
.offline {
  color: red;
  font-size: small;
}
.online {
  visibility: hidden;
}
</style>

<template>
  <div>
    <v-navigation-drawer
      src="@/assets/lessonheader.jpg"
      height="300px"
      width="100%"
      permanent
    >
      <div style="padding-left:30px;  padding-top:70px; width:50%; color:#fff;">
        <h5>{{ this.$store.state.weektitle }}</h5>

        {{ subject + " " + grade }}
      </div>
    </v-navigation-drawer>

    <v-row>
      <v-col cols="12" sm="8" xs="8">
        <span v-if="view == 'sections'">
          <v-card flat v-if="lesson.outline" outlined style="margin-left:20px;">
            <v-card-title>Exam Outline & Guideline</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="10">
                  <div
                    v-html="lesson.outline"
                    :class="
                      subject == 'Arabic' || subject == 'Arabic Social Studies'
                        ? 'fooar'
                        : 'foo'
                    "
                  ></div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br />

          <v-card
            @click="goToVideos($store.state.weekpath, 'videos')"
            flat
            outlined
            ><v-card-text>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Videos</div>
                  <v-list-item-title class="headline mb-1">
                    {{ $t("Lesson Videos") }} 
                  </v-list-item-title>
                  <v-list-item-subtitle>Educational Shows</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar tile size="80"
                  ><img src="@/assets/videos.png" width="100px"
                /></v-list-item-avatar>
              </v-list-item> </v-card-text
          ></v-card>
          <v-card
            style="margin-top: 20px"
            @click="goToVideos($store.state.weekpath, 'internet')"
            flat
            outlined
            ><v-card-text
              ><v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Videos</div>
                  <v-list-item-title class="headline mb-1">
                    Review Videos
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >Educational Videos</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-avatar tile size="80"
                  ><img src="@/assets/videos2.png" width="100px"
                /></v-list-item-avatar>
              </v-list-item> </v-card-text
          ></v-card>
          <v-card
            style="margin-top: 20px;"
            @click="goToShows($store.state.weekpath)"
            flat
            outlined
            ><v-card-text
              ><v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Files</div>
                  <v-list-item-title class="headline mb-1">
                    Review Documents
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >Presentation Shows</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-avatar tile size="80"
                  ><img src="@/assets/powerpoint.png" width="100px"
                /></v-list-item-avatar>
              </v-list-item> </v-card-text
          ></v-card>
        </span>

        <span v-else>
          <v-card color="#1d2430" style="margin-left:20px;">
            <v-card-title
              style="color: #ffffff; cursor: pointer"
              @click="goToLesson(this.$store.state.weekpath)"
            >
              <v-icon color="green" size="30">mdi-play</v-icon>{{$t("Start Flipped Classroom")}}
            </v-card-title>

            <v-card-text>
              <LessonList :path="this.$store.state.weekpath"></LessonList>
            </v-card-text>
          </v-card>
        </span>
      </v-col>
      <v-col cols="12" sm="4" xs="4">
        <v-row>
          <!-- Review Packs -->
          <v-col cols="2" sm="6" xs="6">
            <a :href="lesson.packs" target="_blank">
              <v-card dark flat outlined color="info" style="margin-top:20px">
                <v-card-text></v-card-text>
                <v-card-actions
                  style="background-color:#1d2430; font-size:16px;"
                >
                  Review Packs
                </v-card-actions>
              </v-card>
            </a>
          </v-col>
          <v-col cols="2" sm="6" xs="6">
            <a :href="lesson.answers" target="_blank">
              <v-card
                dark
                flat
                outlined
                color="success"
                style="margin-top:20px"
                @click="goToTest(this.$store.state.weekpath)"
              >
                <v-card-text></v-card-text>
                <v-card-actions
                  style="background-color:#1d2430; font-size:16px;"
                >
                  Answer Key
                </v-card-actions>
              </v-card>
            </a>
          </v-col>
        </v-row>

        <!-- Review Practice -->

        <ReviewPractice :path="this.$store.state.weekpath" />
        <!-- Upcoming Live Sessions -->
        <v-card
          flat
          dark
          style="cursor: pointer; text-align:center; margin-top:20px; background-color:#333333;"
        >
          <v-card-title style="background-color: #1d2430"
            >Review Live Sessions</v-card-title
          >
          <v-card-text>
            <div v-if="sessions.length == 0">
              <img src="@/assets/nodata.png" width="80px" />
              <br />
              <small>No Upcoming Live Sessions</small>
            </div>

            <v-list dense dark v-else color="#333333">
              <v-list-item-group color="primary">
                <span v-for="(item, i) in sessions" :key="i">
                  <v-list-item
                    @click="
                      goToSession(
                        item.title,
                        item.date,
                        item.time,
                        item.link,
                        item.prerecorded
                      )
                    "
                  >
                    <v-list-item-avatar style="background-color:red">
                      <v-icon color="white" size="24"
                        >mdi-video-wireless-outline</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        ><h6>{{ item.title }}</h6></v-list-item-title
                      >
                      <v-list-item-subtitle style="color: #53a451"
                        >{{ item.date }} - {{ item.time }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </span>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- End Upcoming Live Sessions -->

        <br />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import LessonList from "../weeks/LessonList";
import ReviewPractice from "./ReviewPractice";
export default {
  name: "WeekPage",
  components: {
    LessonList,
    ReviewPractice
  },
  data() {
    return {
      assignments: {},
      lesson: {},
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      sessions: [],
      unique_random_numbers: [],
      view: "",
      objectives: "",
      lessonview: this.$store.state.lessonview,
      sessionsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/sessions")
    };
  },
  methods: {
    goToLesson(path) {
      console.log("review")
      this.$store.state.rightAnswers = 0;
      this.$store.state.missedAnswers = 0;
      this.$store.state.lessonrightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.lessonwrongAnswers = 0;
      this.$store.state.current = 0;

      router.push({
        name: "LessonPage",
        params: { path: path }
      });
    },
    goToVideos(path, source) {
      this.$store.commit("videotype", source);

      router.push({
        name: "ReviewVideos"
      });
    },
    goToShows(path, source) {
      router.push({
        name: "ReviewShows",
        params: { path: path, source: source }
      });
    },
    goToTest(path) {
      this.$store.state.rightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.missedAnswers = 0;
      this.$store.state.current = 0;

      router.push({
        name: "TestPage",
        params: { path: path }
      });
    },
    goToFlipped() {
      router.push({
        name: "FlippedIntro"
      });
    },
    goToExit() {
      router.push({
        name: "ExitIntro"
      });
    },
    goToAssignIntro(assid, asspath) {
      this.$store.commit("assid", assid);
      this.$store.commit("asspath", asspath);

      router.push({
        name: "AssignmentIntro"
        //name: "Assignment"
      });
    },

    goToReport() {
      router.push({
        name: "activityReport",
        params: {
          path: this.$store.state.weekpath,
          title: this.$store.state.weektitle
        }
      });
    },

    goToSession(title, date, time, link, recorded) {
      this.$store.commit("livetitle", title);
      this.$store.commit("livedate", date);
      this.$store.commit("livetime", time);
      this.$store.commit("livelink", link);
      this.$store.commit("liverecorded", recorded);

      router.push({
        name: "LiveSession"
      });
    }
  },

  created() {
    this.sessionsRef
      .where("sessionclass", "==", this.$store.state.sclass)
      .where("week", "==", this.$store.state.weekpath)
      .get()
      .then(querySnapshot => {
        this.sessions = [];
        querySnapshot.forEach(doc => {
          this.sessions.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            date: doc.data().date,
            time: doc.data().time,
            link: doc.data().link,
            prerecorded: doc.data().prerecorded
          });
        });
      });

    firebase
      .firestore()
      .collection(this.$store.state.weekpath + "/tasks")
      .get()
      .then(querySnapshot => {
        this.assignments = [];
        querySnapshot.forEach(doc => {
          const ass = doc.data();
          this.assignments.push({
            key: doc.id,
            path: doc.ref.path,
            title: ass.title,
            type: ass.type
          });
        });
      });
    firebase
      .firestore()
      .doc(this.$store.state.weekpath)
      .get()
      .then(doc => {
        this.lesson = doc.data();

        //! Get Objective
        firebase
          .firestore()
          .doc(this.$store.state.weekpath + "/details/1")
          .get()
          .then(doc => {
            if (doc.exists) {
              this.objectives = doc.data().objectives;
            }
          });
      });
  },
  mounted() {
    if (this.lessonview == "Lesson Page") this.view = "sections";
  }
};
</script>
<style scoped>
.fooar >>> img {
  width: 400px;
}
.fooar {
  text-align: right;
  direction: rtl;
  width: 100%;
}
.foo >>> img {
  width: 400px;
}
</style>

<template>
  <div id="printMe">
    <v-card outlined style="padding:30px">
      <v-card-title>
        <!-- <h5>Lesson Progress</h5> -->
        <h5>{{$t("Progress Reports")}}</h5>
        <v-divider /><span v-if="this.score"
          >{{ Number(this.score.toFixed(1)) }}%</span
        >
      </v-card-title>
      <!-- <v-card-subtitle v-if="this.score"
        >Click here for more details</v-card-subtitle
      > -->

      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-btn
        v-for="(attempt, i) in attempts" :key="i"
          color="green"
          small
          style="margin-bottom: 8px;"
          dark
          depressed
          @click="goToReport(attempt.attempts)"
        >
          <v-icon>mdi-finance</v-icon>
          {{$t("Report with Score:")}} {{ Number(attempt.score).toFixed(2) }}</v-btn
        >
          </v-col>
          <v-col cols="4">
        <p v-for="(attempt, i) in attempts" :key="i"  style="margin-left: 4px;"> {{ new Date(attempt.date).toLocaleDateString() }}</p>
          </v-col>
        </v-row>

        <!-- <div v-for="(attempt, i) in attempts" :key="i" style="margin-bottom: 5px;">
          <v-chip small v-if="attempt.score > 50" color="green" dark style="margin-bottom: 5px;">
          {{ Number(attempt.score).toFixed(2) }} %</v-chip>
          <v-chip small v-else color="red" dark style="margin-bottom: 8px;"> {{ Number(attempt.score).toFixed(2) }} %</v-chip>
        </div> -->
        <!-- <apexchart
          v-if="this.score"
          type="donut"
          width="100%"
          :options="dochartOptions"
          :series="doseries"
        ></apexchart>
        <div v-else>There are no Activities Yet</div> -->
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
// import apexchart from 'vue-apexcharts';

export default {
  name: "activityWidget",
  components: {},
  props: ["path"],
  data() {
    return {
      datacollection: null,
      lesson: [],
      flipped: [],
      submissions: [],
      totalRight: [],
      totalWrong: [],
      oldhighestRight:0,
      highestRight:0,
      FinalWrong:0,
      standards: [],
      wrongAnswers:0,
      rightAnswers:0,
      totalAnswers:0,
      exit: [],
      score: 0,
      attempts:[],
      school: this.$store.state.school,

      //Score Chart
      doseries: [1, 1],
      dochartOptions: {
        plotOptions: {
          pie: {
            donut: {
              size: "60%"
            }
          }
        },
        colors: ["#059612", "#e1261c"],
        chart: {
          type: "donut"
        },
        labels: ["Right", "Wrong"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    };
  },

  created() {
    firebase
      .firestore()
      .collection(this.$store.state.studentpath + "/flipped/" + this.$store.state.weekid + "/attempts")
      .get()
      .then((querySnapshot) => {
        this.attempts = [];
        querySnapshot.forEach((doc) => {
          // this.attempts.push(doc.data());
          firebase
      .firestore()
      .collection(this.$store.state.studentpath + "/flipped/" + this.$store.state.weekid + "/answers_new")
      .where("attempts","==",doc.data().attempts)
      .get()
      .then((querySnapshot) => {

        console.log("attempt count: ",doc.data().attempts,"/ answers count: ",querySnapshot.size)
        this.wrongAnswers=0
      this.rightAnswers=0
      this.totalAnswers=0
        querySnapshot.forEach((doc) => {
          if(doc.data().answer == "Right"){
          this.rightAnswers = this.rightAnswers+1
        }
        if(doc.data().answer == "Wrong"){
          this.wrongAnswers = this.wrongAnswers+1
        }
        this.totalAnswers = this.rightAnswers + this.wrongAnswers
        })
        console.log(this.rightAnswers,this.wrongAnswers)
        console.log("score : ",(this.rightAnswers / this.totalAnswers)*100)
        firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/attempts/"+doc.data().attempts)
              .update({
                'right':this.rightAnswers,
                'wrong':this.wrongAnswers,
                'highestRight':this.rightAnswers,
                'highestScore': (this.rightAnswers / this.totalAnswers)*100,
                'score':(this.rightAnswers / this.totalAnswers)*100,
              })
              .then(() => {
                this.attempts.push(doc.data());
               })
      })
        })

        // for (let index = 1; index <= this.attempts.length; index++) {
                    firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid+"/attempts")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                  if(doc){
                  if(doc.data().attempts == 1){
                    this.oldhighestRight = doc.data()?.right;
                    this.highestRight = doc.data()?.right;
                    this.FinalWrong = doc.data()?.wrong
                  }else{
                    if(doc.data()?.right > this.oldhighestRight){
                      this.highestRight = doc.data()?.right;
                      this.oldhighestRight = this.highestRight;
                      this.FinalWrong = doc.data()?.wrong
                    }else{
                      this.highestRight = this.oldhighestRight;
                    }
                  }
                }
                console.log("highest score:",(this.highestRight / (doc.data()?.right + doc.data()?.wrong))*100)
                if(doc.data().attempts <= 3){
                  const total = this.highestRight + this.FinalWrong
                  firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/flipped/"+this.$store.state.weekid)
              .update({
                'right':this.highestRight,
                'wrong':this.FinalWrong,
                'highestRight':this.highestRight,
                'highestScore': (this.highestRight / total)*100,
                'score':(this.highestRight / total)*100,
                "grade":total,
                "assgrade":total
                })
                .then(() => {})
              }
                }) 

              })

            //      }
      })
  },
  methods:{
    goToReport(i) {
      router.push({
        name: "activityReport",
        params: {
          path: this.$store.state.weekpath,
          title: this.$store.state.weektitle,
          attempts:i
        },
      });
    }
  }
};
</script>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/intro.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">Flipped</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <div>
          <v-card class="mx-auto">
            <v-card-title>
              <span class="headline"> </span>
            </v-card-title>
            <v-card-subtitle>{{ this.assign.type }}</v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div
                    v-if="
                      this.lesson[studentclass + branch + 'flipped'] == null ||
                        this.lesson[studentclass + branch + 'flipped'] ==
                          false ||
                        this.thisstudent['blended'] == false
                    "
                    style="text-align:center"
                  >
                    <v-card flat outlined
                      ><v-card-text>
                        <img src="@/assets/waiting.png" width="40%" />

                        <h5 style="padding-top:50px">
                          Waiting for Flipped Questions to be Opened
                        </h5>
                        <br />

                        <v-btn @click="onRetry" color="success" small
                          >Retry</v-btn
                        >
                      </v-card-text>
                    </v-card>
                  </div>

                  <div v-else>
                    <div v-if="this.before.week != null">
                      <v-card flat
                        ><v-card-text>
                          <h5 style="padding-top:50px">
                            You Already Submitted Before
                          </h5>
                        </v-card-text>
                      </v-card>
                    </div>

                    <v-btn
                      color="success"
                      @click="gotoAssignment"
                      style="margin-bottom:50px"
                      v-else
                      depressed
                    >
                      <v-icon>mdi-play</v-icon>
                      Take your attempt now</v-btn
                    >
                  </div>
                  <v-icon color="orange">mdi-alert-outline</v-icon>
                  <span style="color:red"
                    >Once you begin answering flipped questions please don't
                    refresh because this will be counted as an attempt.</span
                  >
                  <v-list dense>
                    <!--  <h5>Your Grade</h5>
                <h3 style="color: green">
                  {{ this.assrecord.right + "/" + this.assrecord.grade }}
                </h3>-->
                    <v-subheader
                      >Flipped Attemps (Click Attempt for More
                      Details)</v-subheader
                    >
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(item, i) in battemps"
                        :key="i"
                        @click="gotoReport(item.path)"
                      >
                        <v-list-item-icon>
                          <v-chip color="success" style="margin-left:20px;"
                            >{{ item.right + "/"
                            }}<small style="color:black">3</small>
                          </v-chip>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.date }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-col>
  </v-row>

  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  data() {
    return {
      right: [],
      wrong: [],
      battemps: [],
      assrecord: [],
      temp: [],
      assign: [],
      lesson: {},

      assignment: this.$store.state.assid,
      asspath: this.$store.state.asspath,
      student: this.$store.state.studentpath,
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      weekpath: this.$store.state.weekpath,
      weekid: this.$store.state.weekid,
      studentpath: this.$store.state.studentpath,

      //!Ahmed Kamal
      studentclass: this.$store.state.sclass,
      branch: this.$store.state.branch,
      //!---------------------------------------

      thisstudent: {},
      before: {},

      attemps: ""
    };
  },

  created() {
    firebase
      .firestore()
      .doc(this.weekpath)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) return;
        this.lesson = snapshot.data();
      });
    firebase
      .firestore()
      .doc(this.studentpath)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) return;
        this.thisstudent = snapshot.data();
      });

    firebase
      .firestore()
      .collection(this.$store.state.oraginalsubject + "/flipped")
      .doc(this.student.split("/")[3] + this.weekid)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) return;
        this.before = snapshot.data();
      });

    //! Get Student Grade Data
    // firebase
    //   .firestore()
    //   .doc(this.student + "/assignments/" + this.assignment)
    //   .onSnapshot(querySnapshot => {
    //     this.assrecord = querySnapshot.data();
    //   });

    this.attemps =
      this.$store.state.oraginalsubject +
      "/flipped/" +
      this.student.split("/")[3] +
      this.weekid +
      "flipped/assignmentattemps";

    //! Get attempts Grade Data
    firebase
      .firestore()
      .collection(this.attemps)
      .onSnapshot(querySnapshot => {
        this.battemps = [];
        querySnapshot.forEach(doc => {
          this.battemps.push({
            key: doc.id,
            path: doc.ref.path,
            right: doc.data().right,
            wrong: doc.data().wrong,
            missed: doc.data().missed,
            date: doc.data().date,
            grade: doc.data().grade,
            time: doc.data().time
          });
        });
      });
    // }
  },
  methods: {
    gotoAssignment() {
      router.push({
        name: "Flipped"
      });
    },
    gotoReport(path) {
      this.$store.commit("submissionpath", path);
      router.push({
        name: "SubmissionReport"
      });
    },

    onRetry() {
      firebase
        .firestore()
        .doc(this.weekpath)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) return;
          this.lesson = snapshot.data();
        });
      firebase
        .firestore()
        .doc(this.studentpath)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) return;
          this.thisstudent = snapshot.data();
        });
    }
  }
};
</script>

import * as firebase from "firebase";

const settings = {
    // timestampsInSnapshots: true
};

const config = {
    apiKey: "AIzaSyBRjfurnKMV2ZSFV3VbAwvWbmQnGy9wFWs",
    authDomain: "new-cerebral.firebaseapp.com",
    projectId: "new-cerebral",
    storageBucket: "new-cerebral.appspot.com",
    messagingSenderId: "758007490983",
    appId: "1:758007490983:web:9f0904d974834ea4ca6e82",
    measurementId: "G-CVB6RL8KQC"
};

firebase.initializeApp(config);
firebase.firestore().settings(settings);

export default firebase;
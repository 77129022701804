var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticStyle:{"z-index":"2","position":"absolute"},attrs:{"src":"@/assets/parentbg.jpg","height":"70%","width":"100%","permanent":""}},[_c('div',{staticStyle:{"padding-left":"30px","padding-top":"50px"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2","lg":"1"}},[_c('img',{attrs:{"src":this.$store.state.logo,"width":"70px"}})]),_c('v-col',{attrs:{"cols":"3"}},[_c('h3',[_vm._v(" Welcome "+_vm._s(this.$store.state.parentname)+", ")]),_vm._v(" Parent "),_c('div')]),_c('v-spacer'),_c('SwitchLocale')],1)],1)]),_c('v-container',[_c('v-row',{staticStyle:{"position":"absolute","z-index":"200","margin-top":"200px","margin-left":"20px","width":"100%"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.boards.length > 0)?_c('v-card',{attrs:{"flat":"","height":"800px"}},[_c('v-card-text',[_c('v-list',{staticStyle:{"max-height":"700px"},attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('div',{staticStyle:{"overflow":"auto","height":"700px"}},_vm._l((_vm.boards),function(student){return _c('v-list-item',{key:student.id,attrs:{"three-line":""},on:{"click":function($event){return _vm.goToSon(
                        student.firstname,
                        student.lastname,
                        student.branch,
                        student.grade,
                        student.class,
                        student.path
                      )}}},[_c('v-card',{staticStyle:{"margin-bottom":"50px","background-color":"#d7e9f9","margin-right":"20px"},attrs:{"width":"100%","shaped":"","outlined":"","flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('img',{attrs:{"src":require('@/assets/student.png'),"width":"80px"}})]),_c('v-col',{attrs:{"cols":"9"}},[_c('h5',[_vm._v(" "+_vm._s(student.firstname + " " + student.lastname)+" ")]),_c('span',{staticStyle:{"color":"#008719"}},[_vm._v(" "+_vm._s(student.branch))]),_c('p',{staticStyle:{"color":"#008719"}},[_vm._v(" "+_vm._s(student.grade)+" Class "+_vm._s(student.class)+" ")])])],1)],1)],1)],1)}),1)])],1)],1)],1):_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"darken-2","height":"800px"}},[_c('v-skeleton-loader',{attrs:{"type":"article, actions"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
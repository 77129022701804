<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="40%">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-icon>
            <v-icon v-if="answer == 'Right'" color="green"> mdi-check</v-icon>
            <v-icon v-else-if="answer == 'Wrong'" color="red">
              mdi-close</v-icon
            >
            <v-icon v-else color="orange"> mdi-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-card>
        <v-card-title
          class="headline green"
          style="color:#ffffff"
          v-if="this.answer == 'Right'"
        >
          {{ this.answer }}
        </v-card-title>
        <v-card-title
          class="headline red "
          style="color:#ffffff"
          v-if="this.answer == 'Wrong'"
        >
          {{ this.answer }}
        </v-card-title>
        <v-card-title
          class="headline orange "
          style="color:#ffffff"
          v-if="this.answer == 'Missed'"
        >
          {{ this.answer }}
        </v-card-title>

        <v-card-text>
          <div v-html="this.questionBody.body" class="foo"></div>
          <v-divider></v-divider>
          <v-card
            v-for="op in options"
            :key="op.key"
            flat
            outlined
            style="margin-bottom:10px;"
          >
            <v-card-text>
              <v-row>
                {{ op.title }}
                <v-spacer />
                <v-chip color="error" v-if="op.grade == 0"
                  ><v-icon>mdi-close</v-icon></v-chip
                >
                <v-chip color="success" v-else
                  ><v-icon>mdi-check</v-icon></v-chip
                >
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-if="this.question.qtype == 'tf'"
            flat
            outlined
            style="margin-bottom:10px;"
          >
            <v-card-text>
              <v-row style="display: flex;justify-content: space-evenly;">
                <v-chip color="error" 
                :class="this.question.correct=='false'? 'chosen' :'' "
                  ><v-icon>mdi-close</v-icon></v-chip
                >
                
                <v-chip 
                :class="this.question.correct=='true'? 'chosen' :'' "
              color="success"><v-icon>mdi-check</v-icon></v-chip>
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider></v-divider>
          <div v-html="this.question.answer" class="foo"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "QuestionPreview",
  props: ["path", "title", "answer"],
  data() {
    return {
      dialog: false,

      question: {},
      questionBody:{},
      options: []
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.question = snapshot.data();
      });
      firebase
      .firestore()
      .doc(this.path+'/details/details')
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        console.log("question body ==>",snapshot.data())
        this.questionBody = snapshot.data();
      });
    firebase
      .firestore()
      .collection(this.path + "/options")
      .get()
      .then(querySnapshot => {
        // if (!querySnapshot.exists) return;

        // console.log(querySnapshot);
        this.options = [];
        querySnapshot.forEach(doc => {
          this.options.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            grade: doc.data().grade
          });
        });
      });
  }
};
</script>
<style scoped>
.foo >>> img {
  width: 400px;
}
.chosen{
  outline-color: brown;
  outline-style: groove;
  outline-width: medium;
}
</style>

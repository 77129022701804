<template>
  <div>
    <!-- <div v-if="this.attempts != submit.attempts"> -->
      <div>
      <!-- Assignment Opened -->
      <div v-if="checkDate()">
        <!-- Teacher Note -->

        <!-- Textbook Assignment -->
        <div v-if="this.assign.type == 'Textbook Assignment'">
          <Textbook
          :attempts="assign.attempts"
          :assAttempts="this.$route.params.assAttempts"
          :assignm="this.$route.params.assign"
          :asspath="this.$route.params.asspath"
          />
        </div>

        <!-- Quiz Assignment -->
        <div v-else-if="this.assign.type == 'Quiz Assignment'">
          <!-- "submit.attempts == null ? 1 : submit.attempts + 1" -->
          <QuizAssignment
            :attempts="assign.attempts"
            :thisattemp="this.$route.params.assAttempts"
            :title="assign.title"
            :quiz="quiz"
            :assAttempts="this.$route.params.assAttempts"
            :assignm="this.$route.params.assign"
            :asspath="this.$route.params.asspath"
          />
        </div>
      </div>
      <!-- Assignment Closed -->
      <div v-else>
        <v-card>
          <v-card-text style="text-align:center">
            <img src="@/assets/waiting.png" width="40%" />

            <div v-if="checkclosed(this.assign.closedate)">
              {{$t("This Assignment has been Closed")}}
            </div>
            <div v-else>
              <h4 style="padding-top:20px">{{$t("Assignment Not Opened Yet")}}</h4>
              {{$t("Will open on")}}

              {{
                Intl.DateTimeFormat("en", { day: "numeric" }).format(
                  Date.parse(this.assign.opendate)
                )
              }}/{{
                Intl.DateTimeFormat("en", { month: "numeric" }).format(
                  Date.parse(this.assign.opendate)
                )
              }}/{{
                Intl.DateTimeFormat("en", { year: "numeric" }).format(
                  Date.parse(this.assign.opendate)
                )
              }}
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- <div v-else>
      <v-card
        ><v-card-text>
          No More Allowed Attempts
        </v-card-text></v-card
      >
    </div> -->
  </div>
</template>
<script>
import firebase from "@/Firebase";
import Textbook from "./TextBook";
import QuizAssignment from "./QuizAssignment";

export default {
  name: "assignemnt-page",
  components: { Textbook, QuizAssignment },
  data() {
    return {
      //Upload Image
      attempts: 0,
      imageData: null,
      hidden: false,
      picture: null,
      uploadValue: 0,
      files: "",
      assign: [],
      student: this.$store.state.studentpath,
      asspath: this.$store.state.asspath,
      submit: {},
      extendto: [],
      quizAttempts:0,
      quiz:{},
      show:false
    };
  },
  methods: {
    checkDate() {
      // if (this.assign.eopendate != null) {
      //   //! If There's Extend
      //   if (
      //     this.extendto.includes(this.student) &&
      //     Date.now() >= Date.parse(this.assign.eopendate) &&
      //     Date.now() <= Date.parse(this.assign.eclosedate)
      //   )
      //     return true;
      //   else return false;
      // } else {
        //! If There's No Extend

        if (
          Date.now() >= Date.parse(this.assign.opendate) &&
          Date.now() <= Date.parse(this.assign.closedate)
        )
          return true;
        else return false;
      //}
    },

    checkclosed(closedate) {
      if (Date.now() >= Date.parse(closedate)) return true;
      else return false;
    }
  },

  created() {
    //! Get Assignment Full Data
    firebase
      .firestore()
      .doc(this.asspath)
      .get()
      .then(doc => {
        this.temp = [];
        console.log(doc.data())
        this.$store.commit("asstype",doc.data().type)
        this.$store.commit("weightcode",doc.data().weightcode)
        this.temp.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          type: doc.data().type,
          grade: doc.data().grade,
          weight: doc.data().weight,
          weightcode: doc.data().weightcode,
          opendate: doc.data().opendate,
          eopendate: doc.data().eopendate,
          closedate: doc.data().closedate,
          eclosedate: doc.data().eclosedate,
          description: doc.data().description,
          quiztype: doc.data().quiztype,
          count: doc.data().count,
          week: doc.data().week,
          note: doc.data().note,
          file: doc.data().file,
          attempts: Number(doc.data().attempts)
        });

        this.assign = this.temp[0];
        console.log(this.assign.type)
        //if max attempts = 1
        if (this.assign.attempts >= 1) this.attempts = this.assign.attempts;
        else this.attempts = 500;

        firebase
          .firestore()
          .doc(this.asspath + "/details/extendto")
          .get()
          .then(doc => {
            this.extendto = doc.data()?.extendto;
          });

        firebase
          .firestore()
          .collection(this.$store.state.studentpath + "/assignments")
          .doc(this.assign.key)
          .get()
          .then(doc => {
            console.log(this.assign.key)
            console.log(doc.data())
            console.log(doc.data())
            if (doc.exists) {
              this.submit = doc.data();
            }else{
              this.submit.attempts = 0
            }
          });
          firebase
        .firestore()
        .collection(this.$store.state.studentpath + "/quizes")
        .doc(this.$store.state.assid)
        .get()
        .then((doc) => {
          console.log(this.$store.state.assid)
          console.log(doc.data())
          if(doc?.data()){
            this.quiz = doc.data()
          }else{
            this.quiz.attempts = 1
          }
          console.log(this.quiz.attempts)
          this.quiz.attempts <=this.attempts? this.show = true: this.show = false
        })
      });

      if(this.$route.params.assAttempts > Number(this.$route.params.assign.attempts)){
        console.log(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/answers_new")
        firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/answers_new")
                .where("attempts","==",this.$route.params.assAttempts)
                .get()
                .then((docs) => {
                  docs.forEach(el => {
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/answers_new")
                .doc(el.id)
                .delete()
                  })
                  console.log(docs.ref)

        })
          
        firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .where("attempts","==",this.$route.params.assAttempts)
                .get()
                .then((docs) => {
                  docs.forEach(el => {
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .doc(el.id)
                .delete()
                  })
                  console.log(docs.ref)

        })
        // .collection(this.$store.state.studentpath + "/quizes")
                // .doc(this.$store.state.assid)
                // .collection("weakness_new")
      }
      // if(this.$route.params.assAttempts <= this.$route.params.assign.attempts){
      //         firebase
      //         .firestore()
      //         .collection(this.$store.state.studentpath + "/quizes")
      //         .doc(this.$store.state.assid)
      //         .set({
      //           start_date: moment().format("DD-MM-YY"),
      //           start_time: moment().format("h:mm:ss a"),
      //           attempts: this.$route.params.assAttempts,
      //           highestScore:"",
      //           highestRight:""
      //         })
      //         .then(() => {
      //           firebase
      //         .firestore()
      //         .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+'/attempts/'+this.$route.params.assAttempts)
      //         .set({
      //           assid: this.$store.state.assid,
      //           asspath:this.$route.params.asspath,
      //           assgrade:this.$route.params.assign.grade,
      //           date:moment().format("DD-MM-YY"),
      //           attempts: this.$route.params.assAttempts,
      //           breakdown: this.$store.state?.breakdown,
      //           subjectTitle: this.$store.state.subjecttitle,
      //           class: this.$store.state.sclass,
      //           student:this.$store.state.email,
      //           branch: this.$store.state.branch,
      //           school: this.$store.state.school,
      //           weektitle: this.$store.state.weektitle,
      //           week: this.$store.state.weekpath,
      //           gradelevel:this.$store.state.grade,
      //           missed:""
      //         })
      //         .then(() => {
 
      //         })

      //         });
      //       }else{
      //         let att = Number(this.$route.params.assign.attempts) + 1
      //         firebase
      //         .firestore()
      //         .collection(this.$store.state.studentpath + "/quizes")
      //         .doc(this.$store.state.assid)
      //         .set({
      //           start_date: moment().format("DD-MM-YY"),
      //           start_time: moment().format("h:mm:ss a"),
      //           attempts: att,
      //           highestScore:"",
      //           highestRight:""
      //         })
      //         .then(() => {
      //           firebase
      //         .firestore()
      //         .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+'/attempts/'+att)
      //         .set({
      //           assid: this.$store.state.assid,
      //           asspath:this.$route.params.asspath,
      //           assgrade:this.$route.params.assign.grade,
      //           date:moment().format("DD-MM-YY"),
      //           attempts: att,
      //           breakdown: this.$store.state?.breakdown,
      //           subjectTitle: this.$store.state.subjecttitle,
      //           class: this.$store.state.sclass,
      //           student:this.$store.state.email,
      //           branch: this.$store.state.branch,
      //           school: this.$store.state.school,
      //           weektitle: this.$store.state.weektitle,
      //           week: this.$store.state.weekpath,
      //           gradelevel:this.$store.state.grade,
      //           missed:""
      //         })
      //         .then(() => {
      //         })

      //         });
      //       }
  }
};
</script>

<template>
  <div>

    <v-select
  v-model="$i18n.locale"
  :key="`lang-${i}`"
  label=""
  :items="langs"
  density="comfortable"
  background-color="transparent"
  @change="changeLang"
  append-icon="mdi-translate"
>
<!-- <template v-slot:prepend>
              <v-icon color="white">translate</v-icon>
            </template> -->
</v-select>
  </div>
</template>

<script>
export default {
  name: "SwitchLocale",
  data() {
    return { langs: ["English", "Arabic"] };
  },
  created(){
    localStorage.setItem("languageChosen", localStorage.getItem("languageChosen"))
  },
  methods: {
      changeLang(selectObj) {
        localStorage.setItem("languageChosen", selectObj)
     }
}
};
</script>

<style>
.v-input {
  width: 40%;
}
.v-select__selection--comma{
  color: white;
}
.v-input__slot .v-label{
color: white!important
}
.v-select__selections{
  color: white;
  margin-right: -25px;
}
.v-select__selection--comma {
    color: transparent;
    margin: 0;
}
.theme--dark.v-select .v-select__selections, .theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
    color: transparent;
}
.theme--dark.v-text-field>.v-input__control>.v-input__slot:before {
    border-color: transparent;
    border: none;
}
.v-text-field>.v-input__control>.v-input__slot:after {
    background-color: transparent;
    border-color: currentcolor;
    /* border-style: solid; */
    border-width: thin 0;
    transform: scaleX(0);
}
</style>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/reportbg.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> </v-list-item-title>
              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-card flat outlined>
          <v-card-title>Submission Report</v-card-title>
          <v-card>
            Thank you for your submission
          </v-card>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>

<template>
  <v-row style="direction: ltr;">
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/videoheader.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">{{ $t("Messaging Center") }}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.grade }}
                <br />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container fluid style="height: 600px">
        <v-layout style="height: 100%">
          <v-flex>
            <v-card flat outlined>
              <v-card-text>
                <div style="text-align: left;">
                  <selectTeacher />
                </div>

                <v-row>
                  <v-col cols="4">
                    <v-list rounded>
                      <v-subheader>{{$t("Chat List")}}</v-subheader>

                      <vuescroll>
                        <v-list-item-group
                          color="primary"
                          style="max-height:400px"
                        >
                          <Chat
                            v-for="(item, i) in rooms"
                            :key="i"
                            :userid="item.receiver"
                            :category="item.category"
                            :id="item.key"
                            v-on:click.native="getMessages(item.chatpath)"
                            style="padding-bottom:20px"
                          >
                            <v-icon
                              color="red"
                              v-on:click.native="deleteboard(item.key)"
                              >mdi-delete</v-icon
                            >
                          </Chat>
                        </v-list-item-group>
                      </vuescroll>
                    </v-list>
                  </v-col>

                  <v-col
                    cols="8"
                    style="background-color:#eee;  border-radius: 15px;"
                  >
                    <b-card flat style="border-radius: 15px; height: 500px">
                      <b-card-text>
                        <vuescroll>
                          <div style="max-height:300px; padding-right:50px;">
                            <ul style="list-style-type: none;">
                              <span
                                v-for="message in messages"
                                :key="message.key"
                              >
                                <v-row v-if="message.sender == username">
                                  <v-col md="4" class="ml-auto">
                                    <li style="text-align:left">
                                      <v-container
                                        fluid
                                        class="speech-bubble after"
                                        grid-list-md
                                        text-xs-center
                                      >
                                        <v-flex>
                                          {{ message.content }}
                                        </v-flex>
                                      </v-container>

                                      <small>{{ message.time }}</small>
                                    </li>
                                  </v-col>
                                </v-row>
                                <v-row v-else>
                                  <v-col md="4">
                                    <li style="text-align:right">
                                      <v-container
                                        fluid
                                        class="reciver-bubble after"
                                        grid-list-md
                                        text-xs-center
                                      >
                                        <v-flex>
                                          {{ message.content }}
                                        </v-flex>
                                      </v-container>

                                      <small>{{ message.time }}</small>
                                    </li>
                                  </v-col>
                                </v-row>
                              </span>
                            </ul>
                          </div>
                        </vuescroll>
                      </b-card-text>

                   
                    </b-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import Chat from "./Chat";
import vuescroll from "vuescroll";
import selectTeacher from "./selectTeacher";

export default {
  name: "Room-page",
  components: { Chat, vuescroll, selectTeacher },
  data() {
    return {
      rooms: [],
      messages: [],
      newmessage: "",
      collMessage: {},
      selectedReciver: "",
      chatpath: "",
      room: "",
      username: this.$store.state.id,
      grade: this.$store.state.grade
    };
  },

  created() {
    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/students/" +
          this.$store.state.id +
          "/chats"
      )
      .onSnapshot(querySnapshot => {
        this.rooms = [];
        querySnapshot.forEach(doc => {
          if (doc.data().receiver != this.username)
            this.rooms.push({
              key: doc.id,
              path: doc.ref.path,
              roomName: doc.data().roomName,
              chatpath: doc.data().chatpath,
              receiver: doc.data().receiver,
              last_changed: doc.data().last_changed,
              category: doc.data().category,
              type: doc.data().type,
              owner: doc.data().owner
            });
          else
            this.rooms.push({
              key: doc.id,
              path: doc.ref.path,
              roomName: doc.data().roomName,
              chatpath: doc.data().chatpath,
              receiver: doc.data().owner,
              last_changed: doc.data().last_changed,
              category: doc.data().category,
              type: doc.data().type,
              owner: doc.data().receiver
            });
        });
      });
  },
  methods: {
    deleteboard(id) {
      this.$confirm({
        message: "Are you sure You want to this chat ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("/schools/" + this.$store.state.school + "/rooms")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    getMessages(path) {
      this.selectedChat = path;
      firebase
        .firestore()
        .collection(path + "/messages")
        .orderBy("stamp")
        .onSnapshot(querySnapshot => {
          this.messages = [];
          querySnapshot.forEach(doc => {
            this.messages.push({
              key: doc.id,
              path: doc.ref.path,
              content: doc.data().content,
              sender: doc.data().sender,
              time: doc.data().time
            });
          });
        });
    },
    sendMessage() {
      var d = new Date();

      this.collMessage.sender = this.$store.state.id;
      this.collMessage.content = this.newmessage;
      this.collMessage.time = d.getHours() + ":" + d.getMinutes();
      this.collMessage.stamp = d;

      firebase
        .firestore()
        .collection(this.selectedChat + "/messages")
        .add(this.collMessage)
        .then(() => {
          this.newmessage = "";
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
<style scoped>
.speech-bubble {
  position: relative;
  width: 100%;
  background: #b9eca7;
  border-radius: 20px;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  margin-top: -12px;
  margin-right: -20px;
  border: 24px solid transparent;
  border-right: 0;
  border-bottom: 0;
  border-left-color: #b9eca7;
}
.reciver-bubble {
  position: relative;
  width: 100%;
  background: #d1d1d1;
  border-radius: 20px;
}

.reciver-bubble:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  margin-top: -12px;
  margin-left: -20px;
  border: 24px solid transparent;
  border-bottom: 0;
  border-left: 0;
  border-right-color: #d1d1d1;
}
</style>

<template>
  <div>
    <v-navigation-drawer
      src="@/assets/parentbg.jpg"
      style="z-index:2;position:absolute;"
      height="70%"
      width="100%"
      permanent
    >
      <div style="padding-left:30px; padding-top:50px; ">
        <v-row align="center">
          <v-col cols="2" lg="1">
            <img :src="this.$store.state.logo" width="70px" />
          </v-col>
          <v-col cols="3">
            <h3>
              Welcome
              {{ this.$store.state.parentname }},
            </h3>
            Parent

            <div></div>
          </v-col>
          <v-spacer /><SwitchLocale />
        </v-row>
      </div>
    </v-navigation-drawer>
    <v-container>
      <v-row
        style="position:absolute; z-index:200; margin-top:200px;  margin-left:20px; width:100%;"
      >
        <v-col cols="8">
          <v-card flat height="800px" v-if="boards.length > 0">
            <v-card-text>
              <v-list dense style="max-height: 700px;  ">
                <v-list-item-group color="primary">
                  <div style="overflow: auto; height: 700px;">
                    <v-list-item
                      three-line
                      v-for="student in boards"
                      :key="student.id"
                      @click="
                        goToSon(
                          student.firstname,
                          student.lastname,
                          student.branch,
                          student.grade,
                          student.class,
                          student.path
                        )
                      "
                    >
                      <v-card
                        width="100%"
                        shaped
                        outlined
                        flat
                        style="width='500px'; margin-bottom:50px; background-color:#d7e9f9; margin-right:20px;"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="3">
                              <img
                                :src="require('@/assets/student.png')"
                                width="80px"
                              />
                            </v-col>
                            <v-col cols="9">
                              <h5>
                                {{ student.firstname + " " + student.lastname }}
                              </h5>
                              <span style="color:#008719">
                                {{ student.branch }}</span
                              >
                              <p style="color:#008719">
                                {{ student.grade }} Class {{ student.class }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list-item>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>

          <v-sheet v-else color="darken-2" class="pa-3" height="800px">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import SwitchLocale from "@/components/dna/SwitchLocale.vue";

export default {
  name: "Home-page",
  components: { SwitchLocale },

  data() {
    return {
      admission: [],
      schools: [],

      level: [],
      students: [],
      employees: [],
      fleet: [],
      errors: [],
      boards: [],
      gclass: [],
      icon: "",
      domain: "",
      timestamp: "",

      selectedschool: "",
      selectedgrade: "",

      langs: ["en", "be", "da", "hr"]
    };
  },
  created() {
    this.domain = window.location.host;
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/StudentsData")
      .where("father_nationalID", "==", this.$store.state.parentid)
      .get()
      .then(querySnapshot => {
        this.boards = [];
        querySnapshot.forEach(doc => {
          this.boards.push({
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            branch: doc.data().branch,
            grade: doc.data().grade,
            class: doc.data().class
          });
        });
      });
  },
  methods: {
    goToSon(firstname, lastname, beanch, grade, sclass, path) {
      this.$store.commit("sonfirst", firstname);
      this.$store.commit("sonlast", lastname);
      this.$store.commit("sonbranch", beanch);
      this.$store.commit("songrade", grade);
      this.$store.commit("sonclass", sclass);
      this.$store.commit("sonpath", path);

      this.$store.commit("studentpath", path);
      this.$store.commit("grade", grade);

      router.push({
        name: "SonDash"
      });
    },
    goToCalendar() {
      router.push({ name: "Calendar" });
    },
    goToWeekly() {
      router.push({ name: "Weekly" });
    },

    goLeaderboard() {
      router.push({ name: "Leaderboard" });
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    }
  }
};
</script>
<style scoped>
.body {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(24, 146, 0, 0.904);
  box-shadow: 0 0 1px rgba(24, 146, 0, 0.904);
}
</style>

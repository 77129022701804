<template>
  <div>
    <v-card flat outlined style="margin-bottom:10px">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-slide-group multiple show-arrows>
          <v-slide-item v-for="v in videos" :key="v.key">
            <a :href="v.link" target="_blank">
              <v-card
                class="ma-5 d-flex align-center"
                style="cursor: pointer; text-align:center;"
                flat
                hover
                outlined
                height="200"
                width="200"
              >
                <v-card-text>
                  <img
                    src="@/assets/ppt.png"
                    width="50px"
                    style="margin-bottom:20px"
                  />
                  <br />
                  {{ v.title }}
                </v-card-text>
              </v-card>
            </a>
          </v-slide-item>
        </v-slide-group>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "WeekVideos",
  props: ["path", "title"],
  data() {
    return { videos: [], type: this.$store.state.videotype, videospath: "" };
  },
  created() {
    firebase
      .firestore()
      .collection(this.path + "/sheets")
      .get()
      .then(querySnapshot => {
        this.videos = [];
        querySnapshot.forEach(doc => {
          this.videos.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            link: doc.data().link,
            section: doc.data().section,
            description: doc.data().description
          });
        });
      });
  },

  methods: {}
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-card flat>
      <!-- <v-row>
        <v-spacer />
        <v-btn
          color="error"
          style="margin-right:20px;"
          small
          depressed
          @click="skipQuestion"
          v-if="$store.state.answered == false"
          >Skip Question</v-btn
        >
        
        </v-row
      > -->
      <v-chip small>{{ question.bloom }}</v-chip>
      <v-chip color="success" small
        >Mental Power {{ bloomlist.get(question.bloom) }}</v-chip
      >

      <img
        :src="require(`@/assets/bloom/${bloomlist.get(question.bloom)}.png`)"
        width="30px"
      />

      <v-card-title>
        <audio style="margin-left:10px"  controls v-if="this.question_sound">
                  <source :src="this.question_sound" />
                </audio>
        <div
          v-katex:auto
          v-html="this.question_body"
         class="
          fooar
          "
        ></div>
      </v-card-title>

      <v-card-text>

        <!-- Multiple Choice -->
        <div v-if="question.qtype == 'mc' || question.qtype == 'listening' || question.qtype == null">
          <v-list-item-group>
            <!-- Options Before Answers-->
            <div v-if="$store.state.answered == false">
              <v-list-item
               class="fooar"
                v-for="option in options"
                :key="option.key"
                @click="
                  selectedAnswer = option.key;
                  selectedGrade = option.grade;
                "
              >
              <v-list-item-content v-if="option.sound">
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                       <audio style="margin-left:10px" controls>
                          <source :src="option.sound" />
                        </audio>

                      <v-btn
                      v-if="!option.title"
                        style="height: 50px; margin-left: 10px"
                        rounded
                        x-small
                        depressed
                        color="info"
                        @click="checkAnswer(selectedGrade)"
                      >
                        <v-icon>mdi-check-all</v-icon> {{ $t("Confirm") }} </v-btn
                      >
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <audio  style="margin-left:10px"  controls>
                        <source :src="option.sound"/>
                      </audio>
                    </div>
                  </h6>
                </v-list-item-content>
                <v-list-item-content v-if="option.title">
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn v-if="!option.sound" color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-chip
                        outlined
                        style="min-width:50%; height:50px; margin-left:10px;"
                        color="success"
                        text-color="black"
                      >
                        {{ option.title }}</v-chip
                      >

                      <v-btn
                        style="height:50px;  margin-left:10px;"
                        rounded
                        x-small
                        depressed
                        color="info"
                        @click="checkAnswer(selectedGrade)"
                      >
                        <v-icon>mdi-check-all</v-icon> {{ $t("Confirm") }} </v-btn
                      >
                    </div>
                    <div v-else>
                      <v-btn v-if="!option.sound" fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <v-chip
                        color="primary"
                        style="min-width:50%; height:50px; margin-left:10px;"
                        outlined
                        text-color="black"
                        >{{ option.title }}</v-chip
                      >
                    </div>
                  </h6>
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- Options After Answers-->
            <div v-else>
              <v-list-item
                class="fooar"
                v-for="option in options"
                :key="option.key"
              >
              <v-list-item-content v-if="option.sound">
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <audio
                      style="margin-left:10px" controls>
                  <source :src="option.sound" />
                </audio>
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>

                      <audio style="margin-left:10px"  controls>
                        <source :src="option.sound"/>
                      </audio>
                    </div>
                  </h6>
                </v-list-item-content>
                <v-list-item-content v-if="option.title">
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn v-if="!option.sound" color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-chip
                        dark
                        style="min-width:50%; height:50px; margin-left:10px;"
                        :color="option.grade == 1 ? 'success' : 'error'"
                        text-color="white"
                      >
                        {{ option.title }}</v-chip
                      >
                    </div>
                    <div v-else>
                      <v-btn v-if="!option.sound" fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <v-chip
                        :color="option.grade == 1 ? 'success' : 'error'"
                        style="min-width:50%; height:50px; margin-left:10px;"
                        text-color="white"
                        >{{ option.title }}</v-chip
                      >
                    </div>
                  </h6>
                </v-list-item-content>

              </v-list-item>

            </div>
          </v-list-item-group>
        </div>
          <!-- Listening -->
        <!-- <div v-if="question.qtype == 'listening'">
          <v-list-item-group>
            <div v-if="$store.state.answered == false">
              <v-list-item
                class="fooar"
                v-for="option in options"
                :key="option.key"
                @click="
                  selectedAnswer = option.key;
                  selectedGrade = option.grade;
                "
              >
                <v-list-item-content>
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      

                       <Audio style="margin-left:10px" :src="option.sound" controls></Audio>

                      <v-btn
                        style="height: 50px; margin-left: 10px"
                        rounded
                        x-small
                        depressed
                        color="info"
                        @click="checkAnswer(selectedGrade)"
                      >
                        <v-icon>mdi-check-all</v-icon> {{ $t("Confirm") }} </v-btn
                      >
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <Audio style="margin-left:10px" :src="option.sound" controls></Audio>
                    </div>
                  </h6>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div v-else>
              <v-list-item
                class="fooar"
                v-for="option in options"
                :key="option.key"
              >
                <v-list-item-content>
                  <h6>
                    <div v-if="option.key == selectedAnswer">
                      <v-btn color="info" fab dark small depressed>
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                     <Audio style="margin-left:10px" :src="option.sound" controls></Audio>
                    </div>
                    <div v-else>
                      <v-btn fab small depressed>
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                      </v-btn>
                      <Audio style="margin-left:10px" :src="option.sound" controls></Audio>
                    </div>
                  </h6>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-item-group>
        </div> -->
        <!-- True/False -->
        <div v-else-if="question.qtype == 'tf'">
          <v-btn
            class="mx-2"
            fab
            dark
            x-large
            color="red"
            depressed
            @click="checkRight(question.correct, 'wrong')"
          >
            <v-icon dark>
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            dark
            x-large
            color="green"
            depressed
            @click="checkRight(question.correct, 'right')"
          >
            <v-icon dark>
              mdi-check
            </v-icon>
          </v-btn>
        </div>

        <!-- Complete -->
        <div v-else-if="question.qtype == 'complete'">
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="The Missing Word"
                outlined
                rounded
                v-model="missing"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" style="text-align:right">
              <v-btn
                depressed
                rounded
                color="green"
                dark
                @click="checkComplete(question.correct, missing)"
                >Check Answer</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <!-- Complete -->
        <div v-else-if="question.qtype == 'essay'">
          <v-row>
            <v-col cols="6">
              <v-textarea
                label="Write Your Answer"
                outlined
                v-model="missing"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" style="text-align:right">
              <v-btn
                depressed
                rounded
                color="green"
                dark
                @click="checkComplete(missing, path)"
                >Submit Answer</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <br />
        <br />

        <div>
          <v-alert
            :value="wrong"
            color="pink"
            dark
            border="top"
            icon="mdi-alert-circle"
            >{{ $t("Your Answer is Incorrect")  }}</v-alert
          >

          <v-alert
            :value="right"
            color="green"
            dark
            border="top"
            icon="mdi-checkbox-marked-circle-outline"
            >{{ $t("Your Answer is Correct")  }}</v-alert
          >
        </div>
        <!-- Answer Feedback -->
        <div
          v-if="this.right == true || this.wrong == true"
          style="text-align:center; "
        >
          <!--  <v-alert
            text
            dense
            color="teal"
            icon="mdi-clock-fast"
            border="left"
            v-html="question.answer"
            class="foo"
          >-->

          <div
            v-katex:auto
            v-html="question_answer"
            class="fooar"
          ></div>
          <!--</v-alert>-->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "Qustion-body",
  props: ["path","attempts", "source", "student", "subject","totalAttempts"],

  data() {
    return {
      missing: "",
      selectedAnswer: "",
      selectedGrade: "",
      questionID: "",
      wrong: false,
      right: false,
      question: {},
      missed: false,
      options: [],
      answer: [],
      refOptions: firebase.firestore().collection(this.path + "/options/"),
      ref: firebase.firestore().doc(this.path),
      studentpath: this.$store.state.studentpath,
      question_sound: "",
      question_body: "",
      question_answer: "",
      bloomlist: new Map([
        ["Knowledge (Remembering)", 1],
        ["Comprehension (Understanding)", 2],
        ["Application (Transferring)", 4],
        ["Analysis (Relating)", 8],
        ["Evaluation (Judging)", 16],
        ["Synthesis (Creating)", 32]
      ])
    };
  },

  created() {
    this.$store.state.answered = false;

    this.ref.get().then(doc => {
      if (doc.exists) {
        console.log("question details: ",doc.data())
        this.question = doc.data();
        this.questionID = doc.id;
        this.question_sound = doc.data().sound;
        firebase
          .firestore()
          .doc(this.path + "/details/details")
          .get()
          .then(querySnapshot => {
            this.question_body = querySnapshot.data().body;
            this.question_answer = querySnapshot.data().answer;
          });
      } else {
        // alert("No such document!");
      }
    });

    this.refOptions.get().then(querySnapshot => {
      this.options = [];
      querySnapshot.forEach(doc => {
        console.log("question options: ",doc.data())
        if(doc.data().sound){
          this.options.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          grade: doc.data().grade,
          sound: doc.data().sound
        });
        }else{
          this.options.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          grade: doc.data().grade
        });
        }

      });
    });

  },

  methods: {
    skipQuestion() {
      if (this.$store.state.answered == false) {
        var today = new Date();

        this.$store.state.answered = true;

        this.answer.qtype = "mc";
        this.answer.difficulty =
          this.question.difficulty == null ? "" : this.question.difficulty;
        this.answer.question = this.path;
        this.answer.class = this.$store.state.sclass;
        this.answer.bloom =
          this.question.bloom == null ? "" : this.question.bloom;
        this.answer.dok = this.question.dok == null ? "" : this.question.dok;
        this.answer.standard =
          this.question.standard == null ? "" : this.question.standard;

        this.answer.date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        this.answer.time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        this.answer.subjectPath = this.$store.state.subject;
        this.answer.subjectTitle = this.$store.state.subjecttitle;
        this.answer.topic = this.$store.state.weektitle;
        this.answer.topicPath = this.$store.state.weekpath;
        this.answer.grade = this.$store.state.grade;
        this.answer.class = this.$store.state.sclass;
        this.answer.educationPath = this.$store.state.educationPath;
        this.answer.source = this.source;
        this.answer.studentId = this.$store.state.email;
        this.answer.branch = localStorage.getItem("branch");
        this.answer.school = this.$store.state.school;
        this.answer.answer = "Missed";
        this.answer.attempts = this.attempts;
        
        this.$store.state.missedAnswers++;
        firebase
          .firestore()
          .collection(this.studentpath + "/answers")
          .doc(this.questionID)
          .set({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            this.$swal(
              {
                icon: "info",
                title: "Question Skipped",
                html: "You Can Continue now",
                timer: 2000
              }
            );
            // firebase
            //   .firestore()
            //   .collection(this.student + "/questions")
            //   .doc(this.questionID)
            //   .set({ ...this.answer })
            //   .then(() => {
            //     this.missed = true;
            //   });
          })
          .catch(() => {});
      }
    },

    //! Multiple Choice Qoustions
    checkAnswer(grade) {
      try {
        if (this.$store.state.answered == false) {
          var today = new Date();

          this.$store.state.answered = true;

          this.answer.qtype = "mc";
          this.answer.difficulty =
            this.question.difficulty == null ? "" : this.question.difficulty;
          this.answer.question = this.path;
          this.answer.class = this.$store.state.sclass;
          this.answer.bloom =
            this.question.bloom == null ? "" : this.question.bloom;
          this.answer.dok = this.question.dok == null ? "" : this.question.dok;
          this.answer.standard =
            this.question.standard == null ? "" : this.question.standard;

          this.answer.date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
          this.answer.time =
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds();
          this.answer.subjectPath = this.$store.state.subject;
          this.answer.subjectTitle = this.$store.state.subjecttitle;
          this.answer.topic = this.$store.state.weektitle;
          this.answer.topicPath = this.$store.state.weekpath;
          this.answer.grade = this.$store.state.grade;
          this.answer.class = this.$store.state.sclass;
          this.answer.educationPath = this.$store.state.educationPath;
          this.answer.source = this.source;
          this.answer.studentId = this.$store.state.email;
          this.answer.branch = localStorage.getItem("branch");
          this.answer.school = this.$store.state.school;
          this.answer.breakdown = this.$store.state.lessonbreakdown;
          this.answer.attempts = this.attempts;
          if (this.right == false && this.wrong == false) {
            if (grade == "1") {
              this.answer.answer = "Right";

              this.right = true;
              this.wrong = false;
              this.$store.state.rightAnswers++;

              this.$swal(
                {
                  icon: "success",
                  title: this.$t("Right Answer"),
                  html: this.$t("Your Answer is Correct"),
                  timer: 2000
                }
              );
            } else {
              //Add the record to student answers Collection

              this.answer.answer = "Wrong";

              this.right = false;
              this.wrong = true;
              this.$store.state.wrongAnswers++;

              this.$swal(
                {
                  icon: "error",
                  title: this.$t("Wrong Answer"),
                html: this.$t("Your Answer is Incorrect"),
                  timer: 2000
                }
              );
            }

            //! Update submissions with right and wrong
            firebase
            .firestore()
            .collection(this.$store.state.oraginalsubject + "/submissions")
            .doc(this.$store.state.assid)
            .get()
            .then(snapshot => {
              if(snapshot.exists){
                firebase
            .firestore()
            .collection(this.$store.state.oraginalsubject + "/submissions")
            .doc(this.$store.state.assid)
            .update({
       right: this.$store.state.rightAnswers,
        wrong: this.$store.state.wrongAnswers,
        missed: this.$store.state.missedAnswers,
       score:(this.$store.state.rightAnswers / (this.$store.state.rightAnswers + this.$store.state.wrongAnswers)) * 100,
            })
            .then(() => {
              //! Add Attemp Record to students --> assignments Collection
              firebase
                .firestore()
                .doc(
                  this.$store.state.oraginalsubject +
                    "/submissions/" +
                    this.$store.state.email +
                    this.$store.state.assid
                )
                .update({   
       right: this.$store.state.rightAnswers,
        wrong: this.$store.state.wrongAnswers,
        missed: this.$store.state.missedAnswers,
       score:(this.$store.state.rightAnswers / (this.$store.state.rightAnswers + this.$store.state.wrongAnswers)) * 100
      })
                .then(() => {
                })
                .catch(() => {});
            });
              }
            })


//!         Add Question to student --> assignment Collection-------------------------------------------------------  
            firebase
          .firestore()
          .collection(this.$store.state.subject + "/slink")
          .where("source", "==", this.path)
          .get()
          .then((standards) => {
            standards.forEach((standard) => {
             console.log("standards: ",standard.data().standard)

              var right = 0;
              var wrong = 0;

              if (this.answer.answer == "Right") {
                wrong = firebase.firestore.FieldValue.increment(0);
                right = firebase.firestore.FieldValue.increment(1);
              } else {
                wrong = firebase.firestore.FieldValue.increment(1);
                right = firebase.firestore.FieldValue.increment(0);
              }

              firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes")
                .doc(this.$store.state.assid)
                .collection("weakness")
                .doc(standard.data().standard)
                .set(
                  {
                    standard: standard.data().standard,
                    parent: standard.data().parent,
                    right: right,
                    wrong: wrong,
                    student: this.$store.state.email,
                    subjectPath: this.$store.state.subject,
                    subjectTitle: this.$store.state.subjecttitle,
                    attempts:this.attempts
                  },
                  { merge: true }
                )
                .then(() => {
                  //! Add Question to student --> assignment Collection
                  firebase
                    .firestore()
                    .collection(this.student + "/questions")
                    .doc(this.questionID)
                    .set({ ...this.answer })
                    .then(() => {});
                })
                firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .where("standard","==",standard.data().standard)
                .where("attempts","==",this.attempts)
                .get()
                .then((querySnapshot) => {
              if(querySnapshot.empty){
                console.log("empty database")
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .add(
                  {
                    standard: standard.data().standard,
                    parent: standard.data().parent,
                    right: right,
                    wrong: wrong,
                    student: this.$store.state.email,
                    subjectPath: this.$store.state.subject,
                    subjectTitle: this.$store.state.subjecttitle,
                    attempts:this.attempts
                  }
                )
                .then(() => {
                  firebase
                    .firestore()
                    .collection(this.student + "/questions")
                    .doc(this.questionID)
                    .set({ ...this.answer })
                })
                  }else{
                    console.log("exist database", querySnapshot.id)
                    querySnapshot.forEach(el => {
                      firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .doc(el.id)
                .update(
                  {
                    right: right,
                    wrong: wrong,
                  }
                )
                .then(() => {
                  firebase
                    .firestore()
                    .collection(this.student + "/questions")
                    .doc(this.questionID)
                    .set({ ...this.answer })
                })
                    })
                  }
                })
                // if(this.attempts <= this.totalAttempts){
                //   firebase
                // .firestore()
                // .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                // // .doc(this.$store.state.assid)
                // // .collection("weakness_new")
                // // .doc(this.questionID)
                // .add(
                //   {
                //     standard: standard.data().standard,
                //     parent: standard.data().parent,
                //     right: right,
                //     wrong: wrong,
                //     student: this.$store.state.email,
                //     subjectPath: this.$store.state.subject,
                //     subjectTitle: this.$store.state.subjecttitle,
                //     attempts:this.attempts
                //   }
                // )
                // .then(() => {
                //   //! Add Question to student --> assignment Collection
                //   firebase
                //     .firestore()
                //     .collection(this.student + "/questions")
                //     .doc(this.questionID)
                //     .set({ ...this.answer })
                //     .then(() => {});
                // })
                // .catch(() => {});
                // }else{
                //   firebase
                // .firestore()
                // .collection(this.$store.state.studentpath + "/quizes")
                // .doc(this.$store.state.assid)
                // .collection("weakness_new")
                // .doc(this.questionID)
                // .set(
                //   {
                //     standard: standard.data().standard,
                //     parent: standard.data().parent,
                //     right: right,
                //     wrong: wrong,
                //     student: this.$store.state.email,
                //     subjectPath: this.$store.state.subject,
                //     subjectTitle: this.$store.state.subjecttitle,
                //     attempts:this.attempts
                //   }
                // )
                // .then(() => {
                //   //! Add Question to student --> assignment Collection
                //   firebase
                //     .firestore()
                //     .collection(this.student + "/questions")
                //     .doc(this.questionID)
                //     .set({ ...this.answer })
                //     .then(() => {});
                // })
                // .catch(() => {});
                // }

            });
            console.log(standards);
          });

        firebase
          .firestore()
          .collection(this.$store.state.studentpath + "/quizes")
          .doc(this.$store.state.assid)
          .collection("answers")
          .doc(this.questionID)
          .set({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            firebase
              .firestore()
              .collection(this.student + "/questions")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {});
          })
          .catch(() => {});
          console.log(this.totalAttempts)
          if(this.attempts <= this.totalAttempts){
            firebase
          .firestore()
          .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/answers_new")
          .add({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            firebase
              .firestore()
              .collection(this.student + "/questions")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {});
          })
          .catch(() => {});
          }else{
            firebase
          .firestore()
          .collection(this.$store.state.studentpath + "/quizes")
          .doc(this.$store.state.assid)
          .collection("answers_new")
          .doc(this.questionID)
          .set({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            firebase
              .firestore()
              .collection(this.student + "/questions")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {});
          })
          .catch(() => {});
          }

          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    //! Complete Qustions
    checkComplete(answer, question) {
      if (this.$store.state.answered == false) {
        this.$store.state.inclasssanswers.push({ answer, question });
      }

      this.$store.state.answered = true;

      // var today = new Date();
      // this.$store.state.answered = true;

      // //Add the record to student answers Collection
      // this.answer.qtype = "Easy";
      // this.answer.difficulty =
      //   this.question.difficulty == null ? "" : this.question.difficulty;
      // this.answer.question = this.path;
      // this.answer.class = this.$store.state.sclass;
      // this.answer.bloom =
      //   this.question.bloom == null ? "" : this.question.bloom;
      // this.answer.dok = this.question.dok == null ? "" : this.question.dok;
      // this.answer.standard =
      //   this.question.standard == null ? "" : this.question.standard;

      // this.answer.date =
      //   today.getFullYear() +
      //   "-" +
      //   (today.getMonth() + 1) +
      //   "-" +
      //   today.getDate();
      // this.answer.time =
      //   today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      // this.answer.subjectPath = this.$store.state.subject;
      // this.answer.subjectTitle = this.$store.state.subjecttitle;
      // this.answer.topic = this.$store.state.weektitle;
      // this.answer.topicPath = this.$store.state.weekpath;
      // this.answer.grade = this.$store.state.grade;
      // this.answer.class = this.$store.state.sclass;
      // this.answer.educationPath = this.$store.state.educationPath;
      // this.answer.source = this.source;
      // this.answer.studentId = this.$store.state.email;
      // this.answer.branch = localStorage.getItem("branch");
      // this.answer.school = this.$store.state.school;
      // //End of answers Collection

      // if (answer.trim().toLowerCase() == action.trim().toLowerCase()) {
      //   this.answer.answer = "Right";
      //   this.right = true;
      //   this.wrong = false;
      //   this.$store.state.rightAnswers++;
      //   this.$store.state.lessonrightAnswers++;
      // } else {
      //   //Add the record to student answers Collection

      //   this.answer.answer = "Wrong";

      //   this.right = false;
      //   this.wrong = true;
      //   this.$store.state.wrongAnswers++;
      //   this.$store.state.lessonwrongAnswers++;
      // }

      // firebase
      //   .firestore()
      //   .collection(this.studentpath + "/answers")
      //   .doc(this.questionID)
      //   .set({ ...this.answer })
      //   .then(() => {
      //     //! Add Question to student --> assignment Collection
      //     firebase
      //       .firestore()
      //       .collection(this.student + "/questions")
      //       .doc(this.questionID)
      //       .set({ ...this.answer })
      //       .then(() => {});
      //   })
      //   .catch(() => {});
    },

    updatePoints() {
      //! increment points
      const increment = firebase.firestore.FieldValue.increment(1);
      try {
        firebase
          .firestore()
          .doc(this.$store.state.studentpath)
          .update({ points: increment })
          .then(() => {})
          .catch(e => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
      //! End increment points
    },

    //! True/ False Qoustions
    checkRight(answer, action) {
      if (this.$store.state.answered == false) {
        var today = new Date();

        this.$store.state.answered = true;
        //Add the record to student answers Collection
        this.answer.qtype = "True/ False";
        this.answer.difficulty =
          this.question.difficulty == null ? "" : this.question.difficulty;
        this.answer.question = this.path;
        this.answer.class = this.$store.state.sclass;
        this.answer.bloom =
          this.question.bloom == null ? "" : this.question.bloom;
        this.answer.dok = this.question.dok == null ? "" : this.question.dok;
        this.answer.standard =
          this.question.standard == null ? "" : this.question.standard;

        this.answer.date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        this.answer.time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        this.answer.subjectPath = this.$store.state.subject;
        this.answer.subjectTitle = this.$store.state.subjecttitle;
        this.answer.topic = this.$store.state.weektitle;
        this.answer.topicPath = this.$store.state.weekpath;
        this.answer.grade = this.$store.state.grade;
        this.answer.class = this.$store.state.sclass;
        this.answer.educationPath = this.$store.state.educationPath;
        this.answer.source = this.source;
        this.answer.studentId = this.$store.state.email;
        this.answer.branch = localStorage.getItem("branch");
        this.answer.school = this.$store.state.school;
        this.answer.breakdown = this.$store.state.lessonbreakdown;
          this.answer.attempts = this.attempts;

        //End of answers Collection

        if (
          (answer == "true" && action == "right") ||
          (answer == "false" && action == "wrong")
        ) {
          this.answer.answer = "Right";
          this.right = true;
          this.wrong = false;
          this.$store.state.rightAnswers++;
          this.$store.state.lessonrightAnswers++;

          if (this.source == "flipped") {
            this.$store.state.flippedrightAnswers++;
          } else if (this.source == "exit") {
            this.$store.state.exitrightAnswers++;
          }

          this.$swal(
            {
              icon: "success",
              title: this.$t("Right Answer"),
                html: this.$t("Your Answer is Correct"),
              timer: 2000
            }
          );
        } else {
          this.answer.answer = "Wrong";

          this.right = false;
          this.wrong = true;
          this.$store.state.wrongAnswers++;
          this.$store.state.lessonwrongAnswers++;

          this.$swal(
            {
              icon: "error",
              title: "Wrong Answer",
              html: this.$t("Your Answer is Incorrect"),
              timer: 2000
            }
          );
        }

        this.storeStandards(
          this.answer.answer,
          this.answer.studentId,
          this.answer.bloom,
          this.questionID
        );
            //! Update submissions with right and wrong
            firebase
            .firestore()
            .collection(this.$store.state.oraginalsubject + "/submissions")
            .doc(this.$store.state.assid)
            .get()
            .then(snapshot => {
              if(snapshot.exists){
                firebase
            .firestore()
            .collection(this.$store.state.oraginalsubject + "/submissions")
            .doc(this.$store.state.assid)
            .update({
       right: this.$store.state.rightAnswers,
        wrong: this.$store.state.wrongAnswers,
        missed: this.$store.state.missedAnswers,
       score:(this.$store.state.rightAnswers / (this.$store.state.rightAnswers + this.$store.state.wrongAnswers)) * 100,
            })
            .then(() => {
              //! Add Attemp Record to students --> assignments Collection
              firebase
                .firestore()
                .doc(
                  this.$store.state.oraginalsubject +
                    "/submissions/" +
                    this.$store.state.email +
                    this.$store.state.assid
                )
                .update({   
       right: this.$store.state.rightAnswers,
        wrong: this.$store.state.wrongAnswers,
        missed: this.$store.state.missedAnswers,
       score:(this.$store.state.rightAnswers / (this.$store.state.rightAnswers + this.$store.state.wrongAnswers)) * 100
      })
                .then(() => {
                })
                .catch(() => {});
            });
              }
            })


        firebase
          .firestore()
          .collection(this.studentpath + "/answers")
          .doc(this.questionID)
          .set({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            firebase
              .firestore()
              .collection(this.student + "/questions")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {});




              //!         Add Question to student --> assignment Collection-------------------------------------------------------  
            firebase
          .firestore()
          .collection(this.$store.state.subject + "/slink")
          .where("source", "==", this.path)
          .get()
          .then((standards) => {
            standards.forEach((standard) => {
              //? Kamal Record Weeknees

              var right = 0;
              var wrong = 0;

              if (this.answer.answer == "Right") {
                wrong = firebase.firestore.FieldValue.increment(0);
                right = firebase.firestore.FieldValue.increment(1);
              } else {
                wrong = firebase.firestore.FieldValue.increment(1);
                right = firebase.firestore.FieldValue.increment(0);
              }

              firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes")
                .doc(this.$store.state.assid)
                .collection("weakness")
                .doc(standard.data().standard)
                .set(
                  {
                    standard: standard.data().standard,
                    parent: standard.data().parent,
                    right: right,
                    wrong: wrong,
                    student: this.$store.state.email,
                    subjectPath: this.$store.state.subject,
                    subjectTitle: this.$store.state.subjecttitle,
                    attempts:this.attempts
                  },
                  { merge: true }
                )
                .then(() => {
                  //! Add Question to student --> assignment Collection
                  firebase
                    .firestore()
                    .collection(this.student + "/questions")
                    .doc(this.questionID)
                    .set({ ...this.answer })
                    .then(() => {});
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes")
                .doc(this.$store.state.assid)
                .collection("weakness")
                .doc(standard.data().standard)
                .set(
                  {
                    standard: standard.data().standard,
                    parent: standard.data().parent,
                    right: right,
                    wrong: wrong,
                    student: this.$store.state.email,
                    subjectPath: this.$store.state.subject,
                    subjectTitle: this.$store.state.subjecttitle,
                    attempts:this.attempts
                  }
                )
                .then(() => {
                  //! Add Question to student --> assignment Collection
                  firebase
                    .firestore()
                    .collection(this.student + "/questions")
                    .doc(this.questionID)
                    .set({ ...this.answer })
                    .then(() => {});
                })
                firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .where("standard","==",standard.data().standard)
                .where("attempts","==",this.attempts)
                .get()
                .then((querySnapshot) => {
              if(querySnapshot.empty){
                console.log("empty database")
                    firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .add(
                  {
                    standard: standard.data().standard,
                    parent: standard.data().parent,
                    right: right,
                    wrong: wrong,
                    student: this.$store.state.email,
                    subjectPath: this.$store.state.subject,
                    subjectTitle: this.$store.state.subjecttitle,
                    attempts:this.attempts
                  }
                )
                .then(() => {
                  firebase
                    .firestore()
                    .collection(this.student + "/questions")
                    .doc(this.questionID)
                    .set({ ...this.answer })
                })
                  }else{
                    console.log("exist database", querySnapshot.id)
                    querySnapshot.forEach(el => {
                      firebase
                .firestore()
                .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                .doc(el.id)
                .update(
                  {
                    right: right,
                    wrong: wrong,
                  }
                )
                .then(() => {
                  firebase
                    .firestore()
                    .collection(this.student + "/questions")
                    .doc(this.questionID)
                    .set({ ...this.answer })
                })
                    })
                  }
                })
                //     if(this.attempts <= this.totalAttempts){
                // firebase
                // .firestore()
                // .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/weakness_new")
                // .add(
                //   {
                //     standard: standard.data().standard,
                //     parent: standard.data().parent,
                //     right: right,
                //     wrong: wrong,
                //     student: this.$store.state.email,
                //     subjectPath: this.$store.state.subject,
                //     subjectTitle: this.$store.state.subjecttitle,
                //     attempts:this.attempts
                //   }
                // )
                // .then(() => {
                //   //! Add Question to student --> assignment Collection
                //   firebase
                //     .firestore()
                //     .collection(this.student + "/questions")
                //     .doc(this.questionID)
                //     .set({ ...this.answer })
                //     .then(() => {});
                // })
                // .catch(() => {});
                //     }else{
                //       firebase
                // .firestore()
                // .collection(this.$store.state.studentpath + "/quizes")
                // .doc(this.$store.state.assid)
                // .collection("weakness_new")
                // .doc(this.questionID)
                // .set(
                //   {
                //     standard: standard.data().standard,
                //     parent: standard.data().parent,
                //     right: right,
                //     wrong: wrong,
                //     student: this.$store.state.email,
                //     subjectPath: this.$store.state.subject,
                //     subjectTitle: this.$store.state.subjecttitle,
                //     attempts:this.attempts
                //   }
                // )
                // .then(() => {
                //   //! Add Question to student --> assignment Collection
                //   firebase
                //     .firestore()
                //     .collection(this.student + "/questions")
                //     .doc(this.questionID)
                //     .set({ ...this.answer })
                //     .then(() => {});
                // })
                // .catch(() => {});
                //     }

                })
                .catch(() => {});
            });
            console.log(standards);
          });

        firebase
          .firestore()
          .collection(this.$store.state.studentpath + "/quizes")
          .doc(this.$store.state.assid)
          .collection("answers")
          .doc(this.questionID)
          .set({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            firebase
              .firestore()
              .collection(this.student + "/questions")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {});
          })
          .catch(() => {});
          if(this.attempts <= this.totalAttempts){
            firebase
          .firestore()
          .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/answers_new")
          //.doc(this.$store.state.assid)
          //.collection("answers_new")
          // .doc(this.questionID)
          .add({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            firebase
              .firestore()
              .collection(this.student + "/questions")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {});
          })
          .catch(() => {});
          }else{
            firebase
          .firestore()
          .collection(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/answers_new")
          // .doc(this.$store.state.assid)
          // .collection("answers_new")
          //  .doc(this.questionID)
          .add({ ...this.answer })
          .then(() => {
            //! Add Question to student --> assignment Collection
            firebase
              .firestore()
              .collection(this.student + "/questions")
              .doc(this.questionID)
              .set({ ...this.answer })
              .then(() => {});
          })
          .catch(() => {});
          }

//!         Add Question to student --> assignment Collection-------------------------------------------------------  

          })
          .catch(() => {});
      }
    },

    //! Store Standard Rank
    storeStandards(answer, student, bloom, question) {
      var points = 0;

      if (bloom == "Knowledge (Remembering)") {
        points = 1;
      } else if (bloom == "Comprehension (Understanding)") {
        points = 2;
      } else if (bloom == "Application (Transferring)") {
        points = 4;
      } else if (bloom == "Analysis (Relating)") {
        points = 8;
      } else if (bloom == "Evaluation (Judging)") {
        points = 16;
      } else if (bloom == "Synthesis (Creating)") {
        points = 32;
      }

      firebase
        .firestore()
        .collection(this.$store.state.subject + "/slink")
        .where("source", "==", this.path)
        .get()
        .then(standards => {
          standards.forEach(standard => {
            console.log(standard.data().standard);
            firebase
              .firestore()
              .doc(
                "schools/" +
                  this.$store.state.school +
                  "/StudentsData/" +
                  student +
                  "/srank/" +
                  question +
                  this.$store.state.subjecttitle
              )
              .set({
                answer: answer,
                standard: standard.data().standard,
                student: student + "",
                school: this.$store.state.school,
                branch: this.$store.state.educationPath.split("/")[3],
                grade: this.$store.state.grade,
                classroom: this.$store.state.sclass,
                subject: this.$store.state.subjecttitle,
                bloom: bloom,
                points: answer == "Wrong" ? 0 : points
              });
          });
        });
    }
  }
};
</script>

<style scoped>
.fooar >>> img {
  width: 400px;
}
.fooar {
  text-align: right;
  direction: rtl;
  width: 100%;
}
.foo >>> img {
  width: 400px;
}
</style>

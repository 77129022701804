import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store/index.js";
import Page from "@/Page";
import ExamHome from "@/Exam";
import EmptyHome from "@/Empty";
import SubjectHome from "@/Subject";

//Subject Dashboard
import SubjectDash from "@/components/lms/subjects/SubjectDashboard";
import WeekPage from "@/components/lms/subjects/WeekPage";
import ReviewPage from "@/components/lms/review/ReviewPage";
import FlippedIntro from "@/components/lms/subjects/FlippedIntro";
import InclassIntro from "@/components/lms/subjects/InclassIntro";
import ExitIntro from "@/components/lms/subjects/ExitIntro";
import LessonPage from "@/components/lms/weeks/LessonPage";
import TestPage from "@/components/lms/weeks/TestPage";
import activityReport from "@/components/reports/activityReport";
import progressReport from "@/components/reports/progressReport";

import Leaderboard from "@/components/lms/Leaderboard";


//Report Card
import SubjectProgress from "@/components/lms/reports/SubjectProgress";
import WeightDetails from "@/components/lms/reports/WeightDetails";
import AttendanceDetails from "@/components/lms/reports/AttendanceDetails";



import ParentSubjectProgress from "@/components/parent/reports/SubjectProgress";


//-----------------------------------------------------------------------

import Speech from "@/components/lms/speech/Speech";

import Gradebook from "@/components/lms/gradebook/Gradebook";
import ParentGradebook from "@/components/parent/gradebook/Gradebook";


import Calendar from "@/components/calendar/Calendar";
import Weekly from "@/components/weekly/Weekly";
//-----------------------------------------------------------------------

//Dashboard
import Home from "@/components/dashboard/home";
//-----------------------------------------------------------------------
import Room from "@/components/messages/Room";
import Assignment from "@/components/lms/submissions/Assignment";
import AssignmentIntro from "@/components/lms/submissions/AssignmentIntro";
import SubmissionReport from "@/components/lms/submissions/SubmissionReport";
import PracticeReport from "@/components/lms/submissions/PracticeReport";
import SubmissionReportAttempt from "@/components/lms/submissions/SubmissionReportAttempt";
import LessonVideos from "@/components/lms/subjects/LessonVideos";
import ReviewVideos from "@/components/lms/review/ReviewVideos";
import ReviewShows from "@/components/lms/review/ReviewShows";

import InclassFinal from "@/components/lms/subjects/InclassFinal";



import LessonShows from "@/components/lms/subjects/LessonShows";
import Flipped from "@/components/lms/subjects/Flipped";
import Inclass from "@/components/lms/subjects/Inclass";
import Exit from "@/components/lms/subjects/Exit";
import Exam from "@/components/lms/exam/exam";
import LiveSession from "@/components/lms/livesession/LiveSession";



//!Parent
import ParentHome from "@/components/parent/Home";
import SonDash from "@/components/parent/SonDash";
import ParentPage from "@/components/parent/ParentPage";
import NationalSearch from "@/components/parent/account/NationalSearch";
//-----------------------------------------------------------------------




Vue.use(VueRouter);

const routes = [{
        path: '/',
        component: Page,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "/exam",
                name: "Exam",
                component: Exam,
                meta: {
                    requiresAuth: true
                }
            },




        ]
    },

    {
        path: '/',
        component: EmptyHome,
        meta: {
            requiresAuth: true
        },
        children: [










            {
                path: "/weightdetails",
                name: "WeightDetails",
                component: WeightDetails,
                meta: {
                    requiresAuth: true,
                    title: "WeightDetails"
                }
            },
            {
                path: "/attendancedetails",
                name: "AttendanceDetails",
                component: AttendanceDetails,
                meta: {
                    requiresAuth: true,
                    title: "AttendanceDetails"
                }
            },

































        ]
    },
    {
        path: '/',
        component: ExamHome,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "/SubmissionReportAttempt/:attempt,:total",
                name: "SubmissionReportAttempt",
                component: SubmissionReportAttempt,
                meta: {
                    requiresAuth: true,
                    title: "SubmissionReportAttempt"
                }
            },
            {
                path: "/submissionreport",
                name: "SubmissionReport",
                component: SubmissionReport,
                meta: {
                    requiresAuth: true,
                    title: "SubmissionReport"
                }
            },
            {
                path: "/PracticeReport",
                name: "PracticeReport",
                component: PracticeReport,
                meta: {
                    requiresAuth: true,
                    title: "PracticeReport"
                }
            },

            {
                path: "/progress",
                name: "progressReport",
                component: progressReport,
                meta: {
                    requiresAuth: true,
                    title: "progressReport"
                }
            },
            {
                path: "/subjectprogress/:path,:subject",
                name: "SubjectProgress",
                component: SubjectProgress,
                meta: {
                    requiresAuth: true,
                    title: "SubjectProgress"
                }
            },
            {
                path: "/inclassfinal",
                name: "InclassFinal",
                component: InclassFinal,
                meta: {
                    requiresAuth: true,
                    title: "InclassFinal"
                }
            },

            {
                path: "/assignmentintro",
                name: "AssignmentIntro",
                component: AssignmentIntro,
                meta: {
                    requiresAuth: true,
                    title: "AssignmentIntro"
                }
            },
            {
                path: "/room",
                name: "Room",
                component: Room,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/report/:path, :title",
                name: "activityReport",
                component: activityReport,
                meta: {
                    requiresAuth: true,
                    title: "activityReport"
                }
            },
            {
                path: "/LessonShows",
                name: "LessonShows",
                component: LessonShows,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/livesesstion",
                name: "LiveSession",
                component: LiveSession,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/assignment",
                name: "Assignment",
                component: Assignment,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/calendar",
                name: "Calendar",
                component: Calendar,
                meta: {
                    requiresAuth: true,
                    title: "Calendar"
                }
            },
            {
                path: "/weekly",
                name: "Weekly",
                component: Weekly,
                meta: {
                    requiresAuth: true,
                    title: "Weekly"
                }
            },
            {
                path: "/LessonVideos",
                name: "LessonVideos",
                component: LessonVideos,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/ReviewVideos",
                name: "ReviewVideos",
                component: ReviewVideos,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/ReviewShows",
                name: "ReviewShows",
                component: ReviewShows,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/lessonpage/:path,:attempt",
                name: "LessonPage",
                component: LessonPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/exit",
                name: "Exit",
                component: Exit,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/flipped",
                name: "Flipped",
                component: Flipped,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/inclass",
                name: "Inclass",
                component: Inclass,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/gradebook",
                name: "Gradebook",
                component: Gradebook,
                meta: {
                    requiresAuth: true,
                    title: "Gradebook"
                }
            },

            {
                path: "/home",
                name: "Home",
                component: Home,
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                }
            },
            {
                path: "/leaderboard",
                name: "Leaderboard",
                component: Leaderboard,
                meta: {
                    requiresAuth: true,
                    title: "Leaderboard"
                }
            },

            {
                path: "/testpage/:path",
                name: "TestPage",
                component: TestPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/flippedintro",
                name: "FlippedIntro",
                component: FlippedIntro,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/inclassintro",
                name: "InclassIntro",
                component: InclassIntro,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/exitintro",
                name: "ExitIntro",
                component: ExitIntro,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/speech",
                name: "Speech",
                component: Speech,
                meta: {
                    requiresAuth: true
                }
            },






        ]



    },
    {
        path: '/',
        component: SubjectHome,
        meta: {
            requiresAuth: true
        },
        children: [

            //Subject Dashboard
            {
                path: "/subjectdash/:path,:title",
                name: "SubjectDash",
                component: SubjectDash,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/week",
                name: "WeekPage",
                component: WeekPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/reviewpage",
                name: "ReviewPage",
                component: ReviewPage,
                meta: {
                    requiresAuth: true
                }
            },



        ]



    },








    {
        path: '/',
        component: ParentPage,
        meta: {
            requiresAuth: true
        },
        children: [


            //!Parent
            {
                path: "/parenthome",
                name: "ParentHome",
                component: ParentHome,
                meta: {
                    requiresAuth: true,
                    title: "ParentHome"
                }
            },

            {
                path: "/parentgradebook",
                name: "ParentGradebook",
                component: ParentGradebook,
                meta: {
                    requiresAuth: true,
                    title: "ParentGradebook"
                }
            },
            {
                path: "/parentsubjectprogress",
                name: "ParentSubjectProgress",
                component: ParentSubjectProgress,
                meta: {
                    requiresAuth: true,
                    title: "ParentSubjectProgress"
                }
            },
            {
                path: "/sondash",
                name: "SonDash",
                component: SonDash,
                meta: {
                    requiresAuth: true,
                    title: "ParentHome"
                }
            },


        ]
    },

    {
        path: "/school/:school",
        name: "Login",
        component: () =>
            import ( /* webpackChunkName: "login" */ "@/components/access/Login")
    },

    {
        path: "/nationalsearch",
        name: "NationalSearch",
        component: NationalSearch,
        meta: {

            title: "NationalSearch"
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("loggedIn") == "true") {
            next();
        } else {
            // next("/school/" + this.$store.state.school);
            next("/school/");
        }
    } else {
        next();
    }
});

export default router;
<template>
  <div>
    <v-row>
      <v-col cols="3" :style="'background-color:' + this.color">
        <v-row>
          <v-card
            :min-height="this.weight * 10"
            width="200px"
            dark
            tile
            flat
            :style="'background-color:' + this.color"
          >
            <v-card-title>{{ this.weight }}%</v-card-title>
            <v-card-subtitle style="color:#ffffff">
              {{ this.title }}
            </v-card-subtitle></v-card
          >
        </v-row>
      </v-col>
      <v-col cols="7" :style="'background-color:' + this.fadecolor">
        {{ this.current }} Exam Grade
      </v-col>
      <v-col cols="2" :style="' text-align:center; align-self: center;'">
        {{ this.title }}<br />
        <h3>0 %</h3>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>
<script>
// import firebase from "@/Firebase";

export default {
  name:"exam-page",
  components: {},
  props: ["title", "weight", "subject", "color", "fadecolor", "current"],

  data() {
    return {
      assignments: [],
      weeks: [],
      subjectpath: this.$store.state.subject
    };
  },

  created() {
    this.$store.commit("gradebook", {
      title: "Exam",
      grade: 0,
      weight: this.weight
    });
  }
};
</script>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/intro.jpg?alt=media&token=91a8dd7d-db53-43e9-a520-db5d029c9c7f"
        style="z-index: 2; padding-left: 50px"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top: 30px">{{ this.assign.weight }}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
                <br />
                <span>{{ this.assign.title }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9" :style="$i18n.locale=='Arabic'? {'margin-left': '28%'} : ''">
      <v-container>
        <v-card>
          <v-card-title>{{ $t(this.assign.type) }}</v-card-title>
          <v-card-subtitle>
            <b>{{$t("Opens")}} </b>{{ this.dateFormat(this.assign?.opendate) }}

            <br /><b>{{$t("Closes")}} </b>{{ this.dateFormat(this.assign?.closedate) }}
          </v-card-subtitle>

          <v-card-text>
     
          
            <v-row>
              <v-col cols="6">
                <v-btn
                  color="success"
                  v-if="this.assign.type == 'Textbook Assignment'"
                  @click="gotoAssignment($store.state.assid)"
                  style="margin-bottom: 50px"
                  depressed
                >
                  <v-icon>mdi-play</v-icon>
                  {{$t("Click here to submit your assignment")}}</v-btn
                >
                <v-btn
                  color="success"
                  v-else
                  @click="goToAssign($store.state.assid)"
                  style="margin-bottom: 50px"
                  depressed
                >
                  <v-icon>mdi-play</v-icon>
                  {{$t("Take your attempt now")}}</v-btn
                >

                <v-list dense v-if="this.assign.type != 'Textbook Assignment'">
                  <v-subheader
                    >{{$t(`Assignment Attemps (Click Attempt for More
                    Details)`) }}</v-subheader
                  >
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(item, i) in battemps"
                      :key="i"
                      @click="gotoReport(item.attempt,item.grade)"
                    >
                      <v-list-item-icon>
                        <!-- <v-chip color="success" style="margin-left: 20px"
                          >{{ item.right + "/"
                          }}<small style="color: black">{{ item.grade }}</small>
                        </v-chip> -->
                        <v-chip color="success" style="margin-left: 20px"
                          >{{$t("Attempt no.")+ item.attempt
                          }}
                        </v-chip>
                        <p style="margin-left: 5px;margin-top: 5px;"> {{ new Date(item.date).toLocaleDateString()}}</p>
                      </v-list-item-icon>
                      <!-- <v-list-item-content>
                        <v-list-item-title>{{ item.date }} </v-list-item-title>
                      </v-list-item-content> -->
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <div v-else>
                  <div v-if="this.assrecord && this.assrecord.right">
                    <h5>{{$t("Your Grade")}}</h5>
                    <h3 style="color: green">
                      {{ this.assrecord.right + "/" + this.assrecord.grade }}
                    </h3>
                    {{$t("Submission Date") + ":" + assrecord.date }}
                    <v-card flat outlined class="ma-2">
                      <v-card-subtitle>{{$t("Your Submission")}}</v-card-subtitle>
                      <v-card-text>
                        <a
                          :href="assrecord.file"
                          target="_blank"
                          v-if="assrecord.file"
                          >{{$t("Click Here to open the Submitted File")}}</a
                        >

                        <div v-html="assrecord.submission" class="foo"
                      /></v-card-text>
                    </v-card>

                    <v-card flat outlined class="ma-2">
                      <v-card-subtitle>{{$t("Audio Submission")}}</v-card-subtitle>
                      <v-card-text>
                        <Audio
                          style="margin-left: 10px"
                          :src="assrecord.audio"
                          controls
                        ></Audio>
                      </v-card-text>
                    </v-card>
                    <v-card flat outlined class="ma-2">
                      <v-card-subtitle>{{$t("Video Submission")}}</v-card-subtitle>
                      <v-card-text>
                        <video
                          ref="playback"
                          :src="assrecord.video"
                          :width="450"
                          :height="337.5"
                          controls
                        ></video>
                      </v-card-text>
                    </v-card>
                  </div>
                  <br />
                  <v-card flat outlined
                    ><v-card-subtitle>{{$t("Assignment Description")}}</v-card-subtitle
                    ><v-card-text>
                      <a :href="this.temp[0].file" target="_blank"
                        >{{$t("Click to Open the Assignment Guide")}}</a
                      >
                      <br />
                      <div
                        v-html="description"
                        :class="
                          subject == 'Arabic' ||
                          subject == 'Arabic Social Studies'
                            ? 'fooar'
                            : 'foo'
                        "
                      ></div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="6" v-if="this.assign.type != 'Textbook Assignment'">
                <div id="chart">
                  <apexchart
                    type="line"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>

  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import moment from "moment";

export default {
  data() {
    return {
      right: [],
      wrong: [],
      battemps: [],
      assrecord: [],
      temp: [],
      assign: [],

      assignment: this.$store.state.assid,
      asspath: this.$store.state.asspath,
      student: this.$store.state.studentpath,
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      week: this.$store.state.weekpath,
      description: "",
      attemps: "",
      quizattempt:0,
      assAttempts:0,
      //Chart

      series: [
        {
          name: "Right",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Wrong",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#089d13", "#FF4560"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Assigment Progress",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["1", "2", "3", "4", "5", "6", "7"],
          title: {
            text: "Attempts",
          },
        },
        yaxis: {
          title: {
            text: "Assigment Progress",
          },
          min: 0,
          max: 10,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },

  created() {
    console.log("state =>",this.$store.state)
    console.log(this.$store.state.assid)
    //! Get Assignment Full Data
    firebase
      .firestore()
      .doc(this.asspath)
      .get()
      .then((doc) => {
        this.temp = [];
        this.temp.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          type: doc.data().type,
          grade: doc.data().grade,
          weight: doc.data().weight,
          weightcode: doc.data().weightcode,
          opendate: doc.data().opendate,
          eopendate: doc.data().eopendate,
          closedate: doc.data().closedate,
          eclosedate: doc.data().eclosedate,
          // description: doc.data().description,
          quiztype: doc.data().quiztype,
          count: doc.data().count,
          week: doc.data().week,
          note: doc.data().note,
          file: doc.data().file,
          attempts: doc.data().attempts,
        });

        this.assign = this.temp[0];

        firebase
          .firestore()
          .doc(this.asspath + "/details/description")
          .get()
          .then((snapshot) => {
            if (!snapshot.exists) return;
            this.description = snapshot.data().description;
          });

      });

    //! Get Student Grade Data
    firebase
      .firestore()
      .doc(this.student + "/assignments/" + this.assignment)
      .get()
      .then((querySnapshot) => {
        this.assrecord = querySnapshot.data();
      });

    this.attemps =
      this.student + "/assignments/" + this.assignment + "/assignmentattemps";
      firebase
        .firestore()
        .collection(this.$store.state.studentpath + "/quizes")
        .doc(this.$store.state.assid)
        .get()
        .then((doc) => {

          this.quizattempt = doc.data()?.attempts
          for (let index = 1; index <= this.quizattempt; index++) {
      firebase
      .firestore()
      .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+"/"+"attempts/"+index)
      .get()
      .then((querySnapshot) => {
          // this.right.push(doc.data().right);
          // this.wrong.push(doc.data().wrong);
          console.log("quiz attempts data: ",querySnapshot.data())
          this.battemps.push(
            {
            key: this.$store.state.assid,
            path: querySnapshot.ref.path,
            right: querySnapshot.data()?.right,
            wrong: querySnapshot.data()?.wrong,
            missed: querySnapshot.data()?.missed,
            date: querySnapshot.data()?.date,
            grade: querySnapshot.data()?.assgrade,
            attempt:querySnapshot.data()?.attempts
          }
          );


        // this.series = [
        //   {
        //     name: "Right",
        //     data: this.right,
        //   },
        //   {
        //     name: "Wrong",
        //     data: this.wrong,
        //   },
        // ];
      });
      
    }
        })

    // firebase
    //   .firestore()
    //   .collection(this.attemps)
    //   .get()
    //   .then((querySnapshot) => {
    //     this.battemps = [];
    //     querySnapshot.forEach((doc) => {
    //       this.right.push(doc.data().right);
    //       this.wrong.push(doc.data().wrong);
    //       this.battemps.push({
    //         key: doc.id,
    //         path: doc.ref.path,
    //         right: doc.data().right,
    //         wrong: doc.data().wrong,
    //         missed: doc.data().missed,
    //         date: doc.data().date,
    //         grade: doc.data().grade,
    //         time: doc.data().time,
    //       });
    //     });

    //     this.series = [
    //       {
    //         name: "Right",
    //         data: this.right,
    //       },
    //       {
    //         name: "Wrong",
    //         data: this.wrong,
    //       },
    //     ];
    //   });
    // }
  },
  methods: {
    gotoAssignment(id) {
      console.log(this.$store.state)
      if (Date.now() >= Date.parse(this.assign.closedate)){
        firebase
        .firestore()
        .collection(this.$store.state.studentpath + "/quizes")
        .doc(this.$store.state.assid)
        .get()
        .then((doc) => {
          this.assAttempts = 0
            doc.data()?.attempts == undefined ? this.assAttempts = 1 : this.assAttempts = doc.data().attempts +1;
            if(this.assAttempts > this.assign.attempts){
              this.assAttempts = Number(this.assign.attempts) + 1
            }

            firebase
              .firestore()
              .collection(this.$store.state.studentpath + "/quizes")
              .doc(this.$store.state.assid)
              .set({
                start_date: moment().format("DD-MM-YY"),
                start_time: moment().format("h:mm:ss a"),
                attempts: this.assAttempts,
                highestScore:"",
                highestRight:""
              })
              .then(() => {
                firebase
              .firestore()
              .doc(this.$store.state.studentpath + "/quizes/"+this.$store.state.assid+'/attempts/'+this.assAttempts)
              .set({
                assid: this.$store.state.assid,
                asspath:this.asspath,
                assgrade:this.assign.grade,
                date:new Date().toLocaleString(),
                attempts: this.assAttempts,
                breakdown: this.$store.state?.breakdown,
                subjectTitle: this.$store.state.subjecttitle,
                class: this.$store.state.sclass,
                student:this.$store.state.email,
                branch: this.$store.state.branch,
                school: this.$store.state.school,
                weektitle: this.$store.state.weektitle,
                week: this.$store.state.weekpath,
                gradelevel:this.$store.state.grade,
                grade:this.$store.state.grade,
                weightcode:this.$store.state.weightcode,
                missed:0,
                highestScore:0,
                highestRight:0,
                score:"",
                type:this.$store.state.asstype,
                weight:this.$store.state.weighttitle
              })
              .then(() => {
                var path =
        this.$store.state.oraginalsubject +
        "/submissions/" +
        this.$store.state.id +
        id;

      this.$store.commit("submissionpath", path);
      router.push({
        name: "Assignment",
        params: {
          assAttempts: this.assAttempts,
          assign: this.assign,
          asspath:this.asspath
        }
      });
              })

              });
 
        });
      }else{
        var path =
        this.$store.state.oraginalsubject +
        "/submissions/" +
        this.$store.state.id +
        id;

      this.$store.commit("submissionpath", path);
      router.push({
        name: "Assignment",
        params: {
          assAttempts: this.assAttempts,
          assign: this.assign,
          asspath:this.asspath
        }
      });
      }
 

    },

    goToAssign( id) {
      this.assAttempts = 0;
      console.log( id);

      this.$store.state.rightAnswers = 0;
      this.$store.state.missedAnswers = 0;
      this.$store.state.lessonrightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.lessonwrongAnswers = 0;
      this.$store.state.current = 0;

      this.$store.state.flippedrightAnswers = 0;
      this.$store.state.exitrightAnswers = 0;

      firebase
        .firestore()
        .collection(this.$store.state.studentpath + "/quizes")
        .doc(this.$store.state.assid)
        .get()
        .then((doc) => {
          this.assAttempts = 0
          //  if (doc.exists) {
            doc.data()?.attempts == undefined ? this.assAttempts = 1 : this.assAttempts = Number(doc.data().attempts) + 1;
            if(this.assAttempts > this.assign.attempts){
              this.assAttempts = Number(this.assign.attempts) + 1
            }
           console.log(this.assAttempts)
            var path =
                  this.$store.state.oraginalsubject +
                  "/submissions/" +
                  this.$store.state.id +
                  id;

                this.$store.commit("submissionpath", path);
                router.push({
                  name: "Assignment",
                  params: {
          assAttempts: this.assAttempts,
          assign: this.assign,
          asspath:this.asspath
        }
                });
           
        });
    },
    dateFormat(date) {
      console.log(date)
      return (
        Intl.DateTimeFormat("en", { day: "numeric" }).format(Date.parse(date)) +
        "-" +
        Intl.DateTimeFormat("en", { month: "numeric" }).format(
          Date.parse(date)
        ) +
        "-" +
        Intl.DateTimeFormat("en", { year: "numeric" }).format(Date.parse(date))
      );
    },
    gotoReport(id,grade) {
      var path =
        this.$store.state.oraginalsubject +
        "/submissions/" +
        this.$store.state.id +
        this.$store.state.assid;

      this.$store.commit("submissionpath", path);
      // router.push({
      //   name: "SubmissionReport",
      // });
      router.push({
        name: "SubmissionReportAttempt", params: { attempt: id,total: grade}
      });
    },
  },
};
</script>
<style scoped>
.fooar >>> img {
  width: 400px;
}
.fooar {
  text-align: right;
  direction: rtl;
  width: 100%;
}
.foo >>> img {
  width: 400px;
}
</style>

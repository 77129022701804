<template>
  <div>
    <v-row v-if="this.loading" @click="gotoDetails" style="cursor: pointer;">
      <v-col cols="3" :style="'background-color:' + this.color">
        <v-row>
          <v-card
            :min-height="this.weight * 10"
            width="200px"
            dark
            tile
            flat
            :style="'background-color:' + this.color"
          >
            <v-card-title>{{ this.weight }}%</v-card-title>
            <v-card-subtitle style="color:#ffffff">
              {{ this.title }}
            </v-card-subtitle></v-card
          >
        </v-row>
      </v-col>
      <v-col cols="7" :style="'background-color:' + this.fadecolor">
        {{ this.assignments.length }} Assignments

        <br />
        {{ this.wfinished.length }} Completed Assignments

        <!-- <br />
        {{ this.totalgrade }} Total Grade of All Assignments-->
      </v-col>
      <v-col cols="2" :style="' text-align:center; align-self: center;'">
        {{ this.title }}<br />
        <h3>
          {{ Math.round((this.studenttotalgrade / this.totalgrade) * 100) }} %
        </h3>
      </v-col>
    </v-row>

    <v-sheet v-else color="darken-2" class="pa-3" :height="this.weight * 10">
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </v-sheet>
    <v-divider />
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name:"weight-page",
  components: {},
  props: ["title", "weight", "subject", "color", "fadecolor", "current"],

  data() {
    return {
      assignments: [],
      weeks: [],
      finished: [],
      wfinished: [],
      totalgrade: 0,
      presentage: 0,
      loading: false,
      studenttotalgrade: 0,
      subjectpath: this.$store.state.subject,
      studentpath: this.$store.state.studentpath
    };
  },

  mounted() {
    this.assignments = [];
    this.weeks = [];
    this.finished = [];
    this.totalgrade = 0;

    this.loaddata();
  },

  watch: {
    studenttotalgrade: function(value) {
      this.$store.commit("gradebook", {
        title: this.title,
        grade: (value / this.totalgrade) * 100,
        weight: this.weight
      });
    }
  },

  methods: {
    loaddata() {
      //! Get Lessons
      firebase
        .firestore()
        .collection(this.subjectpath + "/weeks")
        .where("breakdown", "==", this.current)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().date != null && doc.data().date != "")
            this.weeks.push({
              path: doc.ref.path,
              title: doc.data().title,
              start: doc.data().date,
              end: doc.data().date,
              order: doc.data().order,
              timed: false
            });

            //! Get Lesssons assignments
          });

          this.weeks.forEach((doc, index) => {
            //! Get Subject Assignments
            firebase
              .firestore()
              .collection(doc.path + "/tasks")
              .where("weight", "==", this.title)
              //.orderBy("closedate")
              .get()
              .then(querySnapshot => {
                querySnapshot.forEach(assign => {
                  if (assign.data().graded != true) {
                    this.assignments.push({
                      key: assign.id,
                      grade: assign.data().grade,
                      //! Add Week title & Order to Assignments
                      week: doc.title,
                      weekorder: doc.order
                    });

                    this.totalgrade =
                      this.totalgrade + Number(assign.data().grade);
                  }
                });

                if (this.weeks.length == index + 1) {
                  //! Get Student Assignments
                  firebase
                    .firestore()
                    .collection(this.studentpath + "/assignments")
                    .get()
                    .then(querySnapshot => {
                      this.wfinished = [];
                      this.studenttotalgrade = 0;

                      //   console.log(this.title);
                      //   console.log(querySnapshot.docs);

                      //   if (querySnapshot.docs == null) {
                      //     this.studenttotalgrade = 10;
                      //     console.log("Ahmed Kamal");
                      //   }

                      querySnapshot.forEach(doc => {
                        this.addtofinish(doc.id, doc.data().right);
                      });

                      //! Flag As completed
                      this.$store.commit(
                        "weightcount",
                        this.$store.state.weightcount + 1
                      );
                      this.loading = true;
                    });
                }
              });
          });
        });
    },

    addtofinish(name, right) {
      this.assignments.forEach(doc => {
        if (doc.key == name) {
          this.wfinished.push({ grade: right });

          if (right == null) right = 0;

          this.studenttotalgrade = this.studenttotalgrade + Number(right);
        }

        this.loading = true;
      });
    },

    gotoDetails() {
      this.$store.commit("weighttitle", this.title);
      this.$store.commit("weightpre", this.weight);
      this.$store.commit("current", this.current);

      router.push({
        name: "WeightDetails"
      });
    }
  }
};
</script>

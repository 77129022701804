<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/videoheader.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">
                  {{$t("Lesson Shows")}}
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto" tile>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item v-for="n in sheets" :key="n.key">
                    <v-list-item-avatar tile>
                      <img src="@/assets/gradebook.png" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <a :href="n.link" target="_blank">
                        <v-list-item-title
                          style="font-size:16px; margin-bottom:10px;"
                          >{{ n.title }}</v-list-item-title
                        ></a
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
export default {
  data() {
    return {
      sheets: [],
      path: "",
      title: "",
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.$route.params.path + "/sheets")
      .get()
      .then(querySnapshot => {
        this.sheets = [];
        querySnapshot.forEach(doc => {
          this.sheets.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            link: doc.data().link
          });
        });
      });
  },
  methods: {
    changeVideo(link, title) {
      this.path = link;
      this.title = title;
    }
  }
};
</script>

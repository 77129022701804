<template>
  <v-row style="direction: ltr;">
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/exit.jpg?alt=media&token=906f34f6-f6ce-42c1-a31f-c66dac665e08"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">
                 {{ $t("Gradebook") }} 
                 <br />
                 {{ this.subjecttitle }}
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subjecttitle + " " + this.grade }}
                <!--  <br />
                <span>{{ this.title }}</span>-->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9" :style="$i18n.locale=='Arabic'? {'direction': 'rtl'} : ''">
      <v-container>
        <v-card flat>
          <div v-if="loading">Loading</div>

          <v-card-text v-else>
            <!--Filter by Week-->
            <v-card dark style="background-color:#002147">
              <v-card-text>
          
                <v-row
                  ><v-col cols="4">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
              min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          :label="$t('Filter by Month')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          style="width: 50%;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        type="month"
                        no-title
                        scrollable
                        @change="changeFilter"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="8">
                    <v-autocomplete
                      :items="lessons"
                      :label="$t('Select Lesson')"
                      v-model="selected"
                      @change="search"
                      style="direction: ltr;width: 30%;"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-btn
                      @click="clearsearch"
                      color="green"
                      block
                      depressed
                      small
                      >{{$t("Clear Search")}}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <br />

            <!-- Attendance-->
            <v-row>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-card-title>{{$t("Student Attendance")}}</v-card-title>
                  <v-card-text>
                    <ul>
                      <li>
                        <v-row
                          ><v-col cols="6">{{$t("Total Present:")}}</v-col>
                          <v-col cols="6"> {{ this.present }}</v-col>
                        </v-row>
                      </li>
                      <li>
                        <v-row
                          ><v-col cols="6">{{$t("Total Absence:")}} </v-col>
                          <v-col cols="6"> {{ this.absence }}</v-col>
                        </v-row>
                      </li>
                      <li>
                        <v-row
                          ><v-col cols="6"> {{$t("Total Excuse:")}}</v-col>
                          <v-col cols="6"> {{ this.excuse }}</v-col>
                        </v-row>
                      </li>
                      <li>
                        <v-row
                          ><v-col cols="6"> {{$t("Total Late:")}}</v-col>
                          <v-col cols="6"> {{ this.late }}</v-col>
                        </v-row>
                      </li>
                      <li>
                        <v-row
                          ><v-col cols="6"> {{$t("Total Leave:")}}</v-col>
                          <v-col cols="6"> {{ this.leave }}</v-col>
                        </v-row>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card style="border-style: solid;  border-width: 1px;" flat outlined>
                  <v-card-text>
                    <div id="chart">
                      <apexchart
                        type="donut"
                        width="450"
                        :options="dochartOptions"
                        :series="doseries"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row style="margin-left:10px; margin-top:50px">
              <p>
                <v-icon color="#40b1e5">mdi-alpha-u-box</v-icon>
               {{ $t("Upcoming Assignments") }} 
              </p>
              <p>
                <v-icon color="green">mdi-alpha-d-box</v-icon>
              {{ $t("Delivered Assignments") }}  
              </p>
              <p>
                <v-icon color="red">mdi-alpha-m-box</v-icon>
              {{ $t("Overdue Assignments") }}  
              </p>
            </v-row>
            <v-col cols="12">
              <div v-for="lesson in lessons" :key="lesson.key">
                <GradeLesson
                  :path="lesson.value"
                  :subject="subject"
                  :oraginal="orginalsubject"
                  :title="lesson.text"
                  :date="lesson.date"
                  :weekid="lesson.key"
                  :par="par"
                />
              </div>
            </v-col>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import GradeLesson from "./GradeLesson";
import apexchart from 'vue-apexcharts';
export default {
  name: "GradeBook",
  components: { GradeLesson,apexchart },
  data() {
    return {
      assignments: [],
      lessons: [],
      weights: [],
      week: "",
      date: new Date().toISOString().substr(0, 7),
      subject: this.$store.state.subject,
      orginalsubject: this.$store.state.oraginalsubject,
      menu: false,
      modal: false,
      selected: "",
      //Attendance
      present: 0,
      absence: 0,
      excuse: 0,
      late: 0,
      leave: 0,
      load: false,
      subjecttitle: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      student: this.$store.state.studentpath,
      studentid: this.$store.state.email,
      par: 0,

      //Attendance Chart
      doseries: [0, 0],
      dochartOptions: {
        colors: ["#059612", "#e1261c", "#ffcc00"],
        chart: {
          type: "donut"
        },
        labels: ["Present", "Absence", "Excuse"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("gradebook")
    console.log("/schools/" + this.$store.state.school + "/attendance");
    console.log(String(this.studentid));
    console.log(this.subjecttitle);
    console.log(this.$store.state.breakdown)
    console.log(this.subject)
    //! Get Student Attendance
    firebase
      .firestore()
      .collection("/schools/" + this.$store.state.school + "/attendance")
      .where("student", "==", this.studentid)
      .where("subject", "==", this.subjecttitle)
      .where("breakdown", "==", "الفصل الدراسي الاول") //this.$store.state.breakdown
      .get()
      .then(querySnapshot => {
        console.log(querySnapshot.docs)
        this.present = 0;
        this.absence = 0;
        this.excuse = 0;
        this.late = 0;
        this.leave = 0;
        querySnapshot.forEach(doc => {
          console.log(doc.data())
          if (doc.data().type == "present") this.present++;
          else if (doc.data().type == "absent") this.absence++;
          else if (doc.data().type == "excuse") this.excuse++;
          else if (doc.data().type == "late") this.late++;
          else if (doc.data().type == "leave") this.leave++;
        });

        this.doseries = [this.present, this.absence, this.excuse];
      });
    //! get weeks
    firebase
      .firestore()
      .collection(this.subject + "/weeks/")
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        this.lessons = [];
        querySnapshot.forEach(doc => {
          this.lessons.push({
            key: doc.id,
            text: doc.data().title,
            date: doc.data().date,
            value: doc.ref.path
          });
        });
        this.load = true;
      });

    //! get subject
    firebase
      .firestore()
      .doc(this.orginalsubject)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;

        this.par = snapshot.data().participation;
      });
  },
  methods: {
    //Search By Title
    search() {
      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.lessons = [];
          querySnapshot.forEach(doc => {
            if (doc.ref.path == this.selected)
              this.lessons.push({
                key: doc.id,
                text: doc.data().title,
                date: doc.data().date,
                value: doc.ref.path
              });
          });
        });
    },
    //Search By Date
    changeFilter() {
      var mon;
      mon = new Date(this.date).getMonth() + 1;
      this.$refs.menu.save(this.date);
      this.menu = false;

      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.lessons = [];
          querySnapshot.forEach(doc => {
            if (new Date(doc.data().date).getMonth() + 1 == mon)
              this.lessons.push({
                key: doc.id,
                text: doc.data().title,
                date: doc.data().date,
                value: doc.ref.path
              });
          });
        });
    },
    clearsearch() {
      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.lessons = [];
          querySnapshot.forEach(doc => {
            this.lessons.push({
              key: doc.id,
              text: doc.data().title,
              date: doc.data().date,
              value: doc.ref.path
            });
          });
        });
    }
  }
};
</script>

<template>
<v-card flat outlined><v-card-text>


   <div class="fooar">
    <v-row>
      <v-col cols="3">  <v-chip small v-if="score>50" color="green" dark>% {{score}} </v-chip><v-chip small v-else color="red" dark>% {{score}} </v-chip>
      
      
      </v-col>
      <v-col cols="9">{{ this.standard.title }}</v-col>
      </v-row>
  
</div>
</v-card-text></v-card>

</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "Test-page",
  props: ["code", "right", 'wrong'],

  data() {
    return { standard: {}, score:((this.right/(this.right+this.wrong))*100).toFixed(0) };
  },

  created() {
    console.log((this.right/(this.right+this.wrong)))
    firebase
      .firestore()
      .collection(this.$store.state.subject + "/standards")
      .doc(this.code)
      .get()
      .then(doc => {
        this.standard = doc.data();
      });
  },

  methods: {}
};
</script>

<style lang="scss" scoped></style>

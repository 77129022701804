<template>
  <div>
    <!--    <p v-for="q in examquestions" :key="q.key">{{ q.path }}</p> -->
    <v-card flat>
      <v-card-title>
        <v-spacer></v-spacer>
        <!-- <small> {{ this.current + 1 }} / {{ this.exam.pages }}</small> -->
        <!-- <v-btn
          color="primary"
          style="margin-left: 20px"
          rounded
          @click="next()"
          small
          v-if="this.current < this.examquestions.length - 1"
        >
          <v-icon>mdi-chevron-right</v-icon> Next Page
        </v-btn> -->
        <v-btn
          color="orange"
          style="margin-left: 20px"
          rounded
          depressed
          @click="finish()"
          small
        >
          <v-icon>mdi-chevron-right</v-icon> Finish Exam
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <div
            v-for="question in examquestions"
            :key="question"
            style="margin-bottom:10px"
          >
            <Question
              :path="question.path"
              source="Exam"
              :key="question.key"
            ></Question>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="orange"
          style="margin-left: 20px"
          rounded
          depressed
          @click="finish()"
          small
        >
          <v-icon>mdi-chevron-right</v-icon> Finish Exam
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import Question from "./Question";

export default {
  props: ["path"],
  components: {
    Question
  },
  data() {
    return {
      subject: this.$store.state.subject,
      current: 0,
      count: 5,

      exam: {},
      qmatrix: [],
      examquestions: [],
      lessonquestions: [],
      record: []
    };
  },
  created() {
    this.$store.state.rightAnswers = 0;
    this.$store.state.wrongAnswers = 0;
    this.examquestions = [];
    firebase
      .firestore()
      .collection(this.subject + "/questions")
      .orderBy("order")
      .get()
      .then(snapshot => {
        snapshot.forEach(question => {
          // console.log(question.ref.path);
          this.examquestions.push({
            key: question.id,
            path: question.ref.path
          });
        });

        // this.exam = snapshot.data();
        // // this.qmatrix = snapshot.data().qmatrix;
        // this.examquestions = this.exam.fixedquestions;
      });
    //! Kamal Shalaby Exam
  },

  methods: {
    finish() {
      this.record.date = new Date().toLocaleString();
      this.record.right = this.$store.state.rightAnswers;
      this.record.wrong = this.$store.state.wrongAnswers;
      this.record.skipped =
        this.count -
        (this.$store.state.wrongAnswers + this.$store.state.rightAnswers);

      this.record.subject = this.$store.state.subject;
      this.record.subject_title = this.$store.state.subjecttitle;
      this.record.type = "Exam";
      this.record.weektitle = this.$store.state.weektitle;
      this.record.weekpath = this.$store.state.weekpath;
      this.record.totalQuestions = this.count;

      router.push({ name: "Home" });
    },
    next() {
      if (this.current < this.examquestions.length - 1) {
        this.current++;
      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    }
  }
};
</script>

<template>
  <div>
    <v-card style="margin-top:20px; background-color:#033b59;" dark flat>
      <!--  Overdue Assignments -->

      <div v-if="this.assignments == ''">
        <div
          v-if="Date.now() > Date.parse(closedate)"
          style="margin-top:20px; background-color:red; padding-left:10px;"
        >
          <v-row
            style="margin-top:20px;  padding-left:10px; padding-right:20px;"
          >
            <span>
              <b>{{ this.weight }}</b>

              <v-spacer />{{ this.opendate }}</span
            >

            <v-spacer />
            <div
              @click="goToAssignIntro()"
              small
              light
              style="cursor: pointer;"
            >
              Go To Assignment
            </div>
          </v-row>
        </div>

        <!--  Waiting Submission -->
        <div
          v-else
          style="margin-top:20px; background-color:#40b1e5; padding-left:10px;"
        >
          <v-row
            style="margin-top:20px;  padding-left:10px; padding-right:20px;"
          >
            <span>
              <b>{{ this.weight }}</b>

              <v-spacer />{{ this.opendate }}</span
            >

            <v-spacer />
            <div
              @click="goToAssignIntro()"
              small
              light
              style="cursor: pointer;"
            >
              Go To Assignment
            </div>
          </v-row>
        </div>
      </div>

      <!--  Delivered Assignments -->

      <div
        v-else
        style="margin-top:20px; background-color:green; padding-left:10px;"
      >
        <v-row style="margin-top:20px;  padding-left:10px; padding-right:20px;">
          <span
            ><b> {{ this.weight }}</b
            ><v-spacer />{{ this.opendate }}</span
          >
          <v-spacer />
          <div @click="goToAssignIntro()" small light style="cursor: pointer;">
            Go To Assignment
          </div>
        </v-row>
      </div>
      <v-card-subtitle>
        <v-row>
          <v-col cols="10">
            {{ this.title }}
          </v-col>
          <v-col
            cols="2"
            v-if="this.assignments != ''"
            style="background-color:green; color:#ffffff; font-size:16px;"
          >
            {{ this.assignments.right }} /{{ this.assignments.grade }}
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <div v-if="this.assignments != ''">
          <div v-if="this.assignments.feedback">
            <v-card light
              ><v-card-text>
                {{ this.assignments.feedback }}</v-card-text
              ></v-card
            >
          </div>
        </div>
        <br />
        <!-- <br />
      Number Of Attempts: {{ this.assignments.length }}-->
        <v-row></v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "AssignLesson",
  props: [
    "path",
    "type",
    "title",
    "grade",
    "opendate",
    "closedate",
    "weight",
    "assid",
    "weekpath",
    "weektitle",
    "weekid"
  ],
  data() {
    return {
      assignments: [],
      lessons: [],
      week: "",
      loading: false
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.$store.state.studentpath + "/assignments/" + this.assid)
      .get()
      .then(querySnapshot => {
        this.assignments = querySnapshot.data();
      });
  },
  methods: {
    goToAssignIntro() {
      this.$store.commit("assid", this.assid);
      this.$store.commit("asspath", this.path);

      this.$store.commit("weekpath", this.weekpath);
      this.$store.commit("weekid", this.weekid);
      this.$store.commit("weektitle", this.weektitle);

      this.$store.state.rightAnswers = 0;
      this.$store.state.lessonrightAnswers = 0;
      this.$store.state.wrongAnswers = 0;
      this.$store.state.lessonwrongAnswers = 0;
      // this.$store.state.weektitle = this.weektitle;
      // this.$store.state.weekpath = this.weekpath;

      router.push({
        name: "AssignmentIntro"
        //name: "Assignment"
      });
    }
  }
};
</script>

<template>
  <div class="login-access">
    <v-container>
      <v-card flat outlined>
        <v-card-title>
          <img :src="this.$store.state.logo" width="70px" />
          Create Your Parent Account</v-card-title
        >

        <v-card-text>
          <img src="@/assets/parentbg.jpg" width="100%" />
          <v-divider />
          <v-col class="d-flex" cols="6" sm="6">
            <v-select
              :items="['Father', 'Mother']"
              v-model.trim="relation"
              label="Relation"
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="6" sm="6">
            <v-text-field
              label="Please Enter Your National ID"
              v-model="id"
              style="margin-bottom:50px;"
            />
          </v-col>

          <v-btn depressed color="success" @click="searchforparent"
            >Press To Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  name: "National-page",
  data() {
    return {
      id: "",
      relation: "",
      parent: {}
    };
  },
  methods: {
    searchforparent() {
      if (this.relation == "Father") {
        firebase
          .firestore()
          .collection(
            "schools/" + this.$store.state.schoolName + "/StudentsData"
          )
          .where("father_nationalID", "==", this.id)
          .get()
          .then(x => {
            if (x.docs.length == 0) {
              this.$swal(
                {
                  icon: "error",
                  title: "Not Applicable",
                  html: "There's No Account for this National ID"
                }
              );
            } else {
              this.parent.nationalID = this.id;
              this.parent.relation = this.relation;

              this.parent.username =
                x.docs[0].data()["middlename"].toLowerCase() +
                Math.floor(Math.random() * 900000 + 1000000).toString();
              this.parent.password = Math.floor(
                Math.random() * 900000 + 1000000
              ).toString();

              this.parent.name = x.docs[0].data()["middlename"];

              firebase
                .firestore()
                .collection(
                  "schools/" + this.$store.state.schoolName + "/parents"
                )
                .doc(this.id)
                .set(this.parent)
                .then(() => {
                  this.$swal(
                    {
                      icon: "success",
                      title: "Account Created",
                      html:
                        "<b>Username:</b> " +
                        this.parent.username +
                        "<br/> <b>Password: </b>" +
                        this.parent.password +
                        "<br/><small style='color:red; margin-top:50px;'> Please Write your password before closing this window</small"
                    }
                  );
                });
            }
          });
      } else {
        firebase
          .firestore()
          .collection(
            "schools/" + this.$store.state.schoolName + "/StudentsData"
          )
          .where("mother_nationalID", "==", this.id)
          .get()
          .then(x => {
            if (x.docs.length == 0) {
              this.$swal(
                {
                  icon: "error",
                  title: "Not Applicable",
                  html: "There's No Account for this National ID"
                }
              );
            } else {
              this.parent.nationalID = this.id;
              this.parent.relation = this.relation;

              this.parent.username =
                x.docs[0]
                  .data()
                  // eslint-disable-next-line no-unexpected-multiline
                  ["mother_name"].toLowerCase()
                  .trim() +
                Math.floor(Math.random() * 900000 + 1000000).toString();
              this.parent.password = Math.floor(
                Math.random() * 900000 + 1000000
              ).toString();

              this.parent.name = x.docs[0].data()["mother_name"];

              firebase
                .firestore()
                .collection(
                  "schools/" + this.$store.state.schoolName + "/parents"
                )
                .doc(this.id)
                .set(this.parent)
                .then(() => {
                  this.$swal(
                    {
                      icon: "success",
                      title: "Account Created",
                      html:
                        "<b>Username:</b> " +
                        this.parent.username +
                        "<br/> <b>Password: </b>" +
                        this.parent.password +
                        "<br/><small style='color:red; margin-top:50px;'> Please Write your password before closing this window</small"
                    }
                  );
                });
            }
          });
      }
    }
  }
};
</script>

<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/videoheader.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">
                  Review Shows
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto" tile flat outlined>
              <v-card-text>
                <div v-for="n in weeks" :key="n">
                  <WeekShows :path="n" />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import WeekShows from "./WeekShows";
export default {
  components: { WeekShows },
  data() {
    return {
      videos: [],
      weeks: [],
      path: "",
      title: "",
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      week: this.$store.state.weekpath,
      type: this.$store.state.videotype,
      videospath: ""
    };
  },
  created() {
    this.weeks = [];
    firebase
      .firestore()
      .doc(this.week)
      .get()
      .then(doc => {
        this.weeks = doc.data().weeks;
      });
  },
  methods: {
    changeVideo(link, title) {
      this.path = link;
      this.title = title;
    }
  }
};
</script>

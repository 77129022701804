<template>
  <v-card style="margin-top:20px; background-color:#eee;" flat>
    <div
      v-if="this.assignments.length > 0"
      style="margin-top:20px; background-color:green; padding-left:10px;"
    >
      {{ this.weight }}
    </div>
    <div
      v-else-if="this.assignments.length == 0"
      style="margin-top:20px; background-color:red; padding-left:10px;"
    >
      {{ this.weight }}
    </div>

    <v-card-subtitle>
      <v-row>
        <v-col cols="6">
          Exit Ticket Grade
        </v-col>
        <v-col cols="6">
          <v-chip v-if="this.assignments.length > 0" color="green">
            {{ this.assignments[0].right }}/3</v-chip
          >
        </v-col>
      </v-row>
    </v-card-subtitle>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "AssignLesson",
  props: ["path", "type", "title", "grade", "opendate", "weight"],
  data() {
    return {
      assignments: [],
      lessons: [],
      week: ""
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.$store.state.studentpath + "/assignments")
      .where("week", "==", this.path)
      .where("type", "==", "exit")
      .get()
      .then(querySnapshot => {
        this.assignments = [];
        querySnapshot.forEach(doc => {
          this.assignments.push({
            type: doc.data().type,
            // title: doc.data().title,
            grade: doc.data().right,
            right: doc.data().right
            //opendate: doc.data().opendate,
            // weight: doc.data().weight
          });
        });
      });
  },
  methods: {
    onWeekSelected() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.$store.state.id +
            "/assignments"
        )
        .where("subject", "==", this.$store.state.subject)
        .where("week", "==", this.week)
        .get()
        .then(querySnapshot => {
          this.assignments = [];

          querySnapshot.forEach(doc => {
            this.assignments.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              weight: doc.data().weight,
              type: doc.data().type,
              week: doc.data().week,
              score: doc.data().score,
              date: doc.data().date,
              right: doc.data().right,
              wrong: doc.data().wrong,
              missed: doc.data().missed,
              questionsCount: doc.data().questionsCount,
              weektitle: doc.data().weektitle
            });
          });
        });
    },

    onClearSearch() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.$store.state.id +
            "/assignments"
        )
        .where("subject", "==", this.$store.state.subject)
        .get()
        .then(querySnapshot => {
          this.assignments = [];

          querySnapshot.forEach(doc => {
            this.assignments.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              weight: doc.data().weight,
              type: doc.data().type,
              week: doc.data().week,
              score: doc.data().score,
              date: doc.data().date,
              right: doc.data().right,
              wrong: doc.data().wrong,
              missed: doc.data().missed,
              questionsCount: doc.data().questionsCount,
              weektitle: doc.data().weektitle
            });
          });
        });
    }
  }
};
</script>

<template>
  <div>
    <v-card flat outlined>
      <v-card-title>
        <!--     <img
          src="@/assets/complete.png"
          width="80px"
          v-if="question.qtype == 'complete'"
        />
        <img
          src="@/assets/tf.png"
          width="80px"
          v-else-if="question.qtype == 'tf'"
        />

        <img src="@/assets/q1.png" width="80px" v-else />-->
        <div v-html="this.question_body" style="padding-left:10px"></div>
      </v-card-title>

      <v-card-text>
        <!-- Multiple Choice -->
        <div v-if="question.qtype == 'mc' || question.qtype == null">
          <v-list-item-group color="primary">
            <v-list-item
              v-for="option in options"
              :key="option.id"
              @click="checkAnswer(option.grade, option.title)"
            >
              <v-list-item-content>
                <h6>
                  <v-chip color="primary" text-color="white">{{
                    option.title
                  }}</v-chip>
                </h6>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
        <!-- True/False -->
        <div v-else-if="question.qtype == 'tf'">
          <v-btn
            class="mx-2"
            fab
            dark
            x-large
            color="red"
            depressed
            @click="checkRight(question.correct, 'wrong')"
          >
            <v-icon dark>
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            dark
            x-large
            color="green"
            depressed
            @click="checkRight(question.correct, 'right')"
          >
            <v-icon dark>
              mdi-check
            </v-icon>
          </v-btn>
        </div>

        <!-- Complete -->
        <div v-else-if="question.qtype == 'complete'">
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="The Missing Word"
                outlined
                rounded
                v-model="missing"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" style="text-align:right">
              <v-btn
                depressed
                rounded
                color="green"
                dark
                @click="checkComplete(question.correct, missing)"
                >Submit Answer</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <!-- Essay -->
        <div v-else-if="question.qtype == 'essay'">
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Please Enter the Answer Here"
                outlined
                v-model="essay"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" style="text-align:right">
              <v-btn
                depressed
                rounded
                color="green"
                dark
                @click="checkPassage(essay)"
                >Submit Answer</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <br />
        <div>
          <v-alert
            :value="checked"
            color="green"
            dark
            border="top"
            icon="mdi-alert-circle"
            >Your Answer Submitted</v-alert
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "ExamQustion",
  props: ["path", "source"],

  data() {
    return {
      missing: "",
      questionID: "",
      wrong: false,
      right: false,
      question: {},
      options: [],
      answer: [],
      question_body: "",
      question_answer: "",
      essay: "",
      checked: false
    };
  },

  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(doc => {
        if (doc.exists) {
          this.question = doc.data();
          this.questionID = doc.id;

          firebase
            .firestore()
            .doc(this.path + "/details/details")
            .get()
            .then(querySnapshot => {
              this.question_body = querySnapshot.data().body;
              this.question_answer = querySnapshot.data().answer;
            });
        } else {
          alert("No such document!");
        }

        firebase
          .firestore()
          .collection(this.path + "/options/")
          .orderBy("title")
          .get()
          .then(querySnapshot => {
            this.options = [];
            querySnapshot.forEach(doc => {
              this.options.push({
                key: doc.id,
                path: doc.ref.path,
                title: doc.data().title,
                grade: doc.data().grade
              });
            });
          });
      });
  },

  methods: {
    //! Multiple Choice Qoustions
    checkAnswer(grade, title) {
      var today = new Date();

      this.answer.qtype = "Multiple Choice";
      this.answer.studentanswer = title;
      this.answer.difficulty =
        this.question.difficulty == null ? "" : this.question.difficulty;
      this.answer.question = this.path;
      this.answer.class = this.$store.state.sclass;
      this.answer.bloom =
        this.question.bloom == null ? "" : this.question.bloom;

      this.answer.weight =
        this.question.weight == null ? 1 : this.question.weight;

      this.answer.dok = this.question.dok == null ? "" : this.question.dok;
      this.answer.standard =
        this.question.standard == null ? "" : this.question.standard;

      this.answer.date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.answer.time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.answer.subjectPath = this.$store.state.subject;
      this.answer.subjectTitle = this.$store.state.subjecttitle;
      this.answer.topic = this.$store.state.weektitle;
      this.answer.topicPath = this.$store.state.weekpath;
      this.answer.grade = this.$store.state.grade;
      this.answer.class = this.$store.state.sclass;
      this.answer.educationPath = this.$store.state.educationPath;
      this.answer.source = this.source;
      this.answer.studentId = this.$store.state.id;
      this.answer.branch = localStorage.getItem("branch");
      this.answer.school = this.$store.state.school;

      if (this.checked == false) {
        if (grade == "1") {
          //Add the record to student answers Collection

          this.answer.answer = "Right";
          this.answer.right = 1;

          this.right = true;
          this.wrong = false;
          this.$store.state.rightAnswers++;
        } else {
          //Add the record to student answers Collection

          this.answer.answer = "Wrong";
          this.answer.right = 0;

          this.right = false;
          this.wrong = true;
          this.$store.state.wrongAnswers++;
        }

        firebase
          .firestore()
          .collection(
            this.$store.state.studentpath +
              "/exams/" +
              this.$store.state.subjecttitle +
              "/answers"
          )
          .doc(this.questionID)
          .set({ ...this.answer })
          .then(() => {
            // console.log(this.answer);
            this.checked = true;
          })
          .catch(() => {});
      }
    },

    //! Complete Qoustions
    checkComplete(answer, action) {
      this.checked = true;
      var today = new Date();
      //Add the record to student answers Collection
      this.answer.qtype = "Complete";
      this.answer.studentanswer = action;
      this.answer.difficulty =
        this.question.difficulty == null ? "" : this.question.difficulty;
      this.answer.question = this.path;
      this.answer.class = this.$store.state.sclass;
      this.answer.bloom =
        this.question.bloom == null ? "" : this.question.bloom;

      this.answer.weight =
        this.question.weight == null ? 1 : this.question.weight;

      this.answer.dok = this.question.dok == null ? "" : this.question.dok;
      this.answer.standard =
        this.question.standard == null ? "" : this.question.standard;

      this.answer.date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.answer.time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.answer.subjectPath = this.$store.state.subject;
      this.answer.subjectTitle = this.$store.state.subjecttitle;
      this.answer.topic = this.$store.state.weektitle;
      this.answer.topicPath = this.$store.state.weekpath;
      this.answer.grade = this.$store.state.grade;
      this.answer.class = this.$store.state.sclass;
      this.answer.educationPath = this.$store.state.educationPath;
      this.answer.source = this.source;
      this.answer.studentId = this.$store.state.id;
      this.answer.branch = localStorage.getItem("branch");
      this.answer.school = this.$store.state.school;
      //End of answers Collection

      if (answer.trim().toLowerCase() == action.trim().toLowerCase()) {
        this.answer.answer = "Right";
        this.answer.right = 1;
        this.right = true;
        this.wrong = false;
        this.$store.state.rightAnswers++;
        this.$store.state.lessonrightAnswers++;
      } else {
        //Add the record to student answers Collection

        this.answer.answer = "Wrong";
        this.answer.right = 0;

        this.right = false;
        this.wrong = true;
        this.$store.state.wrongAnswers++;
        this.$store.state.lessonwrongAnswers++;
      }

      firebase
        .firestore()
        .collection(
          this.$store.state.studentpath +
            "/exams/" +
            this.$store.state.subjecttitle +
            "/answers"
        )
        .doc(this.questionID)
        .set({ ...this.answer })
        .then(() => {
          // console.log(this.answer);
        })
        .catch(() => {});
    },

    //! True/ False Qoustions
    checkRight(answer, action) {
      this.checked = true;
      var today = new Date();
      //Add the record to student answers Collection
      this.answer.qtype = "True/ False";
      this.answer.studentanswer = action;
      this.answer.difficulty =
        this.question.difficulty == null ? "" : this.question.difficulty;
      this.answer.question = this.path;
      this.answer.class = this.$store.state.sclass;
      this.answer.bloom =
        this.question.bloom == null ? "" : this.question.bloom;

      this.answer.weight =
        this.question.weight == null ? 1 : this.question.weight;

      this.answer.dok = this.question.dok == null ? "" : this.question.dok;
      this.answer.standard =
        this.question.standard == null ? "" : this.question.standard;

      this.answer.date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.answer.time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.answer.subjectPath = this.$store.state.subject;
      this.answer.subjectTitle = this.$store.state.subjecttitle;
      this.answer.topic = this.$store.state.weektitle;
      this.answer.topicPath = this.$store.state.weekpath;
      this.answer.grade = this.$store.state.grade;
      this.answer.class = this.$store.state.sclass;
      this.answer.educationPath = this.$store.state.educationPath;
      this.answer.source = this.source;
      this.answer.studentId = this.$store.state.id;
      this.answer.branch = localStorage.getItem("branch");
      this.answer.school = this.$store.state.school;
      //End of answers Collection

      if (
        (answer == "true" && action == "right") ||
        (answer == "false" && action == "wrong")
      ) {
        this.answer.answer = "Right";
        this.answer.right = 1;
        this.right = true;
        this.wrong = false;
        this.$store.state.rightAnswers++;
        this.$store.state.lessonrightAnswers++;
      } else {
        this.answer.answer = "Wrong";
        this.answer.right = 0;

        this.right = false;
        this.wrong = true;
        this.$store.state.wrongAnswers++;
        this.$store.state.lessonwrongAnswers++;
      }

      firebase
        .firestore()
        .collection(
          this.$store.state.studentpath +
            "/exams/" +
            this.$store.state.subjecttitle +
            "/answers"
        )
        .doc(this.questionID)
        .set({ ...this.answer })
        .then(() => {
          // console.log(this.answer);
        })
        .catch(() => {});
    },

    //! essay Qoustions
    checkPassage(answer) {
      this.checked = true;
      var today = new Date();
      //Add the record to student answers Collection
      this.answer.qtype = "Essay";
      this.answer.difficulty =
        this.question.difficulty == null ? "" : this.question.difficulty;
      this.answer.question = this.path;
      this.answer.class = this.$store.state.sclass;
      this.answer.bloom =
        this.question.bloom == null ? "" : this.question.bloom;

      this.answer.weight =
        this.question.weight == null ? 1 : this.question.weight;

      this.answer.dok = this.question.dok == null ? "" : this.question.dok;
      this.answer.standard =
        this.question.standard == null ? "" : this.question.standard;

      this.answer.date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.answer.time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.answer.subjectPath = this.$store.state.subject;
      this.answer.subjectTitle = this.$store.state.subjecttitle;
      this.answer.topic = this.$store.state.weektitle;
      this.answer.topicPath = this.$store.state.weekpath;
      this.answer.grade = this.$store.state.grade;
      this.answer.class = this.$store.state.sclass;
      this.answer.educationPath = this.$store.state.educationPath;
      this.answer.source = this.source;
      this.answer.studentId = this.$store.state.id;
      this.answer.branch = localStorage.getItem("branch");
      this.answer.school = this.$store.state.school;

      this.answer.answer = answer;
      this.answer.studentanswer = answer;

      firebase
        .firestore()
        .collection(
          this.$store.state.studentpath +
            "/exams/" +
            this.$store.state.subjecttitle +
            "/answers"
        )
        .doc(this.questionID)
        .set({ ...this.answer })
        .then(() => {
          // console.log(this.answer);
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.a >>> img {
  width: 300px;
}
</style>

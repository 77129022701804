import { render, staticRenderFns } from "./AssignmentIntro.vue?vue&type=template&id=4f24adcb&scoped=true&"
import script from "./AssignmentIntro.vue?vue&type=script&lang=js&"
export * from "./AssignmentIntro.vue?vue&type=script&lang=js&"
import style0 from "./AssignmentIntro.vue?vue&type=style&index=0&id=4f24adcb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f24adcb",
  null
  
)

export default component.exports
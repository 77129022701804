<template>
  <v-row style="direction: ltr;">
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/leaderboardh.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">{{$t("leaderboard")}}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.grade }}
                <br />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="5">
      <v-container>
        <v-sheet v-if="loading == false" color="darken-2" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>

        <v-card flat v-else
          ><v-card-text>
            <v-card flat dark style="background-color:#002147">
              <v-card-text>
                {{
                  this.myrecord.firstname +
                    " " +
                    this.myrecord.middlename +
                    " " +
                    this.myrecord.lastname
                }}
                <h3>
                  {{$t("Your Rank is")}}<span style="color:#5aee00">
                    {{ this.myrecord.index }}</span
                  >
                </h3>
                <h6> {{ $t("Points") + " " + ($i18n.locale=='Arabic' ?(this.myrecord.points).toLocaleString('ar-EG') : (this.myrecord.points))}}</h6>
              </v-card-text></v-card
            >
            <v-row align="center" justify="center" style="padding-top:20px">
              <v-col
                cols="4"
                align="center"
                justify="center"
                style="background-color:red; height:150px; color:#fff;"
              >
                <h1>{{ ($i18n.locale=='Arabic' ?(2).toLocaleString('ar-EG') : (2)) }}</h1>
                <br />
                {{
                  students[1].firstname +
                    " " +
                    students[1].middlename +
                    " " +
                    students[1].lastname
                }}
              </v-col>
              <v-col
                cols="4"
                align="center"
                justify="center"
                style="background-color:green; height:200px; color:#fff;"
              >
                <h1>{{ ($i18n.locale=='Arabic' ?(1).toLocaleString('ar-EG') : (1)) }}</h1>
                <br />
                {{
                  students[0].firstname +
                    " " +
                    students[0].middlename +
                    " " +
                    students[0].lastname
                }}</v-col
              >
              <v-col
                cols="4"
                align="center"
                justify="center"
                style="background-color:#FF8F00; height:120px; color:#fff;"
              >
                <h1>{{ ($i18n.locale=='Arabic' ?(3).toLocaleString('ar-EG') : (3)) }}</h1>
                <br />
                {{
                  students[2].firstname +
                    " " +
                    students[2].middlename +
                    " " +
                    students[2].lastname
                }}</v-col
              >
            </v-row>
            <v-card flat :style="$i18n.locale=='Arabic'? {'direction': 'rtl'} : ''">
              <div v-for="(student, index) in students" :key="student.key">
                <v-card-text>
                  <v-card flat outlined>
                    <v-card-title>
                      <h4>
                        <b>{{ ($i18n.locale=='Arabic' ?(student.points).toLocaleString('ar-EG') : (student.points)) }}</b>
                      </h4>
                      <small>{{$t("Points")}}</small></v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col cols="9" style="background-color:#dbe5f6">
                          <b>
                            {{
                              student.firstname +
                                " " +
                                student.middlename +
                                " " +
                                student.lastname
                            }}</b
                          >
                          <br />
                          {{ $t("Class") +': ' + student.class }}
                        </v-col>
                        <v-col cols="3">
                          <h3 style="margin-right:20px; color:green;">
                            {{ ($i18n.locale=='Arabic' ?(index + 1 ).toLocaleString('ar-EG') : (index + 1 )) }}
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card-text></v-card
                  >
                </v-card-text>
              </div>
            </v-card>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
    <v-col cols="4">
      <v-container>
        <v-sheet v-if="loading == false" color="darken-2" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>

        <div id="chart" v-else>
          <v-card flat outlined>
            <v-card-title :style="$i18n.locale=='Arabic'? {'direction': 'rtl'} : ''">{{$t("Classes' Rank")}}</v-card-title>
            <v-card-text>
              <apexchart
                type="bar"
                width="100%"
                :options="classchartOptions"
                :series="classseries"
              ></apexchart>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Leaderboard-page",
  data() {
    return {
      students: [],
      loading: false,
      answers: [],
      school: this.$store.state.school,
      grade: this.$store.state.grade,
      branch: this.$store.state.branch,
      sclass: this.$store.state.sclass,
      student: this.$store.state.studentpath,
      myrecord: [],
      index: 1,
      classlist: [],
      classpoints: [],
      classseries: [
        {
          data: [44, 55, 41, 64]
        }
      ],

      classchartOptions: {
        colors: ["#089d13"],
        chart: {
          toolbar: {
            show: false
          },
          type: "bar",
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"]
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: ["Class A", "Class B", "Class C", "Class D"]
        }
      }
    };
  },
  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/StudentsData/")
      .where("branch", "==", this.branch)
      .where("grade", "==", this.grade)
      .orderBy("points", "desc")
      .get()
      .then(querySnapshot => {
        this.students = [];
        this.index = 1;
        querySnapshot.forEach(doc => {
          this.students.push({
            index: this.index,
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            middlename: doc.data().middlename,
            lastname: doc.data().lastname,
            schoolName: doc.data().schoolName,
            grade: doc.data().grade,
            points: doc.data().points,
            class: doc.data().class
          });
          this.index++;
        });

        //! Get Current Student Rank
        this.myrecord = this.students.find(({ path }) => path === this.student);

        //! Get Classes Rank
        var result = [];
        this.classlist = [];
        this.classpoints = [];

        this.classseries = [
          {
            name: "Points",
            data: []
          }
        ];

        this.classchartOptions = {
          colors: ["#089d13"],
          chart: {
            toolbar: {
              show: false
            },
            type: "bar",
            height: 430
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: "top"
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"]
            }
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"]
          },
          xaxis: {
            categories: []
          }
        };

        this.students.reduce(function(res, value) {
          if (!res[value.class]) {
            res[value.class] = { class: value.class, points: 0 };
            result.push(res[value.class]);
          }
          res[value.class].points += value.points;
          return res;
        }, {});

        result.forEach(cls => {
          this.classlist.push("Class " + cls.class);
          this.classpoints.push(cls.points);
        });

        this.classchartOptions = {
          colors: ["#089d13"],
          chart: {
            toolbar: {
              show: false
            },
            type: "bar",
            height: 430
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: "top"
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"]
            }
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"]
          },
          xaxis: {
            categories: this.classlist
          }
        };

        this.classseries = [
          {
            name: "Points",
            data: this.classpoints
          }
        ];

        this.loading = true;
      });
  },
  methods: {
    totalClass() {
      var result = [];
      this.students.reduce(function(res, value) {
        if (!res[value.class]) {
          res[value.class] = { class: value.class, points: 0 };
          result.push(res[value.class]);
        }
        res[value.class].points += value.points;
        return res;
      }, {});
    }
    // inc() {
    //   const increment = firebase.firestore.FieldValue.increment(1);
    //   const story = firebase
    //     .firestore()
    //     .collection("ai")
    //     .doc("test");

    //   story.update({ count: increment });
    // }
  }
};
</script>

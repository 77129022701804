<template>
  <!-- Side Exam Panel -->
  <v-row>
    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/intro.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">
                  {{ this.weighttitle }} Details
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subjecttitle + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <!-- <v-card
          flat
          height="100vh"
          v-show="this.loading"
          style=" position: relative;"
        >
          <h6
            style="position: absolute; top: 20%; left: 40%; margin: -25px 0 0 -25px; text-align:center;"
          >
            <img src="@/assets/bgif.gif" width="400px" />
            <br />
            Please wait while your Progress Report is generated
          </h6>
        </v-card> -->

        <v-card>
          <v-card-title
            >{{ this.weighttitle }}

            <v-divider />

            <h3 v-if="this.weighttitle == 'Homework'">
              <!-- {{
                Math.round(
                  ((this.studenttotalgrade + this.flipped) /
                    (this.totalgrade + this.weekswithflipped * 3)) *
                    100
                )
              }} -->
              100%
            </h3>
            <!-- <h3 v-else-if="this.weighttitle == 'Classwork'">
              {{
                Math.round(
                  ((this.studenttotalgrade + this.exit) /
                    (this.totalgrade + this.weekswithexit * 3)) *
                    100
                )
              }}
              %
            </h3> -->
            <h3 v-else>
              {{ this.studenttotalgrade}}
              %
            </h3>
          </v-card-title>
          <v-card-subtitle style="color:green">
            {{ this.weightpre }}% of {{ this.subjecttitle }} Total Grade
          </v-card-subtitle>
          <v-card-text>
            <v-row v-if="this.loading">
              <v-col cols="12">
                <v-card flat outlined
                  ><v-card-text>
                    <b-card-group deck>
                      <b-card
                        bg-variant="info"
                        text-variant="white"
                        header="Total Assignments"
                        class="text-center"
                      >
                        <b-card-text>
                          <h1>{{ this.totalAssgrade}}</h1>
                        </b-card-text>
                      </b-card>

                      <b-card
                        bg-variant="success"
                        text-variant="white"
                        header="Completed and Graded Assignments"
                        class="text-center"
                      >
                        <b-card-text>
                          <h1>{{ this.TotalStudentAss }}</h1>
                        </b-card-text>
                      </b-card>
                    </b-card-group>
                  </v-card-text></v-card
                >

                <br />

                <v-card
                  v-if="this.weighttitle == 'Homework'"
                  flat
                  outlined
                  dark
                  color="warning"
                  ><v-card-text>
                    <v-row>
                      <h5 style="color:#fff">Flipped</h5>
                      <v-spacer />
                      <h5 style="color:#fff">
                        {{ this.flipped }}/{{ this.weekswithflipped * 3 }}
                      </h5></v-row
                    >
                  </v-card-text></v-card
                >
                <!-- <v-card
                  v-else-if="this.weighttitle == 'Classwork'"
                  flat
                  outlined
                  dark
                  color="warning"
                  ><v-card-text>
                    <v-row>
                      <h5 style="color:#fff">Exit Ticket</h5>
                      <v-spacer />
                      <h5 style="color:#fff">
                        {{ this.exit }}/{{ this.weekswithexit * 3 }}
                      </h5></v-row
                    >
                  </v-card-text></v-card
                > -->
                <v-divider />

                <div
                  v-for="assignment in assignments"
                  :key="assignment.key"
                  flat
                  outlined
                >
                  <Assignment
                    :title="assignment.title"
                    :week="assignment.week"
                    :id="assignment.key"
                    :assgrade="assignment.grade"
                    :path="assignment.path"
                    :weekpath="assignment.weekpath"
                    :weekid="assignment.weekid"
                    :student="$route.params.path"
                  />
                </div>
              </v-col>
            </v-row>

            <v-sheet
              v-else
              color="darken-2"
              class="pa-3"
              :height="this.weight * 10"
            >
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import Assignment from "./Assignment";

export default {
  name: "weights-page",
  components: { Assignment },

  data() {
    return {
      assignments: [],
      weeks: [],

      finished: [],
      wfinished: [],
      totalgrade: 0,
      presentage: 0,
      weekswithflipped: 0,
      weekswithexit: 0,
      flipped: 0,
      exit: 0,
      loading: false,
      studenttotalgrade: sessionStorage.getItem("Assgrade"),
      totalAssgrade:sessionStorage.getItem("TotalAssgrade"),
      TotalStudentAss:sessionStorage.getItem("TotalStudentAss"),
      subjectpath: this.$store.state.subject,
      studentpath: this.$store.state.studentpath,
      weighttitle: this.$store.state.weighttitle,
      weightpre: this.$store.state.weightpre,
      subjecttitle: this.$store.state.subjecttitle,
      grade: this.$store.state.grade
    };
  },

  mounted() {
    this.assignments = [];
    this.weeks = [];
    this.finished = [];
    this.totalgrade = 0;

    //! Get Lessons
    firebase
      .firestore()
      .collection(this.subjectpath + "/weeks")
      .where("breakdown", "==", this.$store.state.current)
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.weeks.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            start: doc.data().date,
            end: doc.data().date,
            order: doc.data().order,
            timed: false
          });

          if (doc.data().nofc != true) this.weekswithflipped++;
          if (doc.data().noexit != true) this.weekswithexit++;

          //! Get Lesssons assignments
        });

        this.weeks.forEach(doc => {
          //! Get Subject Assignments
          firebase
            .firestore()
            .collection(doc.path + "/tasks")
            .where("weight", "==", this.weighttitle)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(assign => {
                if (assign.data().graded != true) {
                  this.assignments.push({
                    key: assign.id,
                    path: assign.ref.path,
                    grade: assign.data().grade,
                    title: assign.data().title,
                    //! Add Week title & Order to Assignments
                    week: doc.title,
                    weekid: doc.key,
                    weekpath: doc.path,
                    weekorder: doc.order
                  });

                  this.totalgrade =
                    this.totalgrade + Number(assign.data().grade);
                }
              });
            });
        });
        // //! Get Student Assignments
        firebase
          .firestore()
          .collection(this.studentpath + "/assignments")
          .get()
          .then(querySnapshot => {
            this.wfinished = [];
          //  this.studenttotalgrade = 0;

            if (querySnapshot.docs.length == 0) {
              this.studenttotalgrade = null;
            }

            querySnapshot.forEach(doc => {
              this.addtofinish(doc.id, doc.data().right);
              if (this.weighttitle == "Homework")
                this.addtoflipped(doc.id, doc.data().right);
              if (this.weighttitle == "Classwork")
                this.addtoexit(doc.id, doc.data().right);
            });
            this.loading = true;
          });
      });
  },

  methods: {
    addtofinish(name, right) {
      this.assignments.forEach(doc => {
        if (doc.key == name) {
          this.wfinished.push({ grade: right == null ? 0 : right });
          if (right == null) right = 0;

          this.studenttotalgrade = this.studenttotalgrade + Number(right);
        }

        this.loading = true;
      });
    },
    addtoflipped(name, right) {
      if (name.includes("flipped")) {
        if (
          this.weeks.filter(function(e) {
            if (e.key === name.replace("flipped", "")) return "right";
          }).length > 0
        ) {
          this.flipped = this.flipped + Number(right);
        }
      }
    },
    addtoexit(name, right) {
      if (name.includes("exit")) {
        if (
          this.weeks.filter(function(e) {
            if (e.key === name.replace("exit", "")) return "right";
          }).length > 0
        ) {
          this.exit = this.exit + Number(right);
        }
      }
    }
  }
};
</script>
